import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById, fetchMMSTemplates, saveGiftCardMMSTemplate } from "../../actions"
import { connect } from "react-redux";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"

class GiftCardMMSTemplatesPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uuid: null,
      gift_card_details_id: null,
      product_name: null,
      gift_card_price: null,
      product_image: null
    }
  }

  componentDidMount = () => {
    const gift_card_details_id = this.props.match.params.giftcarddetailsid 

    this.setState({ gift_card_details_id: gift_card_details_id })

    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart()
        this.props.fetchCartProducts()
      })
    }

    this.props.fetchGiftCardDetailsById(gift_card_details_id, (gift_card_details) => { 
      this.props.fetchMMSTemplates(gift_card_details.product_id, () => {
        this.props.fetchProduct(gift_card_details.product_id, () => {
          // loaded
        })
      })
    })
  }

  render() {
    return ( 
      <div id="page-gift-card-mms-templates"> 
        <h1 className="h1">Gift Card MMS Templates</h1>
        <div>Select an MMS Template for your Gift Card</div>
        <div>
          <div className="product-gift-card-mms-templates-container">
            <div id="gift-card-mms-templates">
              {this.props.mms_templates.map(v => {
                return <div className="gift-card-mms-template-parent">
                  <img className="gift-card-mms-template" src={v.mms_template_url}/>
                  <div className="gift-card-mms-template-button-parent">
                    <button className="gift-card-mms-template-button" onClick={() => {
                      this.props.saveGiftCardMMSTemplate(this.state.gift_card_details_id, v.mms_template_id, v.id, () =>{
                        history.push(`/checkout`)
                      })
                    }}>Select</button>
                  </div>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
    product: state.product.product,
    mms_templates: state.mms_templates.mms_templates 
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById, fetchMMSTemplates, saveGiftCardMMSTemplate }
  )(GiftCardMMSTemplatesPage) 
);
