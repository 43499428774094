import _ from "lodash";
import {
  SET_JWT,
  UNSET_JWT,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = localStorage.jwt ? localStorage.jwt : null;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) { 
    case SET_JWT:
      localStorage.jwt = action.payload
      return action.payload;
    case UNSET_JWT:
    case LOGOUT:
      delete localStorage.jwt
      return INITIAL_STATE
    default:
      return state;
  }
};
