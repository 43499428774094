import React from "react";
import {
  fetchProduct,
  fetchCart,
  setCart,
  fetchCartProducts,
  setLoader,
  createEmptyCart,
  fetchSeller,
  showUnexpectedErrorPopup,
} from "../../actions";
import { connect } from "react-redux";
import Product from "../Product";
import history from "../../history";

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: null,
      not_exists: false,
    };
  }

  componentDidMount = () => {
    const product_id = this.props.match.params.id;
    this.props.setLoader(true, "Fetching Product...");
    this.props.fetchProduct(product_id, (data) => {
      this.props.fetchSeller(product_id, () => {
        this.props.setLoader(false, null);
      });
    });
    if (this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCartProducts();
      });
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.product_id) {
      return { product_id: nextProps.match.params.id };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.state.product_id) {
      const product_id = this.state.product_id;
      this.props.fetchProduct(product_id);
      if (this.props.auth.is_signed_in) {
        this.props.createEmptyCart(() => {
          this.props.fetchCart();
          this.props.fetchCartProducts();
        });
      }
    }
  }

  handleAddProductToCart = (
    product,
    qty,
    configurable_options,
    configurable_product_sku
  ) => {
    let product_data = {};
    let price = product.price;
    if (configurable_options) {
      let configurable_item_options = [];
      for (const [key, value] of Object.entries(configurable_options)) {
        if (value !== null) {
          configurable_item_options.push({
            option_id: value.option_id.toString(),
            option_value: parseInt(value.option_value),
            option_label: value.option_label.toString(),
            option_title: value.option_title.toString(),
            price: value.price,
          });
          price = value.price;
        }
      }

      product_data = {
        id: product.id,
        name: product.name,
        sku: product.sku,
        qty: qty,
        price: price,
        images: product.images,
        product_type: "configurable",
        product_option: {
          extension_attributes: {
            configurable_item_options: configurable_item_options,
          },
        },
      };

      this.props.setLoader(true, "Adding product to cart...");
      this.props.setCart(product_data);
      this.props.fetchCartProducts();
      this.props.setLoader(false, null);
    } else {
      product_data = {
        id: product.id,
        name: product.name,
        sku: product.sku,
        qty: qty,
        images: product.images,
        price: parseFloat(product.price).toFixed(2),
        product_type: "simple",
      };

      this.props.setLoader(true, "Adding product to cart...");
      this.props.setCart(product_data);
      this.props.fetchCartProducts();
      this.props.setLoader(false, null);
    }
  };

  renderProduct = () => {
    return (
      <Product
        update={false}
        product={this.props.product}
        seller={this.props.seller}
        handleProductAction={this.handleAddProductToCart}
      />
    );
  };

  render() {
    return (
      <div id="page-product">
        {this.props.product ? (
          this.renderProduct()
        ) : !this.props.loader.show ? (
          <div id="product-not-exists-message">No Product.</div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product.product,
    cart: state.cart.cart,
    auth: state.auth,
    seller: state.seller.seller,
    loader: state.loader,
  };
};

export default connect(mapStateToProps, {
  fetchProduct,
  fetchCart,
  setCart,
  fetchCartProducts,
  setLoader,
  createEmptyCart,
  fetchSeller,
  showUnexpectedErrorPopup,
})(ProductPage);
