import axios from "axios";

let baseURL = null
if(window.location.origin === 'https://www.tap2buy.com' || window.location.origin === 'https://live-giftguru.dnrx1u9c16dvg.amplifyapp.com') {
  baseURL = "https://backend.tap2buy.com"
} else {
  baseURL = "https://staging.securemobile.net"
}

let client = axios.create({
  baseURL: baseURL,
  // withCredentials: true
  // headers: {
  //   "Access-Control-Allow-Credentials": true,
  //   "Access-Control-Allow-Origin": "http://api.api"
  // }
  validateStatus: function (status) {
    return status >= 200 && status < 300; // default
  },
});

export default client;

