import React from "react";
import { connect } from "react-redux";
import history from "../../history"; 

class TermsPage extends React.Component {
  render = () => {
    return (
      <div>
        <div id="back" onClick={() => {
          history.goBack()
        }}><i className="fas fa-arrow-left"></i> <span>Back</span></div> 
        <div className="background-white"> 
          <div id="terms-content">
            <h1>Terms and Conditions</h1>
            <section className="sections two_colums">
              <div>
                <div className="navigations animate">
                  <ul>
                    <li className="animate items_page_terms">
                      <a className="animate heading_one" data-target="heading_one">
                        Overview
                      </a>
                    </li>
                    <li className="animate items_page_terms">
                      <a
                        className="animate terms_of_use"
                        data-target="terms_of_use"
                      >
                        Website Terms of Use
                      </a>
                    </li>
                    <li className="animate items_page_terms">
                      <a className="animate our_policy" data-target="our_policy">
                        Fair Use Policy
                      </a>
                    </li>
                    <li className="animate items_page_terms">
                      <a
                        className="animate other_policy"
                        data-target="other_policy"
                      >
                        Other Practices
                      </a>
                    </li>
                    <li className="animate items_page_terms">
                      <a
                        className="animate agreement_example"
                        data-target="agreement_example"
                      >
                        SDP Agreement Sample
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="section-content">
                  <section className="sections list_content_block heading_one ">
                    <div>
                      <div
                        className="content_head "
                        data-when="enter"
                        data-from="0.5"
                        data-to="0"
                        data-crop="false"
                        data-opacity="0"
                        data-scale="1.5"
                      >
                        <h2 className="title1">Overview</h2>
                      </div>
                      <div className="service_items">
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">Pricing</h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  Goods are priced by the
                                  Seller/Merchant/Retailer supplying the product
                                </li>
                                <li>
                                  All pricing is quoted including VAT and GST
                                  unless otherwise stated and is dependent on
                                  the clients base and billing requirements.
                                </li>
                                <li>Pricing can be amended without notice</li>
                                <li>
                                  Merchants will be notified of successful
                                  purchase and will organise product delivery
                                </li>
                              </ol>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">Terms</h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  Unless otherwise stated cards are stored
                                  against a users account using Vault and Stripe
                                  services and is fully PCI compliant.&nbsp;
                                </li>
                                <li>
                                  Accounts can be blocked if fraudulent activity
                                  is detected
                                </li>
                                <li>
                                  Clients have 3 months to issue a charge back
                                  activity.
                                </li>
                                <li>
                                  Clients that change their number will need to
                                  get in touch with MobileDigital with proof of
                                  number change and identity to transfer account
                                  to new number.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">Services Delivered</h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  There is no subscription charge for services
                                  rendered to Users.
                                </li>
                                <li>
                                  Accounts can be terminated at any time without
                                  notice.
                                </li>
                                <li>
                                  Additional or contradictory terms included in
                                  unique negotiated term agreements hold
                                  precedent over these terms of use.&nbsp;
                                </li>
                                <li>
                                  Support is provided online through chat or
                                  through email at suuport@click2.shop
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">&nbsp;</div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">&nbsp;</div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">&nbsp;</div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">
                              Client’s Obligations and Acknowledgments
                            </h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  The Client indemnifies MobileDigital for any
                                  loss, liability or cost suffered or incurred
                                  by MobileDigital in connection with a claim by
                                  a Customer against MobileDigital except if
                                  such loss, liability or cost is caused by
                                  MobileDigital’s failure to comply with this
                                  Agreement.
                                </li>
                                <li>
                                  The Client will not copy or replicate the
                                  software, and recognizes MobileDigital
                                  Marketing intellectual property as the owner
                                  of the SNIPER software and associated services
                                </li>
                                <li>
                                  The Client must pay when due the fees in
                                  accordance with this agreement.
                                </li>
                                <li>
                                  The Client will be solely responsible for
                                  responding to any Customer or Recipient
                                  enquiry, dispute or claim relating to use of
                                  the service
                                </li>
                                <li>
                                  The Client must keep any login details if
                                  provided to them by MobileDigital secure and
                                  treat these credentials as confidential
                                  information.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">Confidentiality</h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  Each party acknowledges that Confidential
                                  Information howsoever disclosed pursuant to
                                  this Agreement is confidential to, proprietary
                                  to, and/or a trade secret of, the disclosing
                                  party (“Disclosing Party”).
                                </li>
                                <li>
                                  Each party warrants to the other that the
                                  Confidential Information of the other party
                                  will be used by the first mentioned party only
                                  for the purpose of that party fulfilling its
                                  obligations or exercising its rights under
                                  this Agreement.
                                </li>
                                <li>
                                  Except as set out in this Agreement,
                                  Confidential Information must not be disclosed
                                  by the party that has been provided this
                                  information (“Receiving Party”) to any person
                                  for any purpose without the prior written
                                  consent of the Disclosing Party, except where
                                  the Confidential Information which must be
                                  disclosed pursuant to any court order or under
                                  any law, or comes into the public domain
                                  through no fault of the Receiving Party.
                                </li>
                                <li>
                                  Confidential Information of a party means all
                                  confidential, non-public or proprietary
                                  information of that party including all
                                  employees, contractors and customers,
                                  regardless of how the information is stored or
                                  delivered, exchanged relating to the business,
                                  technology or other affairs of the party, but
                                  excluding information which is generally
                                  available in the public domain (other than
                                  through a breach of this Agreement). Any
                                  agreement material constitutes Confidential
                                  Information of the Customer.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                        <div className="service_item">
                          <div className="feature-content">
                            <h4 className="title3">
                              Data Collected on your Behalf
                            </h4>
                            <div className="service_descriptions">
                              <ol>
                                <li>
                                  All end customer data collected on your behalf
                                  remains your property.
                                </li>
                                <li>
                                  When MobileDigital stores user or end customer
                                  data on your behalf, we will take all
                                  reasonable care in providing the highest
                                  security to secure data at all times.
                                </li>
                                <li>
                                  Once we supply you end customer data, it is
                                  your responsibility to provide the data
                                  security and all end customer collected data
                                  usage is governed by your terms and conditions
                                  at that time.
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="sections list_content_block terms_of_use ">
                    <div>
                      <div
                        className="content_head "
                        data-when="enter"
                        data-from="0.5"
                        data-to="0"
                        data-crop="false"
                        data-opacity="0"
                        data-scale="1.5"
                      >
                        <h2 className="title1">Website Terms of Use</h2>
                        <div className="service_items">
                          <div className="service_item">
                            <div className="feature-content">
                              <div className="service_descriptions">
                                Welcome to our website. This website with URL
                                address{" "}
                                <a href="https://(uk.)click2.sho">
                                  https://(uk.)click2.sho
                                </a>
                                p is owned and operated by MobileDigital. Should
                                you continue to use this website, you are
                                agreeing to comply with and be bound by the
                                following terms and conditions of use, which
                                together with our privacy policy govern
                                MobileDigital’s relationship with you in
                                connection with this website. Should you not
                                agree with any of these terms and conditions,
                                please do not use our website.
                                <br />
                                The term ‘MobileDigital’, ‘Click2’, or ‘us’ or
                                ‘our’ or ‘we’ refers to MobileDigital, the owner
                                of the website, whose registered office is 57
                                Rapallo Avenue, Isle of Capri, 4217, Queensland.
                                The term ‘you’ or ‘your’ refers to the website
                                user.Your use of this website is subject to the
                                following terms and conditions:
                                <p>&nbsp;</p>
                                <ol style={{ listStyle: 'decimal' }}>
                                  <li style={{ textAlign: 'left' }}>
                                    The content of this website is for your
                                    general information and use only. It is
                                    subject to change without prior notice.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    This website uses cookies to monitor
                                    browsing preferences. If you allow cookies
                                    to be used, the following personal
                                    information may be stored by us for use by
                                    third parties: All information entered into
                                    forms as well as website tracking data.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    Neither we nor any third parties provide any
                                    warranty or guarantee as to the performance,
                                    accuracy, timeliness, completeness or
                                    suitability of the information and materials
                                    found or offered on this website for any
                                    particular purpose. You hereby acknowledge
                                    that such information and materials may
                                    contain mistakes, inaccuracies or errors and
                                    we expressly exclude any liability for such
                                    to the fullest extent permissible by law.
                                  </li>
                                  <li style={{ textAlign: 'left' }}> 
                                    Your use of any information or materials on
                                    this website is entirely at your own risk,
                                    for which we shall not be liable. It shall
                                    be your own responsibility to ensure that
                                    any products, services or information
                                    available through this website meet your
                                    specific requirements.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    This website contains material which is
                                    owned by or licensed to us. This material
                                    includes, but is not limited to, the
                                    content, design, layout, appearance, look
                                    and graphics of the website. Any
                                    reproduction of the website’s material is
                                    prohibited other than in accordance with the
                                    copyright notice, which forms part of these
                                    terms and conditions.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    All trademarks reproduced in this website,
                                    which are not the property of, or licensed
                                    to us, are acknowledged on the website.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    Unauthorised use of this website may be a
                                    criminal offence and/or give rise to a claim
                                    for damages.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    This website may also, on occasion, include
                                    links to other websites which are not
                                    controlled by us. These links are provided
                                    for your convenience to provide you with
                                    further information. You acknowledge that
                                    they are used at your own risk. They do not
                                    signify that we recommend or endorse the
                                    websites. We have no control over the
                                    nature, content and availability of those
                                    websites.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    Your use of this website and any dispute
                                    arising out of your use of it is subject to
                                    the laws of Queensland.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    You may only use the website for lawful
                                    purposes and in a manner consistent with the
                                    nature and purpose of the website.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    These terms and conditions do not relate to
                                    your use of any product or service described
                                    on our website unless otherwise agreed. You
                                    must refer to the individual warranty
                                    relevant to any particular product or
                                    service.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    These terms and conditions may be amended
                                    from time to time. Your continued use of our
                                    website following any such amendments will
                                    be deemed to be confirmation that you accept
                                    those amendments.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    You indemnify us from and against all
                                    claims, suits, demands, actions,
                                    liabilities, costs and expenses (including
                                    legal costs and expenses on a full indemnity
                                    basis) resulting from your use of the
                                    website.
                                  </li>
                                  <li style={{ textAlign: 'left' }}>
                                    In no event will we be liable for any loss,
                                    damage, cost or expense including legal
                                    costs and expenses (whether direct or
                                    indirect) incurred by you in connection with
                                    the use of this website. Every effort is
                                    made to keep the website up and running
                                    smoothly. However, we take no responsibility
                                    for, and will not be liable for, the website
                                    being temporarily unavailable due to
                                    technical issues beyond our control.
                                  </li>
                                </ol>
                                <p>
                                  Every effort is made to keep the website up
                                  and running smoothly. However, we take no
                                  responsibility for, and will not be liable
                                  for, the website being temporarily unavailable
                                  due to technical issues beyond our control.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="sections list_content_block our_policy ">
                    <div>
                      <div
                        className="content_head "
                        data-when="enter"
                        data-from="0.5"
                        data-to="0"
                        data-crop="false"
                        data-opacity="0"
                        data-scale="1.5"
                      >
                        <h2 className="title1">Fair Use Policy</h2>
                      </div>
                      <div className="service_items">
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">
                              With great power comes great responsibility!
                              <p>
                                The communications clients send can have a big
                                impact on the success of our services. That is
                                why ALL MobileDigital clients must consider the
                                Acceptable Use Policy in their use of the
                                MobileDigital services:
                              </p>
                              <ul>
                                <li>
                                  Respectful engagement and reasonable and
                                  non-invasive communication must be considered
                                  at all times.
                                </li>
                                <li>
                                  The removal of customer Fear Uncertainty
                                  and/or Doubt (FUD) is the best way to ensure
                                  the ongoing credibility and integrity of your
                                  communications. MobileDigital is aware that
                                  50% of malware on mobile phones is from
                                  phishing and spoofing, and if we are aware, so
                                  are your customers – therefore clear links
                                  with brand recognisable URL addresses are a
                                  mandatory component of our messaging regime.
                                </li>
                                <li>ALL communications must be clear.</li>
                                <li>MobileDigital will not send SPAM.</li>
                                <li>
                                  MobileDigital will not render or deliver
                                  messages with poorly formatted, truncated or
                                  complex links that are not immediately obvious
                                  and clear to the receiver.
                                </li>
                                <li>
                                  MobileDigital will NOT send anything
                                  offensive, promote anything illegal, or to
                                  harass anyone.
                                </li>
                                <li>
                                  MobileDigital reserves the right to not send
                                  communications that are not in accordance with
                                  this policy.
                                </li>
                                <li>
                                  We work hard to maintain the positive
                                  reputation of our system, but we count on our
                                  clients to pitch in too. You may not:
                                  <ul>
                                    <li>
                                      Use the Service in any way that is
                                      unlawful or illegal.
                                    </li>
                                    <li>
                                      Send MMS or SMS messages to mobile numbers
                                      that are not one of your existing
                                      customers or known or related contacts.
                                    </li>
                                    <li>
                                      Spam your customers with MMS or SMS
                                      messages; the impact and value of MMS
                                      messages are directly related to the
                                      impact and the uniqueness.
                                    </li>
                                    <li>
                                      Send MMS or SMS messages to customers that
                                      have opted out or have requested to opt
                                      out&lt;./li&gt;
                                    </li>
                                    <li>
                                      Send MMS or SMS messages past 8 pm at
                                      night or before 6am.
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="sections list_content_block other_policy ">
                    <div>
                      <div
                        className="content_head "
                        data-when="enter"
                        data-from="0.5"
                        data-to="0"
                        data-crop="false"
                        data-opacity="0"
                        data-scale="1.5"
                      >
                        <h2 className="title1">
                          Other practices may be relevant in determining
                          Legitimate Use
                        </h2>
                      </div>
                      <div className="service_items">
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">
                              <ul>
                                <li>
                                  MobileDigital reserves the right to refuse
                                  delivery of any unlawful or prohibited use.
                                </li>
                                <li>
                                  MobileDigital will also take abnormal or
                                  unusual activity into account in making its
                                  determination.
                                </li>
                                <li>
                                  MobileDigital may at its option, terminate or
                                  suspend its relationship immediately if it
                                  determines you are using the product contrary
                                  to this FUP.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="sections list_content_block agreement_example ">
                    <div>
                      <div
                        className="content_head "
                        data-when="enter"
                        data-from="0.5"
                        data-to="0"
                        data-crop="false"
                        data-opacity="0"
                        data-scale="1.5"
                      >
                        <h2 className="title1">
                          Standard Data Processing Agreement (Sample)
                        </h2>
                      </div>
                      <div className="service_items">
                        <div className="service_item">
                          <div className="feature-content">
                            <div className="service_descriptions">
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  1.
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Definitions&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  "Data" shall mean [List the categories of the
                                  data that is being processed and the
                                  categories of data subjects this processing
                                  relates to]&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  “Data Subject” shall have the same meaning as
                                  set out in Article 4 (1) of the GDPR and means
                                  an identified or identifiable natural
                                  person&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  “EEA” means the European Economic Area – the
                                  28 Member states of the European Union plus
                                  Iceland, Lichtenstein and Norway
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  “GDPR” means the General Data Protection
                                  Regulation (Regulation (EU) 2016/679 of the
                                  European Parliament and the Council&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  “Incident” has the same meaning as a personal
                                  data breach in Article 4 (12) of the GDPR and
                                  means a breach of &nbsp;security leading to
                                  the accidental or unlawful destruction, loss,
                                  alteration, unauthorised disclosure of, or
                                  access to, Data , transmitted, stored or
                                  otherwise processed under the terms of this
                                  Agreement&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  "Processing" shall mean any operation or set
                                  of operations which is/are performed upon
                                  &nbsp;Data , (whether or not by automatic
                                  means) including collection, recording,
                                  organisation, storage, adaptation or
                                  alteration, retrieval, consultation, use,
                                  disclosure by transmission, dissemination or
                                  otherwise making available, alignment or
                                  combination, blocking, erasure or destruction.
                                  Such processing may be wholly or partly by
                                  automatic means or processing otherwise than
                                  by automatic means of Data which form part of
                                  a filing system or one intended to form part
                                  of a filing system. A filing system shall mean
                                  any structured set of Data which are
                                  accessible according to specific criteria,
                                  whether centralised, decentralised or
                                  dispersed on a functional or geographic
                                  basis."&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  2.
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Application&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  (a) This Agreement shall apply to all Data
                                  processed from the date of this Agreement by
                                  the Processor on behalf of the Controller
                                  until the date of termination of this
                                  Agreement. &nbsp;&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  3. &nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Purpose of Processing&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor shall process the Data it
                                  processes on behalf of the Controller, solely
                                  for the provision&nbsp;
                                </span>
                                <span >
                                  of personalising communications and
                                  identifying mobile users on behalf of the
                                  client in outbound communications and
                                  identifying and applying rules to inbound
                                  communications from mobile users to clients
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  in accordance with the written instructions of
                                  the Controller (including when making a
                                  transfer of personal data to countries outside
                                  the EEA) unless required to do by law. The
                                  Processor must inform the Controller of what
                                  processing the Processor is required to do so
                                  by law unless the Processor is prohibited
                                  under the relevant law from notifying the
                                  Controller of such processing. The Processor
                                  shall not process the Data for any other
                                  purpose except with the express written
                                  consent of the Controller.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) &nbsp;The Controller confirms and warrants
                                  that the Processing of the Data, including the
                                  transfer of the Data to the Processor, has
                                  been and will continue to be carried out in
                                  accordance with the relevant provisions of the
                                  GDPR and does not violate the relevant
                                  provisions of the EEA country in which the
                                  Controller is established
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  4. Duration of processing&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor shall process the Data for as
                                  long as the separate outsourcing agreement for
                                  the provision of SNIPER by MobileDigital
                                  services dated&nbsp;
                                </span>
                                <span >
                                  [date/month/ year of outsourcing agreement]
                                </span>
                                <span >
                                  &nbsp;remains in full force and effect.
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  5. Type of Personal data&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  The Processor will process the following types
                                  of personal information&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •Mobile Numbers , needed in order to create a
                                  customer entity
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •Personal Details, if loaded by client
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  family details, if loaded by client
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  lifestyle and social circumstances
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  goods and services purchased or offered
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  financial details if pertaining to policy or
                                  contract reminders
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  employment and education details if
                                  communication is from such entities
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  visual media, if this media is part of the
                                  inbound communication
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  responses to surveys, if we are the originator
                                  of the form supplied
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  profile data where relevant to the
                                  communication
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  tracking data from web activity where
                                  necessary for communications received and
                                  stored
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  5.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Categories of data subjects
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  The Processor will process information about
                                  the following categories of data subjects
                                </span>
                                <span >
                                  &nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  customers
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  prospective customers
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  suppliers
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  individuals contacted when responding to a
                                  complaint or enquiry
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  service providers
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  applicants for a licence or registration
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  survey respondents
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  6.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Security and Confidentiality of Data
                                </span>
                                <span >
                                  &nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor and the Controller shall
                                  implement appropriate technical and
                                  organisational measures to ensure a level
                                  appropriate to the risks that are presented by
                                  the data processing in particular from
                                  accidental or unlawful destruction, loss,
                                  alteration, unauthorised disclosure of, or
                                  access to personal transmitted, stored or
                                  otherwise processed.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) Both the Controller and Processor shall
                                  take into account the following when
                                  determining the measures:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  i) the state of the art, and
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  ii) the cost of implementation of the
                                  measures, and&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iii) the nature, scope context and purposes of
                                  processing, and&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iiv) the risk of varying likelihood and
                                  severity for the rights and freedoms of
                                  individual Data Subjects
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  c) The Controller and Processor agree that the
                                  &nbsp;measures security measures &nbsp;taken
                                  in accordance with Clause 6 (a) of this
                                  Agreement &nbsp;after assessment with the
                                  requirements of the GDPR are appropriate to
                                  protect Data against accidental or unlawful
                                  destruction or accidental loss, alteration,
                                  unauthorised disclosure or access, in
                                  particular where the Processing involves the
                                  transmission of Data over a network, and
                                  against all other unlawful forms of
                                  Processing, and that these measures ensure a
                                  level of security appropriate to the risks
                                  presented by the Processing and the nature of
                                  the Data to be protected having regard to the
                                  state of the art and the cost of their
                                  implementation; shall ensure a level of
                                  security appropriate to the risk,
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  d) The measures taken shall include amongst
                                  others the following items, where appropriate,
                                  from the non- exhaustive list below:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  i) the pseudonymisation and encryption of
                                  Data&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  ii) the ability to ensure the ongoing
                                  confidentiality, integrity and availability
                                  and resilience of processing systems and
                                  services
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iii) the ability to restore the availability
                                  and access to Data in a timely manner in the
                                  event of a physical or technical Incident
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iv) a process for regularly testing, assessing
                                  and evaluating the effectiveness of technical
                                  and organisational measures for ensuring the
                                  security of the processing.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  e) The Controller and the Processor may use
                                  adherence to an approved code of conduct as
                                  referred to by Article 40 of the GDPR or an
                                  approved certification mechanism as referred
                                  to in Article 42 as an element by which to
                                  demonstrate compliance with the requirements
                                  set out above in clause 6 ) (b) &nbsp;(c) and
                                  (d) of this Agreement&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  f). The Processor shall ensure that each of
                                  its employees, agents or subcontractors are
                                  made aware of its obligations with regard to
                                  the security and protection of the Data and
                                  shall require that they enter into binding
                                  obligations with the Processor in order to
                                  maintain the levels of security, protection
                                  and confidentiality provided for in this
                                  Agreement.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  g). The Processor shall not divulge the Data
                                  whether directly or indirectly to any person,
                                  firm or company without the express consent of
                                  the Controller except to those of its
                                  employees, agents and subcontractors who are
                                  engaged in the processing of the Data and are
                                  subject to the binding obligations referred to
                                  in Clause 6 (e) of this Agreement
                                  above).&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  7.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Incident Reporting
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;a) The Processor must have effective
                                  processes for the identification, management
                                  and reporting of Incidents. Any Incident,
                                  suspected or actual, involving the
                                  Controller’s Data must be reported immediately
                                  to the Controller. An Incident may include but
                                  not be limited to:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Security breach or fraud
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Misuse of relevant system storing Controller’s
                                  Data
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Misuse, loss or corruption of the Controller’s
                                  Data
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Unauthorised access to, use of, alteration,
                                  amendment or deletion of Controller’s Data
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Physical security incident
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  •
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Any unapproved requirement to disclose
                                  Controller’s Data to a third party&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) The Processor will be expected to promptly
                                  investigate any such Incident, provide status
                                  updates throughout the Incident, where
                                  appropriate cooperate with reasonable
                                  Controller requests during the management of
                                  the Incident or permit the Controller to
                                  support the management of the Incident, and
                                  send a written report to the Controller,
                                  describing the nature of the Incident, stating
                                  any control weaknesses discovered, and any
                                  actions taken/planned. A plan to agree any
                                  reasonable additional controls, either
                                  identified by the Processor or the Controller,
                                  to prevent or reduce the likelihood of a
                                  similar Incident must be agreed and
                                  monitored.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  c) The Processor will assist the Controller in
                                  informing Data Subjects if there has been an
                                  Incident involving the Processor.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  d) The Processor will assist the Controller in
                                  informing any relevant supervisory authority
                                  of an Incident.
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  8.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Processor’s appointment of a sub -
                                  processor&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor will not engage a sub
                                  processor to process the Controller’s Data,
                                  without the prior specific or general or
                                  written authorisation of the Controller.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) If the Processor employs a sub – processor
                                  under the Controller’s prior general written
                                  authorisation the Processor will inform the
                                  Controller in writing of any intended
                                  additions to or replacement of sub-
                                  processor(s) the Processor uses to carry out
                                  processing of the Controller’s personal data
                                  at least&nbsp;
                                </span>
                                <span >
                                  5
                                </span>
                                <span >
                                  &nbsp;days before the date of any intended
                                  additions or changes to the sub processors.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  c) If the Controller objects to any such
                                  additions to or replacement the Controller
                                  shall inform the Processor 5 days of receiving
                                  the notice in Clause 8 (b) of this Agreement.
                                  Upon receipt of such a notice of objection the
                                  Processor shall not make the intended addition
                                  or replacement of [a] sub – processor(s)&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  d) The Processor, upon receipt of a notice
                                  under Clause 8 (c) of this Agreement above may
                                  choose another sub – processor(s) it wishes to
                                  add to or act as a replacement to the existing
                                  sub- processor(s) it uses to carry out the
                                  processing. The Processor will then inform the
                                  Controller in accordance with clause 8( b) of
                                  this Agreement and the Controller will have
                                  the right to object in accordance with clause
                                  8 (c) of this Agreement&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  e) The Processor shall ensure by written
                                  contract that any agent or sub-processor
                                  employed by the Processor to process Data to
                                  which this Agreement relates:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  i) imposes the same contract terms as listed
                                  in Clause 6 – Security and Confidentiality of
                                  Data and Clause 7 Incident reporting of this
                                  Agreement on any agent or sub- processor&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  ii) makes it clear that the Processor and not
                                  any agent or sub-processor will be liable to
                                  the Controller for the compliance of the agent
                                  or sub- processor with data protection law
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  f) The Processor will immediately inform the
                                  Controller of any Incident involving any of
                                  its’ permitted sub-contractors or
                                  sub-processors in accordance with Clause 7
                                  Incident reporting of this Agreement.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  g) The Processor will assist the Controller in
                                  informing Data Subjects if there has been an
                                  Incident involving &nbsp;any of its’ permitted
                                  sub-contractors or sub-processors in
                                  accordance with Clause 7 Incident reporting of
                                  this Agreement.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  h) The Processor will assist the Controller in
                                  informing any relevant supervisory authority
                                  of an Incident.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  9.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Data Subjects rights
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;a) The Processor &nbsp;shall have
                                  appropriate technical and organisational means
                                  taking account of the nature of the Processing
                                  &nbsp;in so far as this is possible for the
                                  fulfilment of the Controller‘s obligation to
                                  respond to requests for exercising the
                                  following Data Subject’s rights :&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  i) information rights under Articles 13 and 14
                                  of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  ii) right of access by the Data Subject under
                                  Article 15 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iii) right to rectification under Article 16
                                  of the GDOR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iv) right to erasure under Article 17 of the
                                  GDPR
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  v) right to restriction of processing under
                                  Article 18 of the GDPR
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  vi) notification regarding the right of
                                  rectification and/or erasure of personal data
                                  and/or restriction of processing under Article
                                  19 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  vii) right to data portability under Article
                                  20 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  10.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Assisting the Controller
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor will assist the Controller,
                                  taking into account the nature of the
                                  Processing and the information available to
                                  the Processor, to meet the Controller’s
                                  obligations&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  i) &nbsp;to keep Data secure in accordance
                                  with Article 32 of the GDPR
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  ii) to notify Incidents in accordance with
                                  Article 33 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iii) &nbsp;to advise Data Subjects when there
                                  has been an Incident in accordance with
                                  Article 34 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  iv) &nbsp;to carry out data protection impact
                                  assessments (DPIAs) in accordance with Article
                                  35 GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  v) &nbsp;to consult with the Controller’s
                                  supervisory authority where a DPIA indicates
                                  there is an unmitigated high risk in
                                  accordance with Article 36 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  vi) &nbsp;The Processor will immediately pass
                                  on any notices, requests or other
                                  communications from a Data Subject. The
                                  Processor will not act on any request from a
                                  Data Subject, without the full written
                                  authority of the Controller.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  vii) &nbsp;If a privacy impact assessment
                                  indicates that there is an unmitigated high
                                  risk to the rights and freedoms of the Data
                                  Subject, the Processor will assist the
                                  Controller in consulting with the relevant
                                  supervisory authority or authorities &nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  11.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Audit, inspections and legal processing
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor must provide the Controller
                                  with all the information that is needed to
                                  show that both the Processor and the
                                  Controller have met their obligations under
                                  Article 28 of the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;b) The Processor must submit and
                                  contribute to audits and inspections conducted
                                  by the Controller or another auditor mandated
                                  by the Controller.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor shall , &nbsp;allow the
                                  &nbsp; Controller and/or its auditors, or
                                  their representatives, to have access to and
                                  audit relevant processes, procedures,
                                  documentation, and/or any premises of the
                                  Processor. &nbsp;Such access may take place on
                                  5 days’ prior written notice to the Data
                                  Processor. The Controller agrees to reimburse
                                  the Processor any reasonable charge for the
                                  audit, at the hourly rates agreed within the
                                  Controller’s contract with the
                                  Processor.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) If the Controller reasonably believes that
                                  the Processor is in breach of any of its
                                  obligations under this Agreement or in which
                                  case the Controller shall not be obliged to
                                  give such prior notice and the Processor shall
                                  ensure that a Processor appointed
                                  representative shall provide full co-operation
                                  and assistance to the Controller and/or its
                                  representatives, auditors at no additional
                                  charge to the Controller.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  c) The Processor shall inform the Controller
                                  if any instruction that the Controller gives,
                                  infringes the GDPR or other EU, or EEA member
                                  state data protection provisions.
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  12.
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  &nbsp;Processor’s responsibilities and
                                  liabilities under the GDPR&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) The Processor is aware that it may be
                                  subject to enforcement action by any relevant
                                  data protection supervisory authority to which
                                  the Controller is subject under Article 58
                                  (Powers of the supervisory authority) of the
                                  GDPR.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;b) The Processor is aware that if it
                                  fails to meet its obligations as set out in
                                  this Agreement and under Article 83 (General
                                  conditions for imposing administrative fines)
                                  of the GDPR, it may be subject to an
                                  administrative fine.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;c) The Processor is aware that if it
                                  fails to meet its obligations under GDPR, it
                                  may be subject to a penalty under Article 84
                                  (Penalties) of the GDPR.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  d) The Processor is aware that if it fails to
                                  meet its obligations under GDPR, it may have
                                  to pay compensation to individual Data
                                  Subjects under Article 82 (right to
                                  compensation and liability) of the GDPR.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  e) The Processor will appoint a data
                                  protection officer, if required in accordance
                                  with Article 37 (designation of the data
                                  protection officer) of the GDPR.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  f) The Processor will appoint (in writing) a
                                  representative within the European Union if
                                  required because it is not established in the
                                  European Union and the provisions of Article 3
                                  (2) &nbsp;apply &nbsp;in accordance with
                                  Article 27 (representatives of controllers or
                                  processors not established in the Union) of
                                  the GDPR .&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  13.
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  &nbsp;Liability&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  The Processor's liability to the Controller
                                  for any loss or damage of whatsoever nature
                                  suffered or incurred by the Controller or for
                                  any liability of the Controller to any other
                                  person for any loss or damage of whatsoever
                                  nature suffered or incurred by that person
                                  shall to the extent permitted by law not
                                  exceed [T
                                </span>
                                <span >
                                  o Be agreed between the parties
                                </span>
                                <span >
                                  ].&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  14.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Termination&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) Subject to Clause 14 (b) either Party may
                                  terminate this Agreement upon giving 1 months
                                  prior written notice to the other. Upon the
                                  date of termination of this Agreement, the
                                  Processor shall return or delete at the
                                  Controller’s choice any Data received from the
                                  Controller to the Controller&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  The Processor shall not be obliged to return
                                  or delete any Data received from the
                                  Controller which has:
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  a) already been deleted in the normal course
                                  of events or
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) the Processor is required to retain by law.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  b) Notwithstanding termination of this
                                  contract, the provisions of this Agreement
                                  shall survive the termination of this
                                  Agreement and shall continue in full force and
                                  effect for a period of 2 years from the date
                                  of termination of the Agreement. &nbsp;The
                                  obligations contained in Clause 6 of this
                                  Agreement – Security and Confidentiality of
                                  Data – and Clause 7of this Agreement- Incident
                                  Reporting shall continue indefinitely.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  &nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  15.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                            
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Assignment&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  This Agreement shall not be transferred or
                                  assigned by either party except with the prior
                                  written consent of the other.&nbsp;
                                </span>
                              </p>
                              <p>&nbsp;</p>
                              <p
                                dir="ltr"
                              >
                                <span >
                                  16.&nbsp;
                                </span>
                                <span >
                                  <span
                                    className="Apple-tab-span"
                                    
                                  >
                                    &nbsp; &nbsp;&nbsp;
                                  </span>
                                </span>
                                <span >
                                  Jurisdiction&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  This Agreement shall be governed by and
                                  construed in accordance with the law of
                                  England and Wales and the parties shall submit
                                  to the exclusive jurisdiction of the Courts of
                                  England and Wales.&nbsp;
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                <span >
                                  IN WITNESS WHEREOF, each of the Parties hereto
                                  has caused the Agreement to be executed by its
                                  duly authorised representative.
                                </span>
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                &nbsp;
                              </p>
                              <p
                                dir="ltr"
                                
                              >
                                &nbsp;
                              </p>
                              <p>
                                <strong>
                                  SNIPERMOBILE SECUREMOBILE MARKETPLACE - VENDOR
                                  TERMS AND CONDITIONS
                                </strong>
                              </p>
                              <p>
                                <strong>
                                  (Version issue date: 11 September 2020)
                                </strong>
                              </p>
                              <p>
                                <strong>&nbsp;</strong>
                              </p>
                              <p>
                                These terms and conditions apply to existing
                                client users of the SNIPERMobile Service
                                provided by MobileDigital, who wish to become
                                authorised users of the SECUREMobile Marketplace
                                and the SECURE Mobile Service. They apply in
                                addition to the existing SNIPERMobile Terms and
                                Conditions.&nbsp; It is a condition of use of
                                the SECUREMobile Marketplace and the SECURE
                                Mobile Service that the Vendor accept these
                                terms and conditions. &nbsp;
                              </p>
                              <p>
                                <strong>&nbsp;</strong>
                              </p>
                              <p>
                                <strong>&nbsp;</strong>
                              </p>
                              <ol>
                                <li>Definitions</li>
                              </ol>
                              <p>In these Terms and Conditions:</p>
                              <ul>
                                <li>
                                  <strong>Approved Order</strong> means an Order
                                  approved by MobileDigital and submitted to the
                                  Vendor under clause 5.3(b);
                                </li>
                                <li>
                                  <strong>Approved Payment Method </strong>means
                                  the payment platform forming part of the
                                  SECUREMobile Service or the SECUREMobile
                                  Marketplace, which allows the Member to make
                                  payment for a Product;
                                </li>
                                <li>
                                  <strong>Business Day</strong> means a day that
                                  is not a Saturday, Sunday, or bank or public
                                  holiday in Queensland;
                                </li>
                                <li>
                                  <strong>Commission </strong>means, with
                                  respect to each Order placed by a Member
                                  through the SECUREMobile Marketplace, the
                                  amount equivalent to 3.1% of the Price of the
                                  Order;
                                </li>
                                <li>
                                  <strong>Member</strong> means a registered
                                  user of the Platform who is a purchaser,
                                  potential purchaser, user or recipient of a
                                  Product offered or supplied the Vendor using
                                  the Platform, whether through the SECUREMobile
                                  Service or through the SECUREMobile
                                  Marketplace;
                                </li>
                                <li>
                                  <strong>MobileDigital </strong>means
                                  MobileDigital Operations Pty Ltd ACN 614 173
                                  590;
                                </li>
                                <li>
                                  <strong>
                                    Monthly SECUREMobile Software Licence Fee
                                  </strong>{" "}
                                  means the monthly fee charged to the vendor
                                  for use and access to the SECUREMobile feature
                                  within the SNIPERMobile Service
                                </li>
                                <li>
                                  <strong>Order </strong>means an order
                                  submitted by a Member using the SECUREMobile
                                  Service or through the SECUREMobile
                                  Marketplace to purchase a Product offered for
                                  sale by the Vendor;
                                </li>
                                <li>
                                  <strong>Platform </strong>means e-commerce
                                  marketing and sales platform provided by
                                  MobileDigital, including the SECUREMobile
                                  Marketplace, the SECUREMobile Service and the
                                  SNIPERMobile Service and associated websites,
                                  made available for use by the Vendor to offer
                                  goods or services for sale in accordance with
                                  the Terms and Conditions;
                                </li>
                                <li>
                                  <strong>Price</strong> means the purchase
                                  price of the Product, including any applicable
                                  taxes (including GST) or delivery fees, paid
                                  or payable by the Member;
                                </li>
                                <li>
                                  <strong>Promote </strong>means the promotion
                                  of a Product to Members using MMS and/or SMS
                                  or other marketing and promotion technology
                                  channels that may develop from time to time
                                  and be in general acceptance for use in the
                                  market;
                                </li>
                                <li>
                                  <strong>Product</strong> means the goods or
                                  services offered by the Vendor to Members
                                  using the Platform, whether through the
                                  SNIPERMobile Service or directly through the
                                  SECUREMobile Marketplace;
                                </li>
                                <li>
                                  <strong>Reconciliation Period </strong>means
                                  each period of 7 days ending at 11.59am on
                                  Monday;
                                </li>
                                <li>
                                  [
                                  <strong>
                                    <em>
                                      SWS Lawyers Note: We don’t think this
                                      definition is required, given the limited
                                      use of the term ‘SaaS’. See amendments to
                                      definition of ‘SECUREMobile Service’ and
                                      ‘SNIPERMobile Service’.
                                    </em>
                                  </strong>
                                  ]<strong>SECUREMobile</strong>{" "}
                                  <strong>Marketplace</strong> is the ecommerce
                                  marketplace operated by MobileDigital located
                                  at{" "}
                                  <a href="http://www.securemobile.net">
                                    securemobile.net
                                  </a>{" "}
                                  where Members can purchase Products, and
                                  Vendors can advertise their Products for sale;
                                </li>
                                <li>
                                  <strong>SECUREMobile Service </strong>means
                                  the fast-mobile checkout and payment software
                                  as a service platform offered to Vendors
                                  seeking to sell Products promoted using
                                  SNIPERMobile Service;
                                </li>
                                <li>
                                  <strong>Service Fee </strong>means, with
                                  respect to each Order, the amount equivalent
                                  to 2.9% of the total Price of the Order; [
                                  <strong>
                                    <em>
                                      SWS Lawyers Note: The concepts of ‘taxes’
                                      and ‘delivery fees’ are included in the
                                      definition of ‘Price’, so no need to
                                      repeat here.
                                    </em>
                                  </strong>
                                  ]
                                </li>
                                <li>
                                  <strong>
                                    SNIPERMobile Mobile Terms and Conditions
                                  </strong>{" "}
                                  means the terms and conditions applicable to
                                  the SNIPERMobile Service, available on request
                                  or on the SNIPERMobile.com website;
                                </li>
                                <li>
                                  <strong>SNIPERMobile Service</strong> means
                                  the software as a service platform offered by
                                  MobileDigital under the name SNIPERMobile;
                                </li>
                                <li>
                                  <strong>Terms and Conditions </strong>means
                                  these terms and conditions;
                                </li>
                                <li>
                                  <strong>Transaction</strong>{" "}
                                  <strong>Proceeds </strong>means the proceeds
                                  of Approved Orders received by MobileDigital
                                  including the Price, any delivery fees, taxes
                                  and other charges payable by the Member; and
                                </li>
                                <li>
                                  <strong>Vendor </strong>means an authorised
                                  vendor who offers goods or services for sale
                                  through the SECUREMobile Serviceor the
                                  SECUREMobile Marketplace.
                                </li>
                              </ul>
                              <ol start="2">
                                <li>
                                  Application of these Terms and Conditions
                                  <ul>
                                    <li>
                                      These Terms and Conditions govern the sale
                                      of the Products on the Platform. The
                                      listing of the Products on the Platform by
                                      the Vendor is conclusive evidence of the
                                      Vendor’s acceptance that the Terms and
                                      Conditions apply and are binding on them.
                                    </li>
                                    <li>
                                      These Terms and Conditions must be read in
                                      conjunction with the SNIPERMobile Mobile
                                      Terms and Conditions. Where there is any
                                      direct conflict between these Terms and
                                      Conditions and the SNIPERMobile Mobile
                                      Terms and Conditions, these Terms and
                                      Conditions will prevail.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  The Platform
                                  <ul>
                                    <li>
                                      It is a condition of the Vendor’s use of
                                      the Platform that the Vendor is a current
                                      subscriber to the SNIPERMobile Service.
                                    </li>
                                    <li>
                                      The Vendor may offer goods or services for
                                      sale on the Platform in accordance with
                                      these Terms and Conditions.
                                    </li>
                                    <li>
                                      The Vendor must obtain prior approval from
                                      MobileDigital before goods or services are
                                      offered for sale on the Platform.
                                      MobileDigital may, at its sole discretion,
                                      refuse to approve, withdraw approval of,
                                      goods or services proposed by the Vendor
                                      for any reason, including if MobileDigital
                                      reasonably considers that the goods or
                                      services:
                                      <ul>
                                        <li>
                                          are unlawful or encourage unlawful
                                          activity;
                                        </li>
                                        <li>
                                          infringe the intellectual property
                                          rights of any third party;
                                        </li>
                                        <li>
                                          are offensive or inappropriate; or
                                        </li>
                                        <li>
                                          do not conform with MobileDigital’s
                                          Quality Assurance requirements for
                                          product presentation and formatting,
                                          volumes, stock and delivery time
                                          frames.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      If MobileDigital withdraws approval of any
                                      goods or services are offered for sale on
                                      the Platform:
                                      <ul>
                                        <li>
                                          it must notify the Vendor of the
                                          withdrawal of approval as soon as
                                          reasonably practicable;
                                        </li>
                                        <li>
                                          the Vendor must immediately withdraw
                                          the goods or services from sale on the
                                          Platform; and
                                        </li>
                                        <li>
                                          MobileDigital may suspend or withdraw
                                          the goods or services from sale on the
                                          Platform.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Vendor acknowledges that:
                                      <ul>
                                        <li>
                                          MobileDigital acts as a process and
                                          payment agent to facilitate the
                                          purchase of Products by the Member
                                          from the Vendor using the Platform;
                                        </li>
                                        <li>
                                          the contract for the sale and purchase
                                          of Products is solely between the
                                          Vendor and the Member, and
                                          MobileDigital is not a party to that
                                          contract; and
                                        </li>
                                        <li>
                                          the Vendor is solely responsible for
                                          the description of the Products,
                                          fulfillment of the Order, including
                                          arranging delivery of the Products and
                                          responding to any Member complaint or
                                          claim arising out of or in connection
                                          with the sale of the Product.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="4">
                                <li>
                                  Availability of the Platform
                                  <ul>
                                    <li>
                                      MobileDigital will use reasonable
                                      endeavours to ensure that where it is
                                      reasonably practicable to do so:
                                      <ul>
                                        <li>
                                          it gives prior notice on its website
                                          of any planned interruption to the
                                          availability of the Platform; and
                                        </li>
                                        <li>
                                          any maintenance, upgrade or other
                                          planned interruption to the Platform
                                          is scheduled to minimise disruption.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      Despite clause 1, MobileDigital:
                                      <ul>
                                        <li>
                                          may terminate, suspend or modify
                                          operation of the Platform at any time
                                          and for any reason;
                                        </li>
                                        <li>
                                          does not give any guarantee or
                                          warranty that the Platform will
                                          operate uninterrupted, fault-free or
                                          without delay or without compromise of
                                          its security systems.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      MobileDigital may suspend or terminate the
                                      Vendor’s use of the Platform if:
                                      <ul>
                                        <li>
                                          the Vendor breaches or MobileDigital
                                          believes that the Vendor has breached
                                          any of these Terms and Conditions or
                                          the SNIPERMobile Terms and Conditions;
                                        </li>
                                        <li>
                                          MobileDigital believes it is unlawful
                                          to allow the Vendor to use the
                                          Platform;
                                        </li>
                                        <li>
                                          MobileDigital detects any suspicious
                                          activity with respect to the access
                                          to, or use of, the Platform by, or
                                          purportedly by, the Vendor; or
                                        </li>
                                        <li>
                                          MobileDigital is unable to provide
                                          access to the Platform due to any
                                          circumstance beyond its reasonable
                                          control.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      To the maximum extent permitted by law and
                                      subject to clause 1, MobileDigital
                                      excludes any responsibility or liability
                                      for the temporary or permanent
                                      unavailability of the Platform for any
                                      reason whatsoever.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Order processing
                                  <ul>
                                    <li>
                                      The Member will pay the Price in respect
                                      of a Product to MobileDigital at the time
                                      of placing an Order on the Platform, using
                                      an Approved Payment Method, and
                                      MobileDigital will transfer such payments
                                      to the Vendor in accordance with clause
                                      7.3.&nbsp;
                                    </li>
                                    <li>
                                      The Vendor acknowledges that all Orders
                                      are subject to approval by MobileDigital
                                      and MobileDigital may, at its sole
                                      discretion, refuse to approve an Order.
                                    </li>
                                    <li>
                                      Upon receipt of payment from the Member
                                      and approval of the Order by MobileDigital
                                      , MobileDigital will:
                                      <ul>
                                        <li>
                                          issue an order confirmation and
                                          payment receipt to the Member; and
                                        </li>
                                        <li>
                                          submit the Order, including
                                          transaction details and a copy of the
                                          payment receipt, to the Vendor to
                                          enable the Vendor to fulfil the Order.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="6">
                                <li>
                                  Refunds
                                  <ul>
                                    <li>
                                      The Vendor may request the payment of a
                                      refund to a Member of all or part of the
                                      Price of a Product (
                                      <strong>Refund</strong>{" "}
                                      <strong>Amount</strong>) by completing the
                                      refund process within the SNIPERMobile
                                      portal.
                                    </li>
                                    <li>
                                      Subject to completion of the refund
                                      request by the Vendor within the
                                      SNIPERMobile portal:
                                      <ul>
                                        <li>
                                          MobileDigital will arrange the refund
                                          to the Member of the Refund Amount
                                          using the Approved Payment Method; and
                                        </li>
                                        <li>
                                          the Vendor must pay the Refund Amount,
                                          less the amount of any Commission
                                          attributable to that Refund Amount, to
                                          MobileDigital.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="7">
                                <li>
                                  Payments
                                  <ul>
                                    <li>
                                      The Vendor appoints MobileDigital as its
                                      payments collection agent to:
                                      <ul>
                                        <li>
                                          receive and process payments from
                                          Members;
                                        </li>
                                        <li>process refunds to Members; and</li>
                                        <li>
                                          hold Transaction Proceeds on behalf of
                                          the Vendor before remitting them to
                                          the Vendor pursuant to clause 3,
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <p>
                                in accordance with the Terms and Conditions.
                              </p>
                              <ul>
                                <li>
                                  In consideration of MobileDigital providing
                                  access to the Platform to the Vendor and
                                  acting as the payment and process agent in
                                  accordance with the Terms and Conditions, the
                                  Vendor must pay to MobileDigital for each
                                  Approved Order:
                                  <ul>
                                    <li>
                                      placed for Products in direct response to
                                      being Promoted through the SNIPERMobile
                                      Service, the Service Fee; and
                                    </li>
                                    <li>
                                      for Orders placed through the SECUREMobile
                                      Marketplace, without being Promoted
                                      through the SNIPERMobile Service:
                                      <ul>
                                        <li>the Commission; and</li>
                                        <li>the Service Fee.</li>
                                      </ul>
                                    </li>
                                    <li>
                                      As soon as reasonably practicable, and in
                                      any event within 2 Business Days,
                                      following the end of each Reconciliation
                                      Period, MobileDigital must calculate and
                                      remit to the Vendor’s nominated bank
                                      account, the aggregate of the Transaction
                                      Proceeds received by MobileDigital during
                                      the Reconciliation Period, after
                                      offsetting to the extent available:
                                      <ul>
                                        <li>
                                          the aggregate of Service Fees, if any,
                                          payable for Approved Orders;
                                        </li>
                                        <li>
                                          the aggregate of Commissions, if any,
                                          payable for Approved Orders; and
                                        </li>
                                        <li>
                                          the aggregate of Refund Amounts, if
                                          any, after deduction of any
                                          Commissions attributable to those
                                          Refund Amounts,
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ul>
                              <p>
                                for the relevant Reconciliation Period, and must
                                provide details of the calculation to the
                                Vendor.
                              </p>
                              <ul>
                                <li>
                                  If the aggregate of Refund Amounts, after
                                  deduction of any Commissions attributable to
                                  those Refund Amounts, owing to MobileDigital
                                  for a Reconciliation Period exceeds the
                                  aggregate of the Transaction Proceeds
                                  available after offsetting any Services Fees
                                  and Commissions, the Vendor must remit the
                                  outstanding balance to MobileDigital’s
                                  nominated bank account within 2 Business Days
                                  after receipt of MobileDigital’s calculation
                                  provided under clause 7.3.
                                </li>
                              </ul>
                              <ol start="8">
                                <li>
                                  <a name="_Toc523927777"></a> GST
                                  <ul>
                                    <li>
                                      Defined terms in this clause 8 have the
                                      meanings given to them in{" "}
                                      <em>
                                        A New Tax System (Goods and Services)
                                        Act 1999
                                      </em>{" "}
                                      (Cth)(<strong>GST Law</strong>).
                                    </li>
                                    <li>
                                      Any consideration or payment obligations
                                      in the Terms and Conditions are exclusive
                                      of GST.
                                    </li>
                                    <li>
                                      If a Supply made under or in connection
                                      with the Terms and Conditions is a Taxable
                                      Supply for which the consideration is
                                      money, the consideration for the Supply is
                                      to be increased by an additional amount
                                      equal to the amount of the consideration
                                      multiplied by the relevant GST rate.
                                    </li>
                                    <li>
                                      A party that receives consideration,
                                      whether the consideration is in money or
                                      other than money from another party, must
                                      give to the other party a Tax Invoice in a
                                      form that complies with the GST Law within
                                      14 days after the end of the month in
                                      which the consideration is received, or an
                                      invoice issued, in relation to the Supply,
                                      whichever occurs first.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Vendor obligations
                                  <ul>
                                    <li>
                                      The Vendor must:
                                      <ul>
                                        <li>
                                          not list a Product on the Platform
                                          unless it has available sufficient
                                          stock and resources to be able to
                                          fulfill all reasonably anticipated
                                          Orders in a timely manner;
                                        </li>
                                        <li>
                                          immediately notify MobileDigital if
                                          any Product becomes unavailable, or it
                                          reasonably anticipates the Product to
                                          become unavailable for immediate
                                          supply, in which case MobileDigital
                                          may suspend or terminate the offering
                                          of the Product on the Platform;
                                        </li>
                                        <li>
                                          supply and deliver the Products to the
                                          Member in a timely manner in
                                          accordance with the relevant Order;
                                        </li>
                                        <li>
                                          ensure that it holds all necessary
                                          licences, authorisations and approvals
                                          to supply the Products through the
                                          Platform;
                                        </li>
                                        <li>
                                          not provide any false, misleading or
                                          inaccurate information on the Platform
                                          about the Products;
                                        </li>
                                        <li>
                                          ensure that the Products are supplied
                                          to the Member as described on the
                                          Platform;
                                        </li>
                                        <li>
                                          ensure that the Products are packaged
                                          to protect against damage during
                                          handling, transportation and delivery;
                                        </li>
                                        <li>
                                          not damage or interfere with the
                                          Platform or engage in any conduct
                                          likely to damage the reputation of the
                                          Platform;
                                        </li>
                                        <li>
                                          not engage in any unlawful conduct in
                                          connection with the Platform;
                                        </li>
                                        <li>
                                          respond to and attempt to resolve in
                                          good faith and in a timely manner, any
                                          claims or demands by a Member
                                          concerning a Product, including any
                                          actual or alleged defect in the
                                          Product or failure to comply with any
                                          of the Vendor’s obligations under this
                                          clause 9.1;
                                        </li>
                                        <li>
                                          comply with the requirements set out
                                          in clause 3.3 (d); and
                                        </li>
                                        <li>
                                          without limiting any of the foregoing,
                                          not engage in any spam marketing or
                                          similar practices, and otherwise
                                          ensure that it has all necessary
                                          approvals or consents, including,
                                          where required, from the Member, to
                                          promote the Products to all Members to
                                          whom any communications are sent using
                                          the SNIPERMobile Service.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Vendor is responsible for managing the
                                      return of any Products by a Member.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Warranties
                                  <ul>
                                    <li>
                                      The Vendor warrants to MobileDigital and
                                      the Member that:
                                      <ul>
                                        <li>
                                          it has legal title to any Product
                                          listed on the Platform clear of any
                                          encumbrances, liens and claims;
                                        </li>
                                        <li>
                                          the supply of the Product through the
                                          Platform is lawful;
                                        </li>
                                        <li>
                                          the description of the Product
                                          provided on the Platform is current
                                          and accurate;
                                        </li>
                                        <li>
                                          it will fulfill all Orders in a timely
                                          manner; and
                                        </li>
                                        <li>
                                          the Products will be supplied to the
                                          Member free of any defects and in full
                                          compliance with all applicable laws.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Vendor acknowledges and agrees that it
                                      is solely responsible for:
                                      <ul>
                                        <li>
                                          the description of Products and
                                          fulfillment of all Orders including
                                          delivery;
                                        </li>
                                        <li>the quality of Products;</li>
                                        <li>
                                          the performance of Products; and
                                        </li>
                                        <li>
                                          the management and resolution of any
                                          dispute or claim by the Member or any
                                          third party in respect of a Product.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="11">
                                <li>
                                  Liability and Indemnities
                                  <ul>
                                    <li>
                                      Nothing in this clause 11 excludes,
                                      restricts or modifies any terms,
                                      conditions or warranties, or MobileDigital
                                      ’s liability for them, which are imposed
                                      or implied by statute, including but not
                                      limited to the{" "}
                                      <em>Competition and Consumer Act 2010</em>{" "}
                                      (Cth), and by statute cannot be excluded,
                                      restricted or modified. All exclusions and
                                      limitations of MobileDigital ’s liability
                                      under these Terms and Conditions must be
                                      read subject to this clause 1.
                                    </li>
                                    <li>
                                      To the extent it is lawfully able to do
                                      so, MobileDigital expressly limits its
                                      liability for breach of any non-excludable
                                      statutory guarantee with respect to any
                                      supply of services (or failure to supply
                                      services) to the supply of the services
                                      again or the payment of the costs of
                                      having the services supplied again.
                                    </li>
                                    <li>
                                      The Vendor acknowledges and agrees that
                                      MobileDigital will not be liable for, and
                                      the Vendor waives any right it has to
                                      claim, any loss not arising naturally
                                      according to the usual course of things,
                                      any loss of income, loss of actual or
                                      anticipated profit, loss of production,
                                      loss of contract, loss of Members,
                                      goodwill, opportunity or business, or loss
                                      of data arising under or in connection
                                      with the use of the Platform or these
                                      Terms and Conditions, whether in contract,
                                      tort (including negligence), under
                                      statute, in equity or otherwise.
                                    </li>
                                    <li>
                                      Subject to clauses 1, 11.2 and 11.3, to
                                      the maximum extent permitted by law, the
                                      maximum aggregate liability of
                                      MobileDigital arising out of or in
                                      connection with these Terms and
                                      Conditions, whether arising under
                                      contract, in tort (including negligence),
                                      in equity, under statute or otherwise,
                                      will be limited to $1.00.
                                    </li>
                                    <li>
                                      The Vendor agrees to indemnify and keep
                                      indemnified MobileDigital, its directors,
                                      officers, employees and agents, against
                                      any and all liability or claim incurred or
                                      suffered by MobileDigital arising from or
                                      in any way connected with:
                                      <ul>
                                        <li>
                                          the description or quality of the
                                          Products;
                                        </li>
                                        <li>
                                          a breach by the Vendor of any of its
                                          obligations under these Terms and
                                          Conditions or any of the warranties
                                          given by the Vendor pursuant to these
                                          terms and Conditions; or
                                        </li>
                                        <li>
                                          any act or default or omission or
                                          wilful misconduct or negligence of the
                                          Vendor in connection with these Terms
                                          and Conditions,
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <p>
                                provided that the Vendor will not be liable
                                under this clause 11.5 to the extent to which
                                the liability or claim is caused or contributed
                                to by any negligent or wrongful act or omission
                                or wilful misconduct of MobileDigital.
                              </p>
                              <ol start="12">
                                <li>Privacy</li>
                              </ol>
                              <p>
                                MobileDigital will comply with its obligations
                                under the <em>Privacy Act 1988</em> (Cth) and
                                will deal with personal information in
                                accordance with its published privacy
                                policy.&nbsp; This policy is available on
                                request or on MobileDigital ’s website.
                              </p>
                              <ol start="13">
                                <li>
                                  Amendment of Terms and Conditions
                                  <ul>
                                    <li>
                                      MobileDigital may at any time amend the
                                      Terms and Conditions, including the rate
                                      of the Service Fee or the Commission, by
                                      giving not less than 30 days’ prior notice
                                      to the Vendor, by publishing the amended
                                      Terms and Conditions on its website.
                                    </li>
                                    <li>
                                      If MobileDigital publishes the revised
                                      Terms and Conditions on its website, the
                                      amended Terms and Conditions (or Service
                                      Fee or Commission) are effective from the
                                      effective date stated in the Terms and
                                      Conditions or 30 days after the date of
                                      publication on the website, whichever is
                                      later.
                                    </li>
                                  </ul>
                                </li>
                                <li>Severance</li>
                              </ol>
                              <p>
                                If any provision or part of any provision of
                                these Terms and Conditions is invalid, illegal
                                or unenforceable, such provision or part thereof
                                will be severed from the Terms and Conditions
                                and the remainder will continue in full force
                                and effect.
                              </p>
                              <ol start="15">
                                <li>Assignment</li>
                              </ol>
                              <p>
                                MobileDigital may assign its rights or novate
                                its rights or obligations under these Terms and
                                Conditions (in whole or in part) to any person
                                without the consent of the Vendor.
                              </p>
                              <ol start="16">
                                <li>Governing Law and Jurisdiction</li>
                              </ol>
                              <p>
                                The Terms and Conditions will be governed by and
                                construed in accordance with the laws in force
                                in the State of Queensland. Each party submits
                                to the non-exclusive jurisdiction of the courts
                                of Queensland.
                              </p>
                              <p>
                                <strong>
                                  SNIPERMOBILE SERVICE AND SECUREMOBILE
                                  MARKETPLACE - MEMBER TERMS AND CONDITIONS
                                </strong>
                              </p>
                              <p>
                                <strong>
                                  (Version issue date: 11 September 2020)
                                </strong>
                              </p>
                              <ol>
                                <li>Definitions</li>
                              </ol>
                              <p>In these Terms and Conditions:</p>
                              <ul>
                                <li>
                                  <strong>Approved Order</strong> means an Order
                                  approved by MobileDigital and submitted to the
                                  Third Party Supplier under clause 4;
                                </li>
                                <li>
                                  <strong>Approved Payment Method </strong>means
                                  a credit card or debit card or other payment
                                  method compatible with the payment platform
                                  forming part of the Platform;
                                </li>
                                <li>
                                  <strong>Member</strong> means the person who
                                  is the registered user of a Member Account, or
                                  where the context requires, a person who uses
                                  or accesses a Member Account;
                                </li>
                                <li>
                                  <strong>Member Account</strong> means an
                                  authorised user account established on the
                                  Platform in accordance with clause 7 to enable
                                  the purchase of Products through the Platform;
                                </li>
                                <li>
                                  <strong>MobileDigital</strong> means
                                  MobileDigital Operations Pty Ltd ACN 614 173
                                  590;
                                </li>
                                <li>
                                  <strong>Order </strong>means an order
                                  submitted by the Member using the SECUREMobile
                                  Service or through the SECUREMobile
                                  Marketplace to purchase a Product listed by a
                                  Third Party Supplier;
                                </li>
                                <li>
                                  <strong>Platform </strong>means the e-commerce
                                  marketing and sales platform provided by
                                  MobileDigital, including the SECUREMobile
                                  Marketplace, the SECUREMobile Service and the
                                  SNIPERMobile Service and associated websites,
                                  made available for use by Third Party
                                  Suppliers to offer goods or services for sale
                                  to Members;
                                </li>
                                <li>
                                  <strong>Price</strong> means the purchase
                                  price of the Products, including any
                                  applicable taxes (including GST) or delivery
                                  fees, paid or payable by the Member;
                                </li>
                                <li>
                                  <strong>Product</strong> means the goods or
                                  services offered for sale on or by the
                                  Platform by a Third Party Supplier;
                                </li>
                                <li>
                                  <strong>Purchase Transaction</strong> means
                                  the purchase of a Product pursuant to an
                                  Approved Order;
                                </li>
                                <li>
                                  <strong>SNIPERMobile Service</strong> means
                                  the SaaS platform offered by MobileDigital
                                  under the name SNIPERMobile;
                                </li>
                                <li>
                                  <strong>SECUREMobile</strong>{" "}
                                  <strong>Marketplace</strong> is the ecommerce
                                  marketplace operated by MobileDigital located
                                  at{" "}
                                  <a href="http://www.securemobile.net">
                                    securemobile.net
                                  </a>{" "}
                                  where Members can purchase Products, and Third
                                  Party Suppliers can advertise their Products
                                  for sale;
                                </li>
                                <li>
                                  <strong>SECUREMobile Service </strong>means
                                  the fast mobile checkout and payment service
                                  offered to Third Party Suppliers seeking to
                                  sell Products promoted using SNIPERMobile
                                  Service;
                                </li>
                                <li>
                                  <strong>Terms and Conditions </strong>means
                                  these terms and conditions; and
                                </li>
                                <li>
                                  <strong>Third Party Supplier </strong>means an
                                  authorised vendor who offers goods or services
                                  for sale through the SECUREMobile Service or
                                  the SECUREMobile Marketplace.
                                </li>
                              </ul>
                              <ol start="2">
                                <li>
                                  Application of these Terms and Conditions
                                </li>
                              </ol>
                              <p>
                                Subject to clause 3, the Terms and Conditions
                                govern the use of the Platform including the
                                purchase of Products. The establishment of a
                                Member Account or placing of an Order by the
                                Member is conclusive evidence of the Member’s
                                acceptance that the Terms and Conditions apply
                                and are binding on the Member.
                              </p>
                              <ol start="3">
                                <li>
                                  Consumer Rights
                                  <ul>
                                    <li>
                                      As a consumer, the Member has certain
                                      rights under consumer protection
                                      legislation including statutory guarantees
                                      under the Australian Consumer Law that
                                      goods will be of acceptable quality, match
                                      their description and be fit for any
                                      purpose made known by the consumer, and
                                      that services supplied will be provided
                                      with due care and skill and be reasonably
                                      fit for any specified purpose (
                                      <strong>Consumer Rights</strong>).
                                    </li>
                                    <li>
                                      Nothing in these Terms and Conditions is
                                      intended to exclude, restrict or modify
                                      any of the Member’s Consumer Rights,
                                      including by limiting MobileDigital’s
                                      liability or imposing liability on the
                                      Member in a manner which would be
                                      considered unfair under the relevant
                                      consumer protection laws. Limitations and
                                      exclusions are made only to the extent
                                      that MobileDigital may legally do
                                      so.&nbsp; All exclusions and limitations
                                      of MobileDigital’s liability under these
                                      Terms and Conditions must be read subject
                                      to this clause 3.2.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  The Platform
                                  <ul>
                                    <li>
                                      Subject to meeting the eligibility
                                      requirements set out in clause 1, the
                                      Member may purchase the Products by
                                      placing an Order using the Platform.
                                    </li>
                                    <li>
                                      All Orders are subject to approval by
                                      MobileDigital. MobileDigital may refuse to
                                      approve an Order if MobileDigital:
                                      <ul>
                                        <li>
                                          suspects any person has committed or
                                          is attempting to commit fraud with
                                          respect to the Order;
                                        </li>
                                        <li>
                                          considers the Order to be unlawful;
                                        </li>
                                        <li>
                                          suspects the person placing the Order
                                          is not the Member or is not authorised
                                          to access the Member Account;
                                        </li>
                                        <li>
                                          is unable to authenticate or verify
                                          the Order;
                                        </li>
                                        <li>
                                          payment of the Price is declined for
                                          any reason; or
                                        </li>
                                        <li>
                                          believes the Order has been placed by,
                                          or on behalf a Member whose access to
                                          the Platform is suspended or has been
                                          terminated for any reason.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Member acknowledges that:
                                      <ul>
                                        <li>
                                          MobileDigital acts as a process and
                                          payment agent to facilitate the
                                          purchase of the Products by the Member
                                          from the Third Party Supplier using
                                          the Platform;
                                        </li>
                                        <li>
                                          the contract for the purchase of the
                                          Products is solely between the Third
                                          Party Supplier and the Member, and
                                          MobileDigital is not a party to that
                                          contract;
                                        </li>
                                        <li>
                                          the Third Party Supplier is solely
                                          responsible for the description of the
                                          Products, the fulfillment of the
                                          Order, including arranging delivery of
                                          the Product and responding to any
                                          Member complaint or claim arising out
                                          of or in connection with the Product;
                                          and
                                        </li>
                                        <li>
                                          subject to clause 3, the Member is
                                          responsible for all Purchase
                                          Transactions made using their Member
                                          Account, except in circumstances
                                          where, as a direct consequence of a
                                          breach of the Terms and Conditions by
                                          MobileDigital and without any fault on
                                          the part of the Member, the Purchase
                                          Transaction has been effected
                                          fraudulently.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="5">
                                <li>
                                  Availability of the Platform
                                  <ul>
                                    <li>
                                      MobileDigital will use reasonable
                                      endeavours to ensure that where it is
                                      reasonably practicable to do so:
                                      <ul>
                                        <li>
                                          it gives prior notice on its website
                                          of any planned interruption to the
                                          availability of the Platform; and
                                        </li>
                                        <li>
                                          any maintenance, upgrade or other
                                          planned interruption to the Platform
                                          is scheduled to minimise disruption to
                                          the Platform.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      Despite clause 5.1, MobileDigital:
                                      <ul>
                                        <li>
                                          may suspend or modify operation of the
                                          Platform at any time and for any
                                          reason;
                                        </li>
                                        <li>
                                          does not give any guarantee or
                                          warranty that the Platform will
                                          operate uninterrupted, fault-free or
                                          without delay or without compromise of
                                          its security systems.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      MobileDigital may suspend or terminate the
                                      Member’s access to the Platform if:
                                      <ul>
                                        <li>
                                          the Member breaches or MobileDigital
                                          believes that the Member has breached
                                          any of these terms and Conditions;
                                        </li>
                                        <li>
                                          MobileDigital believes it is unlawful
                                          to allow the Member to access the
                                          Platform;
                                        </li>
                                        <li>
                                          MobileDigital detects any suspicious
                                          activity with respect to the access
                                          to, or use of, the Platform (including
                                          the Member Account) by, or purportedly
                                          by, the Member or where MobileDigital
                                          is unable to authenticate or verify
                                          any information provided by, or
                                          purportedly by, the Member or where
                                          MobileDigital believes any such
                                          information to be incorrect; or
                                        </li>
                                        <li>
                                          MobileDigital is unable to provide
                                          access to the Platform due to any
                                          circumstance beyond its reasonable
                                          control.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      To the maximum extent permitted by law and
                                      subject to clause 3, MobileDigital
                                      excludes any responsibility or liability
                                      for the unavailability of the Platform for
                                      any reason whatsoever.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Member Obligations
                                  <ul>
                                    <li>
                                      To be eligible to place an Order using the
                                      Platform , a person must:
                                      <ul>
                                        <li>
                                          be capable of entering into a legally
                                          binding contract;
                                        </li>
                                        <li>
                                          have a valid Australian mobile
                                          telephone number;
                                        </li>
                                        <li>
                                          provide a valid delivery address in
                                          Australia;
                                        </li>
                                        <li>
                                          be able to pay the Price using an
                                          Approved Payment Method; and
                                        </li>
                                        <li>hold a current Member Account.</li>
                                      </ul>
                                    </li>
                                    <li>
                                      By submitting an Order or completing an
                                      application for a Member Account, a person
                                      warrants that they meet all of the
                                      requirements set out in clause 1.
                                    </li>
                                    <li>
                                      The Member agrees that they will not:
                                      <ul>
                                        <li>
                                          use the Platform for any unlawful
                                          purpose;
                                        </li>
                                        <li>
                                          interfere with, or attempt to
                                          interfere with, the operation of the
                                          Platform;
                                        </li>
                                        <li>
                                          provide false or misleading
                                          information to MobileDigital in
                                          connection with their Member Account
                                          or any Order;
                                        </li>
                                        <li>
                                          use or provide information about any
                                          payment method, including the details
                                          of any credit card or debit card, in
                                          connection with the Member Account or
                                          any Order, unless the Member is
                                          authorised to provide or use those
                                          details; or
                                        </li>
                                        <li>
                                          copy, modify, alter, amend or use any
                                          intellectual property, content, logos,
                                          graphics, icons or other content
                                          published by MobileDigital except as
                                          expressly permitted by MobileDigital.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Member must:
                                      <ul>
                                        <li>
                                          use all reasonable endeavours to
                                          prevent the unauthorised use of or
                                          access to their Member Account,
                                          including by taking reasonable steps
                                          to prevent the disclosure of any
                                          password;
                                        </li>
                                        <li>
                                          notify MobileDigital as soon as
                                          possible if they become aware of any
                                          unauthorised use of or access to their
                                          Member Account;
                                        </li>
                                        <li>
                                          comply with the terms and conditions
                                          of use of any credit cards or debit
                                          cards or other Approved Payment Method
                                          used by the Member, including the
                                          payment of any fees or charges imposed
                                          by the issuer or payment service
                                          provider; and
                                        </li>
                                        <li>
                                          notify MobileDigital immediately if
                                          they cease to be the authorised user
                                          of the mobile telephone number
                                          identified in the Member Account.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="7">
                                <li>
                                  Member Account
                                  <ul>
                                    <li>
                                      A person who wishes to place an Order must
                                      establish a Member Account by completing
                                      the account details at or before the time
                                      of placing their first Order, using the
                                      account registration portal on the
                                      Platform.
                                    </li>
                                    <li>
                                      The Member is responsible for the accuracy
                                      and currency of all registration details
                                      in their Member Account and may amend
                                      those details by accessing the account
                                      through the Platform.
                                    </li>
                                    <li>
                                      The Member agrees that MobileDigital may
                                      update the details held in the Member
                                      Account using information lawfully
                                      provided to MobileDigital by the Member or
                                      its bank or other service providers.
                                    </li>
                                    <li>
                                      The Member agrees that in addition to any
                                      other lawful method, MobileDigital may use
                                      the contact details in the Member Account
                                      to contact the Member.
                                    </li>
                                    <li>
                                      The Member is responsible for all use of
                                      their Member Account, including the
                                      placement of any Orders, except in
                                      circumstances where:
                                      <ul>
                                        <li>
                                          the use is undertaken without the
                                          Member’s actual or implied consent and
                                          in the absence of any breach of the
                                          Terms and Conditions by the Member;
                                          and
                                        </li>
                                        <li>
                                          a negligent or wrongful act or
                                          omission or wilful misconduct of
                                          MobileDigital or any of its directors,
                                          officers, employees and agents has
                                          enabled the use.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="8">
                                <li>
                                  Order Processing and Payments
                                  <ul>
                                    <li>
                                      The Member must pay the Price in respect
                                      of a Product to MobileDigital at the time
                                      of placing an Order on the Platform, using
                                      an Approved Payment Method.
                                    </li>
                                    <li>
                                      The Member authorises MobileDigital to:
                                      <ul>
                                        <li>
                                          initiate payment for an Order using
                                          the Member’s selected method of
                                          payment;
                                        </li>
                                        <li>
                                          provide the Member’s payment details
                                          to a third party payment services
                                          provider; and
                                        </li>
                                        <li>
                                          collect the Price on behalf of the
                                          Third Party Supplier.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      The Member agrees that MobileDigital may:
                                      <ul>
                                        <li>
                                          save payment information, including
                                          credit card or debit card numbers and
                                          expiry dates, provided by the Member
                                          in the Member Registration or when
                                          placing an Order or making any other
                                          transaction on the Platform where card
                                          information is entered;
                                        </li>
                                        <li>
                                          use the stored payment details as the
                                          Member’s default payment method for
                                          future Orders.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      Upon receipt of payment from the Member
                                      and approval of the Order by
                                      MobileDigital, MobileDigital will:
                                      <ul>
                                        <li>
                                          issue an order confirmation and
                                          payment receipt to the Member; and
                                        </li>
                                        <li>
                                          submit the Order, including
                                          transaction details and a copy of the
                                          payment receipt, to the Third Party
                                          Supplier to enable the Third Party
                                          Supplier to fulfil the Order.
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <ol start="9">
                                <li>
                                  Refunds
                                  <ul>
                                    <li>
                                      The Member is responsible for notifying
                                      the Third Party Supplier if they intend to
                                      return any Product or request a refund.
                                      The Product must be returned to the Third
                                      Party Supplier within the period specified
                                      and in the manner required by the Third
                                      Party Supplier’s returns policy or as
                                      otherwise agreed by the Third Party
                                      Supplier.
                                    </li>
                                    <li>
                                      The Third Party Supplier is responsible
                                      for requesting and approving the payment
                                      of a refund to a Member.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Liability
                                  <ul>
                                    <li>
                                      The Member acknowledges and agrees that,
                                      subject to clause 3, MobileDigital:
                                      <ul>
                                        <li>
                                          does not give any express warranty or
                                          guarantee as to the quality,
                                          suitability or availability of the
                                          Products or the Platform or any
                                          description or information provided in
                                          respect of the Products; and
                                        </li>
                                        <li>
                                          accepts no responsibility for the
                                          Products and will not be liable to the
                                          Member or any third party for any
                                          loss, cost or damage suffered directly
                                          or indirectly in connection with the
                                          Products.
                                        </li>
                                      </ul>
                                    </li>
                                    <li>
                                      To the extent it is lawfully able to do
                                      so, MobileDigital expressly limits its
                                      liability for breach of any non-excludable
                                      statutory guarantee with respect to any
                                      supply of services (or failure to supply
                                      services) to the supply of the services
                                      again or the payment of the costs of
                                      having the services supplied again.
                                    </li>
                                    <li>
                                      The Member acknowledges and agrees that
                                      MobileDigital will not be liable for, and
                                      the Member waives any right it has to
                                      claim, any loss not arising naturally
                                      according to the usual course of things,
                                      loss of actual or anticipated profit, loss
                                      of reputation goodwill, opportunity or
                                      business, or loss of data or any indirect,
                                      special or consequential loss or damage
                                      arising under or in connection with the
                                      use or unavailability of Service or
                                      otherwise in connection with these Terms
                                      and Conditions.
                                    </li>
                                    <li>
                                      Subject to clause 3, the Member agrees to
                                      indemnify and keep indemnified
                                      MobileDigital, its directors, officers,
                                      employees and agents, against any and all
                                      liability or claim incurred or suffered by
                                      MobileDigital arising from or in any way
                                      connected with:
                                      <ul>
                                        <li>
                                          a breach by the Member of any of its
                                          obligations under these Terms and
                                          Conditions;
                                        </li>
                                        <li>
                                          the placement of any Order using the
                                          Member’s Member Account; or
                                        </li>
                                        <li>
                                          any act or default or omission or
                                          wilful misconduct or negligence of the
                                          Member in connection with these Terms
                                          and Conditions,
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <p>
                                provided that the Member will not be liable
                                under this clause 10.4 to the extent to which
                                the liability or claim is caused or contributed
                                to by any negligent or wrongful act or omission
                                or wilful misconduct of MobileDigital or any of
                                its directors, officers, employees and agents.
                              </p>
                              <ol start="11">
                                <li>
                                  Disputes and complaints
                                  <ul>
                                    <li>
                                      The Member must directly contact the Third
                                      Party Supplier to resolve any claim or
                                      dispute which arises between the Member
                                      and Third Party Supplier. MobileDigital is
                                      not responsible for facilitating
                                      communication between the Member and the
                                      Third Party Supplier or assisting to
                                      resolve such a dispute. The Member must
                                      continue to meet its obligations under
                                      these Terms and Conditions despite the
                                      existence of any dispute under this clause
                                      1.
                                    </li>
                                    <li>
                                      If the Member wishes to submit a complaint
                                      in writing to MobileDigital in relation to
                                      the Platform, the Member should contact
                                      MobileDigital using the contact details
                                      below:
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <p>
                                Email:{" "}
                                <a href="mailto:customerservice@mobile.digital">
                                  Memberservice@mobile.digital
                                </a>
                                &nbsp;
                              </p>
                              <ol start="12">
                                <li>
                                  Privacy
                                  <ul>
                                    <li>
                                      MobileDigital will comply with its
                                      obligations under the{" "}
                                      <em>Privacy Act 1988</em> (Cth) and will
                                      deal with personal information in
                                      accordance with its published privacy
                                      policy. This policy is available on
                                      request or on MobileDigital’s website.
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  Amendment of Terms and Conditions
                                  <ul>
                                    <li>
                                      MobileDigital may at any time amend the
                                      Terms and Conditions applicable to future
                                      Orders and may notify the Member of these
                                      changes or provide any other notice of a
                                      variation of the Terms and Conditions, by
                                      giving not less than 30 days’ prior notice
                                      to the Member by publishing the revised
                                      Terms and Conditions or notice on its
                                      website or otherwise notifying the Member
                                      of the amendment by contacting the Member
                                      using the contact details in the Member
                                      Account.
                                    </li>
                                    <li>
                                      If MobileDigital publishes the revised
                                      Terms and Conditions on its website, the
                                      revised Terms and Conditions are effective
                                      from the effective date noted in the Terms
                                      and Conditions or 30 days after the date
                                      of publication on the website, whichever
                                      is later.
                                    </li>
                                  </ul>
                                </li>
                                <li>Severance</li>
                              </ol>
                              <p>
                                If any provision or part of any provision of
                                these Terms and Conditions is invalid, illegal
                                or unenforceable, such provision or part thereof
                                will be severed from the Terms and Conditions
                                and the remainder will continue in full force
                                and effect.
                              </p>
                              <ol start="15">
                                <li>Assignment</li>
                              </ol>
                              <p>
                                MobileDigital may assign its rights or novate
                                its rights or obligations under these Terms and
                                Conditions (in whole or in part) to any person
                                without the consent of the Member.
                              </p>
                              <ol start="16">
                                <li>Governing Law and Jurisdiction</li>
                              </ol>
                              <p>
                                The Terms and Conditions will be governed by and
                                construed in accordance with the laws in force
                                in the State of Queensland. Each party submits
                                to the non-exclusive jurisdiction of the courts
                                of Queensland.
                              </p>
                              <p>&nbsp;</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  };
}

export default connect(null, null)(TermsPage);
