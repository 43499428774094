import _ from "lodash";
import { FETCH_BILLING_ADDRESS, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  billing_address: null,
};

export default (state = INITIAL_STATE, action) => {
  let new_state, billing_address;
  switch (action.type) {
    case FETCH_BILLING_ADDRESS:
      billing_address = action.payload.customer.addresses.filter((v) => {
        return v.id == action.payload.customer.default_billing;
      });
      if (billing_address.length > 0) {
        billing_address = billing_address[0];
      }
      new_state = {
        billing_address: billing_address,
      };
      return new_state;
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
