import React from "react";
import { connect } from "react-redux";
import { hideSuppliedByPopup } from "../actions";
import { getCDN } from "../helper/getCDN"

class SuppliedByPopup extends React.Component {
  render() {
    if(this.props.seller) { 
      const seller = this.props.seller

      let show_supplied_by_popup_class = ""
      if(this.props.show_supplied_by_popup) {
        show_supplied_by_popup_class = "show-popup"
      }

      let seller_url = seller.seller_url 
      let supplied_by_img = seller.supplied_by_image

      return (
        <div id="supplied-by-popup" className={`${show_supplied_by_popup_class}`}>
            <div 
              id="supplied-by-popup-x" 
              onClick={() => {
                this.props.hideSuppliedByPopup()
              }}
            >x</div>
            <div id="supplied-by-popup-content">
                <div id="supplied-by-popup-image-parent"><a href={seller_url} target="_blank"><img src={`${getCDN()}web/supplied_by_logos/${supplied_by_img}`}/></a></div>
                <p>{seller.popup_description}</p>  
            </div>
        </div>
      );
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {  
    seller: state.seller.seller,
    show_supplied_by_popup: state.show_supplied_by_popup
  };
};

export default connect(
  mapStateToProps,
  { hideSuppliedByPopup }
)(SuppliedByPopup);
