import _ from "lodash";
import {
  FETCH_CART,
  LOGOUT,
  ORDER_PLACED
} from "../actions/types";

const INITIAL_STATE = {
  cart: null
};

export default (state = INITIAL_STATE, action) => {
  let cart, new_state;
  switch (action.type) {
    case FETCH_CART:
      cart = {
        ...action.payload
      };
      new_state = {
        cart: cart
      }
      return new_state;
    case LOGOUT:
    case ORDER_PLACED:
      return INITIAL_STATE 
    default:
      return state;
  }
};