import React from "react";
import {
  fetchSavedCards,
  setPaymentMethod,
  placeOrder,
  inputValidation,
  clearValidation,
  setShippingInformation,
  setLoaderCheckout,
  setLoader,
  getCheckoutSuccess,
  saveGiftCardOrder,
  clearGiftCardDetails,
  createEmptyCart,
  setCartbyAPI,
  createEmptyCartbyAPI,
  setEmptyCartbyAPI,
  fetchCart,
  setSelectedShippingAddress
} from "../actions";
import { connect } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from "@stripe/react-stripe-js";
import history from "../history";
import { getReCaptchaToken } from "../helper/recaptcha";

class CheckoutCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddNewCard: false,
      localStorageProducts: []
    };
  }

  componentDidMount = () => {
    if (this.props.auth.is_signed_in) {
      this.props.fetchSavedCards(() => {
        this.props.setLoaderCheckout("saved_cards");
      });
    }
  };


  handleSubmit = async (event) => {
    let input_validation = {};

    let pm = this.props.payment_card.pm;
    if (pm === "new_card") {
      const { stripe, elements } = this.props;
      this.props.setLoader(true, "Getting Card Information...");
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });
      console.log("error", error);
      console.log("paymentMethod", paymentMethod);
      this.props.setLoader(false, null);
      this.props.clearValidation();
      if (error) {
        console.log("error", error);
        input_validation.card = [error.message];
      } else {
        pm = paymentMethod.id;
      }
    }

    if (pm === null) {
      input_validation.card = ["Please enter a Card for Payment"];
    }

    if (!this.props.selected_shipping_address) {
      input_validation.address = ["Please complete your shipping address"];
    }

    if (Object.keys(input_validation).length > 0) {
      this.props.inputValidation(input_validation);
      return;
    }

    console.log(this.props);
    this.props.handleCancelTimer();

    const has_gift_card = this.props.cart_products.items.some((p) => {
      if (p.options) {
        return p.options.some((o) => o.attribute_code === "gift_card_price");
      } else {
        return false;
      }
    });

    let data = {
      addressInformation: {
        billing_address: {
          city: this.props.billing_address.city,
          countryId: this.props.billing_address.country_id,
          customerAddressId: this.props.billing_address.customer_address_id,
          customerId: this.props.billing_address.customer_id,
          email: this.props.billing_address.email,
          firstname: this.props.billing_address.firstname,
          lastname: this.props.billing_address.lastname,
          postcode: this.props.billing_address.postcode,
          region: this.props.billing_address["region"]["region"],
          regionCode: this.props.billing_address["region"]["region_code"],
          regionId: this.props.billing_address["region"]["region_id"],
          saveInAddressBook: this.props.billing_address.save_in_address_book,
          street: this.props.billing_address.street,
          telephone: this.props.billing_address.telephone,
        },
        extension_attributes: {},
        shipping_address: {
          city: this.props.selected_shipping_address.city,
          company: "",
          countryId: this.props.selected_shipping_address.country_id,
          firstname: this.props.selected_shipping_address.firstname,
          lastname: this.props.selected_shipping_address.lastname,
          postcode: this.props.selected_shipping_address.postcode,
          region: this.props.selected_shipping_address["region"]["region"],
          regionCode:
              this.props.selected_shipping_address["region"]["region_code"],
          regionId: this.props.selected_shipping_address["region"]["region_id"],
          saveInAddressBook: 0,
          street: this.props.selected_shipping_address.street,
          telephone: this.props.selected_shipping_address.telephone,
        },
        shipping_carrier_code: this.props.shipping_method.carrier_code,
        shipping_method_code: this.props.shipping_method.method_code,
      },
    };

    this.props.setLoader(true, "Saving Shipping Information...");
    this.props.setShippingInformation(data, () => {
      console.log("saved shipping information");

      const data = {
        billingAddress: {
          city: this.props.billing_address.city,
          countryId: this.props.billing_address.country_id,
          customerAddressId: this.props.billing_address.customer_address_id,
          customerId: this.props.billing_address.customer_id,
          email: this.props.billing_address.email,
          firstname: this.props.billing_address.firstname,
          lastname: this.props.billing_address.lastname,
          postcode: this.props.billing_address.postcode,
          region: this.props.billing_address["region"]["region"],
          regionCode: this.props.billing_address["region"]["region_code"],
          regionId: this.props.billing_address["region"]["region_id"],
          saveInAddressBook: 0,
          street: this.props.billing_address.street,
          telephone: this.props.billing_address.telephone,
        },
        paymentMethod: {
          method: "stripe_payments",
          additional_data: {
            cc_save: true,
            cc_stripejs_token: pm,
          },
        },
      };

      this.props.setLoader(true, "Placing Order...")
      this.props.createEmptyCart(() => {
        this.props.placeOrder(data, () => {
          this.props.getCheckoutSuccess(this.props.affiliate_seller_id, this.props.seller, localStorage.campaign_id, (order_id) => {
            delete localStorage.campaign_id
            delete localStorage.cartItems
            console.log('this.props.gift_card_details', this.props.gift_card_details)
            if (has_gift_card && this.props.gift_card_details.length > 0) {
              const cart_id = this.props.gift_card_details[0].cart_id // should be same cart id for all
              getReCaptchaToken('checkout', (token) => {
                const data = {
                  r_token: token,
                  cart_id: cart_id,
                  order_id: order_id,
                }
                this.props.saveGiftCardOrder(data, () => {
                  this.props.clearGiftCardDetails()
                  this.props.setLoader(false, null)
                })
              })
            } else {
              this.props.setLoader(false, null)
            }
          })
          history.push('/success')
        })
      })
    })
  };

  renderSavedCards = () => {
    let check_first = this.state.showAddNewCard ? false : true;
    const card_rows = this.props.saved_cards.map((v, i, a) => {
      let month = v.exp_month < 10 ? "0" + v.exp_month : v.exp_month;
      let year = v.exp_year.toString().substring(2, 4);
      let expiry = month + "/" + year;

      let checked = false;
      if (check_first && i === 0) {
        checked = true;
      }

      return (
          <div key={v.id} className="checkout-card">
            <div className="card-radio-parent">
              <input
                  type="radio"
                  name="card"
                  defaultChecked={checked}
                  className="card-radio"
                  checked={this.props.payment_card.pm === v.id}
                  onClick={() => {
                    this.props.setPaymentMethod(v.id);
                  }}
              />
            </div>
            <div className="card-number-parent">
              <div className="card-number">{v.last4}</div>
            </div>
            <div className="card-expiry-parent">
              <div className="card-expiry">exp {expiry}</div>
            </div>
          </div>
      );
    });

    if (this.state.showAddNewCard) {
      card_rows[card_rows.length] = (
          <div key="new_card" className="checkout-card">
            <div className="card-radio-parent">
              <input
                  type="radio"
                  name="card"
                  defaultChecked={true}
                  id="card-radio-new"
                  onClick={() => {
                    this.props.setPaymentMethod("new_card");
                  }}
              />
            </div>
            <div id="card-new-card-heading">
              <div id="add-new-card-heading">Add New Card</div>
            </div>
          </div>
      );
    }

    return card_rows;
  };

  render() {
    let cardMessage = this.props.input_validation.card.map((message) => (
        <div key={message} className="input-validation">
          {message}
        </div>
    ));

    return (
        <React.Fragment>
          <div id="checkout-payment">
            <div id="checkout-payment-heading">Payment Information</div>
            <div id="checkout-cards">{this.renderSavedCards()}</div>
            {this.state.showAddNewCard && (
                <div id="card-new-card-inputs" className="form">
                  <div className="input-box">
                    <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                            },
                          },
                        }}
                    />
                  </div>
                </div>
            )}
            {!this.state.showAddNewCard && (
                <div
                    className="payment-add-update"
                    onClick={() => {
                      this.setState({ showAddNewCard: true });
                      this.props.setPaymentMethod("new_card");
                    }}
                >
                  Add New
                </div>
            )}
            <div id="payment-failed-message">{cardMessage}</div>
            <div
                className="checkout-arrow"
                onClick={() => {
                  if (!this.state.showAddNewCard) {
                    this.props.setPaymentMethod("new_card");
                  } else {
                    if (this.props.saved_cards.length > 0) {
                      this.props.setPaymentMethod(this.props.saved_cards[0].id);
                    } else {
                      this.props.setPaymentMethod(null);
                    }
                  }

                  this.setState({ showAddNewCard: !this.state.showAddNewCard });
                }}
            ></div>
          </div>
          <div id="checkout-confirm-purchase">
            <button
                type="submit"
                disabled={this.props.disable_checkout_button}
                onClick={(e) => {
                  this.handleSubmit(e);
                }}
            >
              Confirm Purchase
            </button>
            <img
                id="checkout-lock-logo"
                src="https://cdn-staging.securemobile.net/web/images/SecureMobile-lock-logo.png"
            />
          </div>
          {/* {(has_gift_card && !this.props.gift_card_details_reviewed) ?
          <div id="checkout-review-gift-card-details">
            <button onClick={() => {
              history.push('/giftcarddetails')
            }}>Review Gift Card Details</button>
          </div>
          : <div id="checkout-confirm-purchase">
              <button type="submit" disabled={this.props.disable_checkout_button} onClick={(e) => {
                this.handleSubmit(e)
              }}>Confirm Purchase</button>
            </div>
        } */}
        </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    saved_cards: state.saved_cards.saved_cards,
    input_validation: state.input_validation,
    billing_address: state.billing_address.billing_address,
    cart: state.cart.cart,
    payment_card: state.payment_card,
    shipping_addresses: state.shipping_addresses,
    shipping_method: state.shipping_method.shipping_method,
    selected_shipping_address:
    state.selected_shipping_address.selected_shipping_address,
    seller: state.seller.seller,
    auth: state.auth,
    disable_checkout_button: state.disable_checkout_button,
    affiliate_seller_id: state.affiliate_seller_id,
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

const ReadyCheckoutCards = connect(mapStateToProps, {
  fetchSavedCards,
  setPaymentMethod,
  placeOrder,
  inputValidation,
  clearValidation,
  setShippingInformation,
  setLoaderCheckout,
  setLoader,
  getCheckoutSuccess,
  saveGiftCardOrder,
  clearGiftCardDetails,
  createEmptyCart,
  setCartbyAPI,
  createEmptyCartbyAPI,
  setEmptyCartbyAPI,
  fetchCart,
  setSelectedShippingAddress
})(CheckoutCards);

const InjectedCheckoutCards = ({ handleCancelTimer }) => (
    <ElementsConsumer>
      {({ stripe, elements }) => (
          <ReadyCheckoutCards
              stripe={stripe}
              elements={elements}
              handleCancelTimer={handleCancelTimer}
          />
      )}
    </ElementsConsumer>
);

let pk_stripe = null;
if (
    window.location.origin === "https://www.tap2buy.com" ||
    window.location.origin ===
    "https://live-giftguru.dnrx1u9c16dvg.amplifyapp.com"
) {
  pk_stripe = "pk_live_RQdsNdxOG0IYGefPnPF14tFY";
  pk_stripe = "pk_test_rnMv6e5UnHRP3LkC8dW6TyZr00hBMZIlvn"; //Replace for demos
} else {
  pk_stripe = "pk_test_rnMv6e5UnHRP3LkC8dW6TyZr00hBMZIlvn";
}

const stripePromise = loadStripe(pk_stripe);

const ReadyRegistration = ({ handleCancelTimer }) => (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutCards handleCancelTimer={handleCancelTimer} />
    </Elements>
);

export default ReadyRegistration;
