import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById } from "../../actions"
import { connect } from "react-redux";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"

class GiftCardSendNowOrSchedulePage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uuid: null,
      gift_card_details_id: null,
      product_name: null,
      gift_card_price: null,
      product_image: null
    }
  }

  componentDidMount = () => {
    const gift_card_details_id = this.props.match.params.giftcarddetailsid 

    this.setState({ gift_card_details_id: gift_card_details_id })

    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart()
        this.props.fetchCartProducts()
      })
    }

    this.props.fetchGiftCardDetailsById(gift_card_details_id, (gift_card_details) => { 
      this.props.fetchProduct(gift_card_details.product_id, () => {
        const product_name = this.props.product.name
        const gift_card_price = this.props.product.configurable_options_products[CONSTANTS.GIFT_CARD_PRICE_OPTION_ID].find(v => v.sku === gift_card_details.configurable_product_sku).option_title
        const product_image = this.props.product.images[0]
  
        this.setState({ product_name: product_name, gift_card_price: gift_card_price, product_image: product_image })
      })
    })
  }

  render() {
    let gift_card_no = null
    let recipient_firstname = null
    let recipient_lastname = null
    let recipient_mobile = null
    let recipient_message = null
    const details = this.props.gift_card_details.find(v => v.id === this.state.gift_card_details_id)
    if(details) {
      gift_card_no = details.gift_card_no
      recipient_firstname = details.recipient_firstname
      recipient_lastname = details.recipient_lastname
      recipient_mobile = details.recipient_mobile
      recipient_message = details.recipient_message
    }

    return ( 
      <div id="page-gift-card-send-now-or-schedule"> 
        <h1 className="h1">Gift Card Send Details</h1>
        <div>
          <div className="product-gift-card-details-container">
            <div className="gift-card-details-product-info">
              <div className="gift-card-details-left-col">
                <div className="gift-card-details-product-name">{this.state.product_name}</div> 
                <div className="gift-card-details-product-price-container">
                  <div className="gift-card-details-product-price-desc">Price:</div> 
                  <div className="gift-card-details-product-price">${this.state.gift_card_price}</div> 
                </div>
              </div>
              <div className="gift-card-details-right-col">
                <img className="gift-card-details-product-image" src={this.state.product_image} /> 
              </div>
            </div>
            <div id="gift-card-show-details">
              <div className="show-details-row">
                <div className="show-details-desc">Gift Card No:</div>
                <div className="show-details-value">{gift_card_no}</div> 
              </div>
              <div className="show-details-row">
                <div className="show-details-desc">Valid Until:</div>
                <div className="show-details-value">3 Years From Send</div>
              </div>
              <div className="show-details-row">
                <div className="show-details-desc">Deliver To:</div>
                <div className="show-details-value">{recipient_firstname} {recipient_lastname}</div>
              </div>
              <div className="show-details-row" style={{ marginBottom: 20 }}>
                <div className="show-details-desc">On Mobile:</div>
                <div className="show-details-value">{recipient_mobile}</div> 
              </div>
              <div id="show-details-message">{recipient_message}</div>
              <div id="show-details-action-buttons">
                <div className="show-details-button-parent">
                  <button className="show-details-button" onClick={() => { 
                    this.props.saveGiftCardSendTime(this.state.gift_card_details_id, new Date().toISOString().substr(0, 19).replace('T', ' '), 'Australia/Sydney')
                    history.push(`/giftcard/mmstemplates/${this.state.gift_card_details_id}`)
                  }}>Send Now</button>
                </div>
                <div className="show-details-or">Or</div>
                <div className="show-details-button-parent">
                  <button className="show-details-button" onClick={() => {
                    history.push(`/giftcard/schedule/${this.state.gift_card_details_id}`)
                  }}>Schedule</button> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
    product: state.product.product 
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, saveGiftCardSendTime, fetchProduct, fetchGiftCardDetailsById }
  )(GiftCardSendNowOrSchedulePage) 
);
