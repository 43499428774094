import _ from "lodash";
import {
  FETCH_CATEGORY_PRODUCTS
} from "../actions/types";

const INITIAL_STATE = {
  category: null
};

export default (state = INITIAL_STATE, action) => {
  let category, new_state;
  switch (action.type) {
    case FETCH_CATEGORY_PRODUCTS:
      category = {
        ...action.payload.category
      };
      new_state = {
        category: category
      }
      return new_state;
    default:
      return state;
  }
};
