import _ from "lodash";
import {
  LOGOUT,
  SET_CUSTOMER_DATA
} from "../actions/types";

// const INITIAL_STATE = {
//   customer: {
//     mobile: "0400953507",
//     email: "cameron@mobile.digital",
//     fullname: "Cameron Rowe",
//     shop_image: "https://cdn-staging.securemobile.net/web/shop_logos/the-wine-co-black.png",
//     address: {
//       street_address: "10 Bonita Court", 
//       suburb: "Mudgeeraba", 
//       state: "QLD",
//       country: "Australia",
//       postcode: "4213" 
//     } 
//   } 
// }; 

const INITIAL_STATE = {
  customer: localStorage.customer ? JSON.parse(localStorage.customer) : null 
}

export default (state = INITIAL_STATE, action) => {
  let customer, new_state;
  switch (action.type) {
    case SET_CUSTOMER_DATA:

      localStorage.customer = JSON.stringify(action.payload.customer)

      customer = {
        ...action.payload.customer
      };
      new_state = {
        customer: customer
      }
      return new_state;
    case LOGOUT:
      return INITIAL_STATE
    default:
      return state;
  }
};
