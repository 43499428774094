import _ from "lodash";
import {
  SHOW_SM_POPUP,
  HIDE_SM_POPUP
} from "../actions/types";

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_SM_POPUP:
      return true;
    case HIDE_SM_POPUP:
      return false;
    default:
      return state;
  }
};