import endpoint from "../apis/endpoint";
import {
  SET_CUSTOMER_DATA,
  AUTOLOGIN_ERROR,
  FETCH_BROWSE_PRODUCTS,
  FETCH_CATEGORY_PRODUCTS,
  FETCH_SELLER_PRODUCTS,
  FETCH_SELLER,
  FETCH_PRODUCT,
  CLEAR_VALIDATION,
  REGISTER,
  INPUT_VALIDATION,
  SHOW_CART,
  SHOW_NAV_MENU,
  FETCH_CART,
  FETCH_SHIPPING_METHOD,
  FETCH_SHIPPING_INFORMATION,
  FETCH_SAVED_CARDS,
  HIDE_CART,
  HIDE_NAV_MENU,
  FETCH_CATEGORIES,
  SET_AUTHORIZATION_TOKEN,
  SET_JWT,
  FETCH_CART_PRODUCTS,
  FETCH_SHIPPING_ADDRESSES,
  SET_PAYMENT_CARD,
  FETCH_BILLING_ADDRESS,
  FETCH_SHIPPING_METHOD_CUSTOM,
  SET_SELECTED_SHIPPING_ADDRESS,
  SET_LOADER,
  SET_LOADER_CHECKOUT,
  SET_AUTOLOGIN_PRODUCT,
  SET_CHECKOUT_SUCCESS,
  SHOW_MOBILE_POPUP,
  HIDE_MOBILE_POPUP,
  SET_UPDATE_PRODUCT_DETAILS,
  FETCH_UPDATE_PRODUCT_DETAILS,
  UNSET_AUTHORIZATION_TOKEN,
  ADD_NEW_SHIPPING_ADDRESS,
  SET_OFFLINE,
  SHOW_SM_POPUP,
  HIDE_SM_POPUP,
  LOGOUT,
  SET_LOADER_CART,
  ORDER_PLACED,
  TRIGGERED_MOBILE_POPUP,
  SET_EXPIRED_LINK_DETAILS,
  UNEXPECTED_ERROR,
  FETCH_ORDERS,
  FETCH_ORDER,
  SHOW_PRODUCT_OUT_OF_STOCK,
  HIDE_PRODUCT_OUT_OF_STOCK,
  FETCH_BROWSE_PRODUCTS_PAGINATION,
  FETCH_BROWSE_PRODUCTS_MAGENTO,
  FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION,
  FETCH_CATEGORY_PRODUCTS_MAGENTO,
  FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION,
  FETCH_CATEGORY_PRODUCTS_PAGINATION,
  DISABLE_CHECKOUT_BUTTON,
  ENABLE_CHECKOUT_BUTTON,
  UPDATE_PRODUCT_QTY,
  SHOW_SUPPLIED_BY_FOOTER,
  HIDE_SUPPLIED_BY_FOOTER,
  SHOW_SUPPLIED_BY_POPUP,
  HIDE_SUPPLIED_BY_POPUP,
  CLEAR_PRODUCTS,
  ENTERED_HOME_PAGE,
  LEAVING_HOME_PAGE,
  SET_AFFILIATE_SELLER_ID,
  SAVE_GIFT_CARD_DETAILS,
  REVIEW_GIFT_CARD_DETAILS,
  CLEAR_GIFT_CARD_DETAILS,
  SAVE_GIFT_CARD_SEND_TIME,
  FETCH_GIFT_CARD_DETAILS,
  FETCH_GIFT_CARD_DETAILS_BY_ID,
  REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID,
  HIDE_UNEXPECTED_ERROR,
  RESET_EXPIRED_LINK_DETAILS,
  HIDE_OFFLINE_POPUP,
  FETCH_MMS_TEMPLATES,
  SAVE_GIFT_CARD_MMS_TEMPLATE,
  FETCH_PASSES,
  SAVE_GIFT_CARD_PASS,
  SHOW_MESSAGE_POPUP,
  HIDE_MESSAGE_POPUP,
  CLEAR_PRODUCT,
  SET_EMPTY_CART
} from "./types";

const saveCartItemToLocalStorage = (product) => {
  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  let itemFound = false;

  cartItems.forEach((item, index) => {
    if (item.sku === product.sku) {
      if (
        product.product_type === "configurable" &&
        product.product_option &&
        product.product_option.extension_attributes &&
        product.product_option.extension_attributes.configurable_item_options
      ) {
        const newOptions = product.product_option.extension_attributes.configurable_item_options;
        const existingOptions = item.product_option.extension_attributes.configurable_item_options;

        if (
          existingOptions.length === newOptions.length &&
          existingOptions.every((opt, i) => opt.option_id === newOptions[i].option_id && opt.option_value === newOptions[i].option_value)
        ) {
          itemFound = true;
          cartItems[index].qty += product.qty;
        }
      } else {
        itemFound = true;
        cartItems[index].qty += product.qty;
      }
    }
  });

  if (!itemFound) {
    cartItems.push(product);
  }

  localStorage.setItem("cartItems", JSON.stringify(cartItems));
};

const getCartItemsFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("cartItems")) || [];
};

export const verifyToken = (jwt, autologin, callback) => async (dispatch) => {
  console.log("VERIFY TOKEN");
  console.log([jwt, autologin]);
  let response;
  try {
    response = await endpoint.post("/rest/default/V1/mobiledigital/auth", {
      jwt: jwt,
      autologin: autologin,
    });
    let data = JSON.parse(response.data);
    response.data = data;
    console.log("verifyToken", response);

    if (!response.data.status) {
      // fail
      dispatch({ type: AUTOLOGIN_ERROR, payload: response.data });
    } else if (response.data.new_user) {
      // new user
      dispatch({ type: SET_CUSTOMER_DATA, payload: response.data });
      dispatch({ type: SET_JWT, payload: jwt });
    } else if (response.data.existing_user) {
      // existing user
      dispatch({ type: SET_AUTHORIZATION_TOKEN, payload: response.data });
      dispatch({ type: SET_AUTOLOGIN_PRODUCT, payload: response.data });
    } else if (response.data.expired_link) {
      // expired link
      dispatch({ type: SET_JWT, payload: jwt });
      dispatch({
        type: SET_EXPIRED_LINK_DETAILS,
        payload: response.data.mobile,
      });
      localStorage.expired_link_mobile = response.data.mobile;
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback(response.data);
    }
  }
};

export const setExpiredLinkDetails = (mobile, callback) => async (dispatch) => {
  dispatch({ type: SET_EXPIRED_LINK_DETAILS, payload: mobile });
};

export const logout = (callback) => async (dispatch) => {
  dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
  dispatch({ type: LOGOUT });
};

export const checkEmailExists = (email, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
      "/rest/default/V1/customers/isEmailAvailable",
      {
        customerEmail: email,
        websiteId: 1,
      }
    );

    if (!response.data) {
      dispatch({
        type: INPUT_VALIDATION,
        payload: {
          email: ["The email you entered already exists"],
        },
      });
      dispatch({
        type: SET_LOADER,
        payload: {
          show: false,
          message: null,
        },
      });
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data) {
    if (callback) {
      callback();
    }
  }
};

export const fetchBrowseProducts =
  (page, products, callback) => async (dispatch) => {
    let response,
      left = 0;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/browseProducts",
        {
          params: {
            page: page,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchBrowseProducts", response);

      // check for existing product id
      const new_products = response.data.data;
      left = new_products.length;
      if (left > 0) {
        const first_product = new_products[0];
        products.forEach((v) => {
          if (first_product.id == v.id) {
            left = 0;
          }
        });
      }

      if (left !== 0) {
        if (page === 1) {
          dispatch({ type: FETCH_BROWSE_PRODUCTS, payload: response.data });
        } else {
          dispatch({
            type: FETCH_BROWSE_PRODUCTS_PAGINATION,
            payload: response.data,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        dispatch({ type: UNEXPECTED_ERROR });
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(left);
      }
    }
  };

export const fetchBrowseProductsMagento =
  (page, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        `/rest/default/V1/products-render-info?searchCriteria[currentPage]=${page}&searchCriteria[pageSize]=10&storeId=1&currencyCode=AUD`
      );

      console.log(response);

      if (page === 1) {
        dispatch({
          type: FETCH_BROWSE_PRODUCTS_MAGENTO,
          payload: response.data,
        });
      } else {
        dispatch({
          type: FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        dispatch({ type: UNEXPECTED_ERROR });
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.items) {
      if (callback) {
        callback(response.data.items.length);
      }
    }
  };

export const fetchCategoryProducts =
  (category_id, page, products, callback) => async (dispatch) => {
    let response,
      left = 0;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/categoryProducts",
        {
          params: {
            category_id: category_id,
            page: page,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchCategoryProducts", response);

      // check for existing product id
      const new_products = response.data.data;
      left = new_products.length;
      if (left > 0) {
        const first_product = new_products[0];
        products.forEach((v) => {
          if (first_product.id == v.id) {
            left = 0;
          }
        });
      }

      if (left !== 0 && page !== 1) {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_PAGINATION,
          payload: response.data,
        });
      } else {
        dispatch({ type: FETCH_CATEGORY_PRODUCTS, payload: response.data });
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        dispatch({ type: UNEXPECTED_ERROR });
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(left);
      }
    }
  };

export const fetchCategoryProductsMagento =
  (category_id, page, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        `/rest/default/V1/products-render-info?searchCriteria[filterGroups][0][filters][0][field]=category_id&searchCriteria[filterGroups][0][filters][0][value]=${category_id}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=DESC&searchCriteria[currentPage]=${page}&searchCriteria[pageSize]=10&storeId=1&currencyCode=AUD`
      );

      console.log(response);

      if (page === 1) {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_MAGENTO,
          payload: response.data,
        });
      } else {
        dispatch({
          type: FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        dispatch({ type: UNEXPECTED_ERROR });
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.items) {
      if (callback) {
        callback(response.data.items.length);
      }
    }
  };

export const clearProducts = (callback) => async (dispatch) => {
  dispatch({ type: CLEAR_PRODUCTS });
};

export const clearProduct = () => {
  return { type: CLEAR_PRODUCT }
}

export const fetchProduct = (product_id, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/product", {
      params: {
        product_id: product_id,
      },
    });

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchProduct", response);

    if (response.data.status) {
      dispatch({ type: FETCH_PRODUCT, payload: response.data });
    } else {
      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
      if(response.data.not_exists) {
        dispatch({ type: SHOW_MESSAGE_POPUP, payload: { header: "Product doesn't exist.", message: "The selected product does not exist." } });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }
  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const fetchSeller = (product_id, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/seller", {
      params: {
        product_id: product_id,
      },
    });

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchSeller", response);

    dispatch({ type: FETCH_SELLER, payload: response.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }
  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const fetchSellerInformation =
  (seller_url, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/sellerInformation",
        {
          params: {
            seller_url: seller_url,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchSellerInformation", response);

      dispatch({ type: FETCH_SELLER_PRODUCTS, payload: response.data });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        dispatch({ type: UNEXPECTED_ERROR });
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }
    if (response && response.data.status) {
      if (callback) {
        callback();
      }
    }
  };

export const register = (values, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
      "/rest/default/V1/mobiledigital/createAccount",
      values
    );

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("register", response);

    if (!response.data.status) {
      dispatch({
        type: INPUT_VALIDATION,
        payload: response.data.input_validation,
      });
      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
    } else {
      delete localStorage.customer;
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const sendMobileCode = (values, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
      "/rest/default/V1/mobiledigital/sendmobilecode",
      values
    );

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("sendMobileCode", response);

    if (!response.data.status && response.data.input_validation) {
      dispatch({
        type: INPUT_VALIDATION,
        payload: response.data.input_validation,
      });
      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const confirmMobileCode = (values, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
      "/rest/default/V1/mobiledigital/confirmmobilecode",
      values
    );

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("confirmMobileCode", response);

    if (!response.data.status && response.data.input_validation) {
      dispatch({
        type: INPUT_VALIDATION,
        payload: response.data.input_validation,
      });
      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback(response.data.jwt);
    }
  }
};

export const showCart = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_CART });
};

export const showNavMenu = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_NAV_MENU });
};

export const hideCart = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_CART });
};

export const hideNavMenu = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_NAV_MENU });
};

export const showSMPopup = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_SM_POPUP });
};

export const hideSMPopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_SM_POPUP });
};

export const showMobilePopup = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_MOBILE_POPUP });
};

export const hideMobilePopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_MOBILE_POPUP });
};

export const showUnexpectedErrorPopup = (callback) => async (dispatch) => {
  dispatch({ type: UNEXPECTED_ERROR });
};

export const hideUnexpectedErrorPopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_UNEXPECTED_ERROR });
};

export const hideOfflinePopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_OFFLINE_POPUP });
};

export const fetchCart = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/carts/mine", {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    });

    console.log("fetchCart", response);

    dispatch({ type: FETCH_CART, payload: response.data });
    dispatch({ type: FETCH_SHIPPING_ADDRESSES, payload: response.data });
    dispatch({ type: FETCH_BILLING_ADDRESS, payload: response.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.id) {
    if (callback) {
      callback();
    }
  }
};

export const setEmptyCart = (callback) => (dispatch) => {
  localStorage.removeItem("cartItems");

  dispatch({ type: SET_EMPTY_CART });

  if (callback) {
    callback();
  }
};

export const setEmptyCartbyAPI = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
        "/rest/default/V1/mobiledigital/clearcart",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
    );

    console.log("setEmptyCart,", response);
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({type: UNSET_AUTHORIZATION_TOKEN});
      } else if( error.response.status === 404){
        console.log('no cart, continue as is.');
        if (callback) {
          callback();
        }
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data) {
    if (callback) {
      callback();
    }
  }
};

export const setCart = (product, quote_id, callback) => async (dispatch) => {
  let response;
  try {
    product.quote_id = quote_id;
    if(product.qty > 0) {
      saveCartItemToLocalStorage(product);
      dispatch({type: REVIEW_GIFT_CARD_DETAILS});
      console.log("setCart", product);
    }
    else {
      dispatch({ type: SHOW_PRODUCT_OUT_OF_STOCK });
    }
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else if (
        error.response.status === 400 &&
        error.response.data.message === "Product that you are trying to add is not available."
      ) {
        dispatch({ type: SHOW_PRODUCT_OUT_OF_STOCK });
      } else if(
        error.response.status === 400 &&
        error.response.data.message === "The requested qty is not available" 
      ) {
        dispatch({ type: SHOW_MESSAGE_POPUP, payload: {header: "Qty not Available", message: error.response.data.message } });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  const cartItems = getCartItemsFromLocalStorage();
  if (cartItems && cartItems.length > 0) {
    if (callback) {
      callback(cartItems);
    }
  }
};

export const setCartbyAPI = (product, quote_id, callback) => async (dispatch) => {
  let response;
  try {
    product.quote_id = quote_id;

    response = await endpoint.post(
        "/rest/default/V1/carts/mine/items",
        {
          cartItem: product,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
    );

    dispatch({ type: REVIEW_GIFT_CARD_DETAILS });

    console.log("setCart", response);
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else if (
          error.response.status === 400 &&
          error.response.data.message === "Product that you are trying to add is not available."
      ) {
        dispatch({ type: SHOW_PRODUCT_OUT_OF_STOCK });
      } else if(
          error.response.status === 400 &&
          error.response.data.message === "The requested qty is not available"
      ) {
        dispatch({ type: SHOW_MESSAGE_POPUP, payload: {header: "Qty not Available", message: error.response.data.message } });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data) {
    if (callback) {
      callback(response.data);
    }
  }
};

export const createEmptyCart = (callback) => (dispatch) => {
  const existingCartItems = JSON.parse(localStorage.getItem("cartItems"));

  if (!existingCartItems) {
    localStorage.setItem("cartItems", JSON.stringify([]));
  }

  if (callback) {
    callback();
  }
};

export const createEmptyCartbyAPI = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
        "/rest/default/V1/carts/mine",
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
    );

    console.log("createEmptyCartbyAPI", response);
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data) {
    if (callback) {
      callback();
    }
  }
};

export const fetchCartProducts = (callback) => async (dispatch) => {
  try {
    const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    console.log("fetchCartProducts", cartItems);

    dispatch({ type: FETCH_CART_PRODUCTS, payload: { data: { items: cartItems } } });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
    dispatch({ type: UNEXPECTED_ERROR });
  }

  const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  if (callback) {
    callback(cartItems.length);
  }
};


export const fetchCustomShippingMethods =
  (address_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/shippingmethods",
        {
          params: {
            addressId: address_id,
          },
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchCustomShippingMethods", response);

      dispatch({ type: FETCH_SHIPPING_METHOD_CUSTOM, payload: response.data });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback();
      }
    }
  };

export const fetchShippingMethod =
  (address_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/carts/mine/estimate-shipping-methods-by-address-id",
        {
          addressId: address_id,
          address_id: address_id,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      console.log("fetchShippingMethod", response);

      dispatch({ type: FETCH_SHIPPING_METHOD, payload: response.data });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data) {
      if (callback) {
        callback();
      }
    }
  };

export const estimateShippingMethod =
  (values, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/carts/mine/estimate-shipping-methods",
        values,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      console.log("estimateShippingMethod", response);

      dispatch({ type: FETCH_SHIPPING_METHOD, payload: response.data });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.length) {
      if (callback) {
        callback();
      }
    }
  };

export const fetchSavedCards = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/savedcards", {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    });

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchSavedCards", response);

    dispatch({ type: FETCH_SAVED_CARDS, payload: response.data });
    dispatch({ type: SET_PAYMENT_CARD, payload: response.data.data[0].id });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const placeOrder = (values, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.post(
      "/rest/default/V1/carts/mine/payment-information",
      values,
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      }
    );
    console.log("placeOrder", response);
    dispatch({ type: ORDER_PLACED });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    // dispatch({ type: SET_LOADER, payload: { show: false, message: null }});

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        // dispatch({ type: UNEXPECTED_ERROR })
        console.log("placeOrder INPUT_VALIDATION");
        dispatch({
          type: INPUT_VALIDATION,
          payload: {
            card: ["There was an error processing your payment."],
          },
        });
      }

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: ORDER_PLACED });

      // dispatch({ type: SET_OFFLINE })

      if (callback) {
        callback();
      }
    }
  }

  if (response && response.data) {
    if (callback) {
      callback();
    }
  }
};

export const setPaymentMethod = (pm, callback) => async (dispatch) => {
  dispatch({ type: SET_PAYMENT_CARD, payload: pm });

  if (callback) {
    callback();
  }
};

export const setShippingInformation =
  (values, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/au/V1/carts/mine/shipping-information",
        values,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );
      console.log("setShippingInformation", response);
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data) {
      if (callback) {
        callback();
      }
    }
  };

export const fetchCategories = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/categories");

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchCategories", response);

    dispatch({ type: FETCH_CATEGORIES, payload: response.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      dispatch({ type: UNEXPECTED_ERROR });
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

// export const addProductToCart = (product_id, callback) => async dispatch => {
//   const response = await endpoint.post("/rest/default/V1/mobiledigital/addproducttocart", {
//     product_id: product_id
//   }, {
//     headers: {
//       "Authorization": 'Bearer '+localStorage.token
//     }
//   });

//   console.log(response)
//   let data = JSON.parse(response.data)
//   response.data = data
//   console.log(response)

//   // dispatch({ type: FETCH_CATEGORIES, payload: response.data });

//   if (response.data.status) {
//     if(callback) {
//       callback();
//     }
//   }
// }

export const removeProductFromCart =
  (item_id, callback, no_loader = false) =>
    async (dispatch) => {
      try {
        console.log('item_iditem_id', item_id)
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const updatedCartItems = cartItems.filter(item => item.id !== item_id);
        localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
        dispatch({ type: REVIEW_GIFT_CARD_DETAILS });
      } catch (error) {
        console.log("error", error);
        console.log("error.name", error.name);
        console.log("error.message", error.message);

        if (!no_loader) {
          dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
        }

        if (error.response) {
          console.log("error response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status === 401) {
            dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
          } else {
            dispatch({ type: UNEXPECTED_ERROR });
          }
        } else if (error.request) {
          console.log("error request");
          console.log(error.request);

          dispatch({ type: SET_OFFLINE });
        }
      }

      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
      if (callback) {
        callback(cartItems.length);
      }
    };

export const inputValidation = (values, callback) => async (dispatch) => {
  dispatch({ type: INPUT_VALIDATION, payload: values });
};

export const clearValidation = (callback) => async (dispatch) => {
  dispatch({ type: CLEAR_VALIDATION });
};

export const setSelectedShippingAddress =
  (address_id, addresses, callback) => async (dispatch) => {
    let selected_shipping_address = addresses.find((v) => {
      return v.id == address_id;
    });

    // if(selected_shipping_address.length > 0) {
    //   selected_shipping_address = selected_shipping_address[0]
    // } else {
    //   selected_shipping_address = null
    // }

    if (!selected_shipping_address) {
      selected_shipping_address = null;
    }

    dispatch({
      type: SET_SELECTED_SHIPPING_ADDRESS,
      payload: selected_shipping_address,
    });

    if (callback) {
      callback();
    }
  };

export const setLoader = (show, message, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER,
    payload: {
      show,
      message,
    },
  });
};

export const setLoaderCart = (show, message, callback) => async (dispatch) => {
  dispatch({
    type: SET_LOADER_CART,
    payload: {
      show,
      message,
    },
  });
};

export const setLoaderCheckout = (type, callback) => async (dispatch) => {
  dispatch({ type: SET_LOADER_CHECKOUT, payload: type });
};

export const getCheckoutSuccess =
  (affiliate_seller_id, seller, campaign_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/checkoutsuccess",
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
          params: {
            seller_id: affiliate_seller_id,
            campaign_id: campaign_id,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("getCheckoutSuccess", response);

      dispatch({
        type: SET_CHECKOUT_SUCCESS,
        payload: {
          order_id: response.data.data.order_id,
          shop_name: seller.name,
        },
      });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(response.data.data.order_id);
      }
    }
  };

export const setUpdateProductDetails =
  (details, callback) => async (dispatch) => {
    dispatch({ type: SET_UPDATE_PRODUCT_DETAILS, payload: details });
  };
export const fetchUpdateProductDetails = (callback) => async (dispatch) => {
  dispatch({ type: FETCH_UPDATE_PRODUCT_DETAILS });
};

export const addNewShippingAddress =
  (shipping_address, callback) => async (dispatch) => {
    dispatch({ type: ADD_NEW_SHIPPING_ADDRESS, payload: shipping_address });
    dispatch({
      type: SET_SELECTED_SHIPPING_ADDRESS,
      payload: shipping_address,
    });
  };

  export const updateCustomer = (customer, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.put("/rest/default/V1/customers/me", customer, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
      });
  
      console.log("updateCustomer", response);
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);
  
      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
  
      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
  
        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);
  
        dispatch({ type: SET_OFFLINE });
      }
    }
  
    if (response && response.data) {
      if (callback) {
        callback();
      }
    }
  };

export const triggeredMobilePopup = (values, callback) => async (dispatch) => {
  dispatch({ type: TRIGGERED_MOBILE_POPUP, payload: values });
};

export const fetchOrders = (callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/orders", {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    });

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchOrders", response);

    dispatch({ type: FETCH_ORDERS, payload: response.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const fetchOrder = (order_id, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get("/rest/default/V1/mobiledigital/order", {
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
      params: {
        order_id: order_id,
      },
    });

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchOrder", response);

    dispatch({ type: FETCH_ORDER, payload: response.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const hideProductOutOfStockPopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_PRODUCT_OUT_OF_STOCK });
};

export const disableCheckoutButton = (callback) => async (dispatch) => {
  dispatch({ type: DISABLE_CHECKOUT_BUTTON });
};
export const enableCheckoutButton = (callback) => async (dispatch) => {
  dispatch({ type: ENABLE_CHECKOUT_BUTTON });
};

export const updateProductQty =
  (product_id, qty, callback) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_QTY,
      payload: {
        product_id: product_id,
        qty: qty,
      },
    });
  };

export const showSuppliedByFooter = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_SUPPLIED_BY_FOOTER });
};

export const hideSuppliedByFooter = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_SUPPLIED_BY_FOOTER });
};

export const showSuppliedByPopup = (callback) => async (dispatch) => {
  dispatch({ type: SHOW_SUPPLIED_BY_POPUP });
};

export const hideSuppliedByPopup = (callback) => async (dispatch) => {
  dispatch({ type: HIDE_SUPPLIED_BY_POPUP });
};

export const enteredHomePage = (callback) => async (dispatch) => {
  dispatch({ type: ENTERED_HOME_PAGE });
};

export const leavingHomePage = (callback) => async (dispatch) => {
  dispatch({ type: LEAVING_HOME_PAGE });
};

export const setAffiliateSellerId =
  (seller_id, callback) => async (dispatch) => {
    dispatch({ type: SET_AFFILIATE_SELLER_ID, payload: seller_id });
  };

export const resetExpiredLinkDetails = (callback) => async (dispatch) => {
  console.log("resetExpiredLinkDetails");
  dispatch({ type: RESET_EXPIRED_LINK_DETAILS });
};

export const saveGiftCardDetails =
  (gift_card_details, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/mobiledigital/savegiftcarddetails",
        {
          data: JSON.stringify(gift_card_details),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("saveGiftCardDetails", response);

      if (!response.data.status) {
        if (response.data.input_validation) {
          dispatch({
            type: INPUT_VALIDATION,
            payload: response.data.input_validation,
          });
          dispatch({
            type: SET_LOADER,
            payload: {
              show: false,
              message: null,
            },
          });
        }
      } else {
        dispatch({ type: SAVE_GIFT_CARD_DETAILS, payload: response.data.data });
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(response.data.data.last_insert_id);
      }
    }
  };

export const editGiftCardDetails =
  (gift_card_details_id, gift_card_details, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/mobiledigital/editgiftcarddetails",
        {
          gift_card_details_id: gift_card_details_id,
          data: JSON.stringify(gift_card_details),
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("editGiftCardDetails", response);

      if (!response.data.status) {
        if (response.data.input_validation) {
          dispatch({
            type: INPUT_VALIDATION,
            payload: response.data.input_validation,
          });
          dispatch({
            type: SET_LOADER,
            payload: {
              show: false,
              message: null,
            },
          });
        }
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(response.data.data);
      }
    }
  };

export const removeGiftCardDetailsByCartItemId =
  (cart_id, cart_item_id, callback, no_loader = false) =>
    async (dispatch) => {
      let response;
      try {
        response = await endpoint.post(
          "/rest/default/V1/mobiledigital/deletegiftcarddetailsbycartitemid",
          {
            cart_id: cart_id,
            cart_item_id: cart_item_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        );

        let data = JSON.parse(response.data);
        response.data = data;
        console.log("removeGiftCardDetailsByCartItemId", response);

        dispatch({
          type: REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID,
          payload: {
            cart_id: cart_id,
            cart_item_id: cart_item_id,
          },
        });
      } catch (error) {
        console.log("error", error);
        console.log("error.name", error.name);
        console.log("error.message", error.message);

        if (!no_loader) {
          dispatch({ type: SET_LOADER, payload: { show: false, message: null } });
        }

        if (error.response) {
          console.log("error response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status === 401) {
            dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
          } else {
            dispatch({ type: UNEXPECTED_ERROR });
          }
        } else if (error.request) {
          console.log("error request");
          console.log(error.request);

          dispatch({ type: SET_OFFLINE });
        }
      }

      if (response && response.data.status) {
        if (callback) {
          callback();
        }
      }
    };

export const clearGiftCardDetails = (callback) => async (dispatch) => {
  dispatch({ type: CLEAR_GIFT_CARD_DETAILS });

  callback();
};

export const saveGiftCardOrder =
  (post_data, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/mobiledigital/savegiftcardorderbycart",
        post_data,
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("saveGiftCardOrder", response);

      if (!response.data.status) {
        dispatch({ type: SHOW_MESSAGE_POPUP, payload: {header: "Error Processing", message: "We processed your order but were unable to send your Gift Card. Please contact support." } });
      }
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback();
      }
    }
  };

export const saveGiftCardSendTime =
  (gift_card_details_id, date_str, delivery_timezone, callback) =>
    async (dispatch) => {
      let response;
      try {
        response = await endpoint.post(
          "/rest/default/V1/mobiledigital/savegiftcardscheduleddate",
          {
            gift_card_details_id: gift_card_details_id,
            scheduled_delivery: date_str,
            delivery_timezone: delivery_timezone,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        );

        dispatch({
          type: SAVE_GIFT_CARD_SEND_TIME,
          payload: {
            id: gift_card_details_id,
            date_str: date_str,
          },
        });

        let data = JSON.parse(response.data);
        response.data = data;
        console.log("saveGiftCardSendTime", response);
      } catch (error) {
        console.log("error", error);
        console.log("error.name", error.name);
        console.log("error.message", error.message);

        dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

        if (error.response) {
          console.log("error response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status === 401) {
            dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
          } else {
            dispatch({ type: UNEXPECTED_ERROR });
          }
        } else if (error.request) {
          console.log("error request");
          console.log(error.request);

          dispatch({ type: SET_OFFLINE });
        }
      }

      if (response && response.data.status) {
        if (callback) {
          callback();
        }
      }
    };

export const fetchGiftCardDetailsById =
  (gift_card_details_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/getgiftcarddetailsbyid",
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
          params: {
            gift_card_details_id: gift_card_details_id,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchGiftCardDetailsById", response);

      dispatch({
        type: FETCH_GIFT_CARD_DETAILS_BY_ID,
        payload: response.data.data,
      });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(response.data.data);
      }
    }
  };

export const fetchGiftCardDetailsByCart =
  (cart_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.get(
        "/rest/default/V1/mobiledigital/getgiftcarddetailsbycart",
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
          params: {
            cart_id: cart_id,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("fetchGiftCardDetailsByCart", response);

      dispatch({ type: FETCH_GIFT_CARD_DETAILS, payload: response.data.data });
    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback(response.data.data);
      }
    }
  };

export const fetchMMSTemplates = (product_id, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get(
      "/rest/default/V1/mobiledigital/getgiftcardmmstemplates",
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
        params: {
          product_id: product_id,
        },
      }
    );

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchMMSTemplates", response);

    dispatch({ type: FETCH_MMS_TEMPLATES, payload: response.data.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const saveGiftCardMMSTemplate =
  (
    gift_card_details_id,
    mms_template_id,
    click2_giftcard_mms_template_id,
    callback
  ) =>
    async (dispatch) => {
      let response;
      try {
        response = await endpoint.post(
          "/rest/default/V1/mobiledigital/savegiftcardmmstemplate",
          {
            gift_card_details_id: gift_card_details_id,
            mms_template_id: mms_template_id,
            click2_giftcard_mms_template_id: click2_giftcard_mms_template_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        );

        dispatch({
          type: SAVE_GIFT_CARD_MMS_TEMPLATE,
          payload: {
            id: gift_card_details_id,
            mms_template_id: mms_template_id,
          },
        });

        let data = JSON.parse(response.data);
        response.data = data;
        console.log("saveGiftCardMMSTemplate", response);
      } catch (error) {
        console.log("error", error);
        console.log("error.name", error.name);
        console.log("error.message", error.message);

        dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

        if (error.response) {
          console.log("error response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status === 401) {
            dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
          } else {
            dispatch({ type: UNEXPECTED_ERROR });
          }
        } else if (error.request) {
          console.log("error request");
          console.log(error.request);

          dispatch({ type: SET_OFFLINE });
        }
      }

      if (response && response.data.status) {
        if (callback) {
          callback();
        }
      }
    };

export const fetchPasses = (product_id, callback) => async (dispatch) => {
  let response;
  try {
    response = await endpoint.get(
      "/rest/default/V1/mobiledigital/getgiftcardpasses",
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
        },
        params: {
          product_id: product_id,
        },
      }
    );

    let data = JSON.parse(response.data);
    response.data = data;
    console.log("fetchPasses", response);

    dispatch({ type: FETCH_PASSES, payload: response.data.data });
  } catch (error) {
    console.log("error", error);
    console.log("error.name", error.name);
    console.log("error.message", error.message);

    dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

    if (error.response) {
      console.log("error response");
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);

      if (error.response.status === 401) {
        dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
      } else {
        dispatch({ type: UNEXPECTED_ERROR });
      }
    } else if (error.request) {
      console.log("error request");
      console.log(error.request);

      dispatch({ type: SET_OFFLINE });
    }
  }

  if (response && response.data.status) {
    if (callback) {
      callback();
    }
  }
};

export const saveGiftCardPass =
  (gift_card_details_id, pass_id, click2_giftcard_pass_id, callback) =>
    async (dispatch) => {
      let response;
      try {
        response = await endpoint.post(
          "/rest/default/V1/mobiledigital/savegiftcardpass",
          {
            gift_card_details_id: gift_card_details_id,
            pass_id: pass_id,
            click2_giftcard_pass_id: click2_giftcard_pass_id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        );

        dispatch({
          type: SAVE_GIFT_CARD_PASS,
          payload: {
            id: gift_card_details_id,
            pass_id: pass_id,
          },
        });

        let data = JSON.parse(response.data);
        response.data = data;
        console.log("saveGiftCardPass", response);
      } catch (error) {
        console.log("error", error);
        console.log("error.name", error.name);
        console.log("error.message", error.message);

        dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

        if (error.response) {
          console.log("error response");
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);

          if (error.response.status === 401) {
            dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
          } else {
            dispatch({ type: UNEXPECTED_ERROR });
          }
        } else if (error.request) {
          console.log("error request");
          console.log(error.request);

          dispatch({ type: SET_OFFLINE });
        }
      }

      if (response && response.data.status) {
        if (callback) {
          callback();
        }
      }
    };

export const setCustomerData = (data) => async (dispatch) => {
  dispatch({
    type: SET_CUSTOMER_DATA, payload: {
      customer: data
    }
  });
};

export const hideMessagePopup = (data) => async (dispatch) => {
  dispatch({ type: HIDE_MESSAGE_POPUP });
};

export const setDefaultBillingAddress =
  (billing_address_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/mobiledigital/setdefaultbilling",
        {
          address_id: billing_address_id
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("setDefaultBillingAddress", response);

    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback();
      }
    }
  };

  export const setDefaultShippingAddress =
  (shipping_address_id, callback) => async (dispatch) => {
    let response;
    try {
      response = await endpoint.post(
        "/rest/default/V1/mobiledigital/setdefaultshipping",
        {
          address_id: shipping_address_id
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        }
      );

      let data = JSON.parse(response.data);
      response.data = data;
      console.log("setDefaultShippingAddress", response);

    } catch (error) {
      console.log("error", error);
      console.log("error.name", error.name);
      console.log("error.message", error.message);

      dispatch({ type: SET_LOADER, payload: { show: false, message: null } });

      if (error.response) {
        console.log("error response");
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        if (error.response.status === 401) {
          dispatch({ type: UNSET_AUTHORIZATION_TOKEN });
        } else {
          dispatch({ type: UNEXPECTED_ERROR });
        }
      } else if (error.request) {
        console.log("error request");
        console.log(error.request);

        dispatch({ type: SET_OFFLINE });
      }
    }

    if (response && response.data.status) {
      if (callback) {
        callback();
      }
    }
  };