import {
  SET_LOADER,
  SET_LOADER_CART,
  SET_LOADER_CHECKOUT
} from "../actions/types";

const INITIAL_STATE = {
  show: false,
  message: null,
  checkout: {
    saved_cards: false,
    cart: false,
    cart_products: false, 
    seller: false,
    gift_cards: false
  },
  cart: {
    show: false,
    message: null
  }
};

export default (state = INITIAL_STATE, action) => {
  let new_state;
  switch (action.type) {
    case SET_LOADER: 
      new_state = {
        show: action.payload.show,
        message: action.payload.message,
        checkout: { 
          ...INITIAL_STATE.checkout 
        },
        cart: {
          ...INITIAL_STATE.cart 
        }
      }
      return new_state
    case SET_LOADER_CHECKOUT: 
      new_state = {
        show: state.show,
        message: state.message,
        checkout: { 
          ...state.checkout,
          [action.payload]: true
        },
        cart: {
          ...INITIAL_STATE.cart  
        }
      }   

      if(
        new_state.checkout.saved_cards && 
        new_state.checkout.cart && 
        new_state.checkout.cart_products && 
        new_state.checkout.seller &&
        new_state.checkout.gift_cards
      ) {
        new_state.show = false
        new_state.message = null 
      }

      return new_state
    case SET_LOADER_CART: 
      new_state = {
        show: state.show,
        message: state.message,
        checkout: { 
          ...state.checkout
        },
        cart: {
          show: action.payload.show,
          message: action.payload.message
        }
      }
      return new_state 
    default:
      return state;
  }
};
