import _ from "lodash";
import {
  ENTERED_HOME_PAGE,
  LEAVING_HOME_PAGE
} from "../actions/types";

const INITIAL_STATE = false; 

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ENTERED_HOME_PAGE:
      return true;
    case LEAVING_HOME_PAGE:
      return false
    default:
      return state;
  }
};
