import _ from "lodash";
import {
  DISABLE_CHECKOUT_BUTTON,
  ENABLE_CHECKOUT_BUTTON
} from "../actions/types";

const INITIAL_STATE = null

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISABLE_CHECKOUT_BUTTON: 
      return true;
    case ENABLE_CHECKOUT_BUTTON: 
      return false  
    default:
      return state;
  }
};
