import React from "react";
import { fetchProduct, fetchUpdateProductDetails, fetchCart, setCart, fetchCartProducts, removeProductFromCart, setLoader, fetchSeller } from "../../actions";
import { connect } from "react-redux";
import Product from "../Product";
import requireAuth from "../requireAuth";
import history from "../../history"; 

class UpdateProductPage extends React.Component {
  componentDidMount = () => {
    const product_id = this.props.match.params.id
    this.props.setLoader(true, "Fetching Product...")
    this.props.fetchProduct(product_id, () => {
      this.props.fetchSeller(product_id, () => {
        this.props.setLoader(false, null)
      })
    })
    if(this.props.auth.is_signed_in) {
      this.props.fetchUpdateProductDetails()
      this.props.fetchCart()
    }
  }

  handleUpdateProductInCart = (product, qty, configurable_options, configurable_product_sku) => {
    console.log('u', product) 
    console.log('qty', qty) 
    console.log('configurable_options', configurable_options)  
    console.log('configurable_product_sku', configurable_product_sku)  

    this.props.setLoader(true, "Updating Cart...")
    let product_data = {}
    if(configurable_options) {
      
      this.props.removeProductFromCart(this.props.update_product_details.item_id, () => {
        let configurable_item_options = []
        for (const [key, value] of Object.entries(configurable_options)) {
          if(value !== null) {
            configurable_item_options.push({
              option_id: value.option_id.toString(), 
              option_value: parseInt(value.option_value)
            })
          }
        } 
  
        product_data = {
          sku: product.sku,
          qty: qty,
          product_type: 'configurable',
          product_option: {
            extension_attributes: {
              configurable_item_options: configurable_item_options
            }
          }
        }
  
        // product_data = {
        //   item_id: this.props.update_product_details.item_id,
        //   sku: configurable_product_sku,
        //   qty: qty,
        //   product_type: 'simple'
        // }
        // console.log('product_data', product_data)
        // return 
  
        this.props.setCart(product_data, this.props.cart.id, () => {
          console.log('successully added configurable product')
          this.props.fetchCart(() => {
            this.props.setLoader(false, null)
            history.push('/checkout')
          })
          this.props.fetchCartProducts() 
        })
      })
    } else {
      product_data = {
        item_id: this.props.update_product_details.item_id,
        sku: product.sku,
        qty: qty,
        product_type: 'simple',
      }

      this.props.setCart(product_data, this.props.cart.id, () => {
        console.log('successully added simple product') 
        this.props.fetchCart(() => {
          this.props.setLoader(false, null)
          history.push('/checkout')
        })
        this.props.fetchCartProducts()
      })
    }   
  }

  renderProduct = () => {
    return (
      <div id="page-product">
        <Product 
          update={true} 
          qty={this.props.update_product_details.qty} 
          options={this.props.update_product_details.options} 
          product={this.props.product} 
          seller={this.props.seller}
          handleProductAction={this.handleUpdateProductInCart}
        />
      </div>
    );
  }

  render() {
    if(this.props.product) {
      return this.renderProduct()
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    product: state.product.product,
    update_product_details: state.update_product_details.update_product_details,
    cart: state.cart.cart,
    auth: state.auth,
    seller: state.seller.seller
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchProduct, fetchUpdateProductDetails, fetchCart, setCart, fetchCartProducts, removeProductFromCart, setLoader, fetchSeller }
  )(UpdateProductPage)
);
