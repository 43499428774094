import _ from "lodash";
import {
  FETCH_PRODUCT,
  CLEAR_PRODUCT
} from "../actions/types";

let INITIAL_STATE = {
  product: null
}

export default (state = INITIAL_STATE, action) => {
  let new_state, product;
  switch (action.type) {
    case FETCH_PRODUCT:
      product = {
        ...action.payload.data
      }
      new_state = { 
        product: product
      }
      return new_state;
    case CLEAR_PRODUCT:
      return {
        ...INITIAL_STATE
      }
    default:
      return state;
  }
};
