import React from "react";
import { connect } from "react-redux";
import { getCDN } from "../helper/getCDN"
import { showSuppliedByPopup } from "../actions";

class SuppliedByFooter extends React.Component { 
  render() {
    let seller_url, supplied_by_img, supplied_by_content = null
    if(this.props.seller) {
      const seller = this.props.seller
      
      seller_url = seller.seller_url 
      supplied_by_img = seller.supplied_by_image

      supplied_by_content = (
        <div id="supplied-by-footer" onClick={() => {
          this.props.showSuppliedByPopup()
        }}>
          <div id="supplied-by-footer-parent">
            <img id="supplied-by-img" src={`${getCDN()}web/supplied_by_logos/${supplied_by_img}`}/> 
            <div id="supplied-by-text">Supplier</div>
          </div> 
        </div>
      )
    }

    return supplied_by_content 
  }
}

const mapStateToProps = state => { 
  return { 
    seller: state.seller.seller
  };
};

export default connect(
  mapStateToProps,
  { showSuppliedByPopup }
)(SuppliedByFooter);
