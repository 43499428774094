import React from "react";
import { connect } from "react-redux";
import { fetchSeller, placeOrder, setLoader, setLoaderCheckout, showSuppliedByFooter, hideSuppliedByFooter, hideSuppliedByPopup, hideSMPopup, setAffiliateSellerId } from "../../actions";
import CheckoutCart from "../CheckoutCart"
import CheckoutShipping from "../CheckoutShipping"
import CheckoutCards from "../CheckoutCards"
import history from "../../history";
import requireAuth from "../requireAuth";
import CheckoutGiftCards from "../CheckoutGiftCards";

class CheckoutPage extends React.Component {
  constructor(props) { 
    super(props)

    this.checkout_cards = React.createRef()

    this.state = {
      timeframe: 180,
      interval: null
    }
  }

  componentDidMount() {
    this.props.showSuppliedByFooter()
    if(this.props.autologin_product_id) {
      this.props.fetchSeller(this.props.autologin_product_id, () => {
        this.props.setLoaderCheckout('seller')
        this.props.setAffiliateSellerId(this.props.seller.id)
      })
    }
    
    this.setClockInterval()
  }

  componentWillUnmount = () => {
    this.props.hideSuppliedByFooter()
    this.props.hideSuppliedByPopup()
    this.props.hideSMPopup()
    if(this.state.interval !== null) {
      clearInterval(this.state.interval)
    }
  }

  setClockInterval = () => {
    let interval = setInterval(() => {
      if(this.state.timeframe === 0) {
        clearInterval(interval); 
        this.setState({ interval: null })
        history.push(`/sellerprofile/${this.props.seller.shop_url}`) 
      } else {
        this.setState({ timeframe: --this.state.timeframe })
      }
    }, 1000) 
    this.setState({ interval }) 
  } 

  render = () => {

    // get seller image
    let seller_image = null;
    if(this.props.seller && this.props.cart_products.items.length > 0) {
      
      let seller_image_url = null

      // autlogin product
      if(this.props.autologin_product_id) {
        const temp_product = this.props.cart_products.items.find(v => {
          return v.id === this.props.autologin_product_id
        })
        if(temp_product && temp_product.product_seller_image !== null) {
          seller_image_url = temp_product.product_seller_image
        }
      }

      // first product
      if(seller_image_url === null) {
        const first_product = this.props.cart_products.items[0]
        if(first_product.product_seller_image !== null) {
          seller_image_url = first_product.product_seller_image
        }
      }

      // seller
      if(seller_image_url === null) {
        seller_image_url = this.props.seller.image
      }

      seller_image = <img src={seller_image_url} onClick={() => {
        history.push(`/sellerprofile/${this.props.seller.shop_url}`)
      }}/>      
    }

    let minutes = Math.floor(this.state.timeframe / 60);
    let seconds = this.state.timeframe % 60; 
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    const all_gift_cards = this.props.cart_products.items.every(p => {
      if(p.options) {
        return p.options.some(o => o.attribute_code === 'gift_card_price')
      } else {
        return false
      }
    })

    return (
      <div id="page-checkout">  
        <div id="checkout-top">
          <div id="checkout-header-img">{seller_image}</div>
          <div id="checkout-header-clock">
            <div id="checkout-header-confirm">Confirm in</div> 
            <span id="clock-minutes">{minutes}</span>:
            <span id="clock-seconds">{seconds}</span>
          </div>
        </div>
        <CheckoutCart />
        <div className="clear"></div> 
        {!all_gift_cards && <CheckoutShipping />}
        <CheckoutCards ref={this.checkout_cards} handleCancelTimer={() => {
          clearInterval(this.state.interval)
        }} /> 
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    seller: state.seller.seller,
    payment_card: state.payment_card,
    billing_address: state.billing_address.billing_address,
    cart: state.cart.cart,
    autologin_product_id: state.autologin_product,
    cart_products: state.cart_products.cart_products
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchSeller, placeOrder, setLoader, setLoaderCheckout, showSuppliedByFooter, hideSuppliedByFooter, hideSuppliedByPopup, hideSMPopup, setAffiliateSellerId }
  )(CheckoutPage)
);
