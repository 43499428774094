import React from "react";
import { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails } from "../../actions";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import requireAuth from "../requireAuth"; 
import history from "../../history";
import CONSTANTS from "../../constants"
import GiftCardForm from "../GiftCardForm";

class GiftCardAddPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      product_id: null,
      configurable_product_sku: null,
      cart_id: null,
      cart_item_id: null
    }
  }

  componentDidMount = () => {
    const product_id = this.props.match.params.productid
    const configurable_product_sku = this.props.match.params.configurableproductsku
    const cart_id = this.props.match.params.cartid
    const cart_item_id = this.props.match.params.cartitemid

    this.setState({ product_id: product_id, configurable_product_sku: configurable_product_sku, cart_id: cart_id, cart_item_id: cart_item_id })

    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart()
        this.props.fetchCartProducts() 
      })
    } 
  }

  render() {
    return ( 
      <div id="page-gift-card-recipient-details"> 
        <h1 className="h1" style={{ marginBottom: 19 }}>Gift Card Recipient Details</h1>
        {this.state.product_id && <GiftCardForm  
          product_id={this.state.product_id} 
          configurable_product_sku={this.state.configurable_product_sku}
          handleAction={(data) => {
            let gift_card_details = {
              ...data,
              product_id: this.state.product_id,
              configurable_product_sku: this.state.configurable_product_sku,
              cart_id: this.state.cart_id,
              cart_item_id: this.state.cart_item_id
            }
        
            this.props.saveGiftCardDetails(gift_card_details, (gift_card_details_id) => {
              history.push(`/giftcard/sendnoworschedule/${gift_card_details_id}`)
            })  
          }}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    cart: state.cart.cart,
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchCart, createEmptyCart, fetchCartProducts, fetchProduct, saveGiftCardDetails }
  )(GiftCardAddPage) 
);
