import React from "react";
import { connect } from "react-redux";
import { setLoader, createEmptyCart, fetchCart, fetchOrders } from "../../actions";
import requireAuth from "../requireAuth"; 
import AccountNav from "../AccountNav";
import history from "../../history";

class AccountPage extends React.Component {
  componentDidMount = () => {
    this.props.fetchOrders(() => {
      this.props.setLoader(false, null)
    })
  }

  renderBillingAddress = () => {
    if(this.props.cart) {
      const billing_address = this.props.cart.billing_address
      return (
        <div>
          <div>{billing_address.firstname} {billing_address.lastname}</div>
          <div>tel: {billing_address.telephone}</div>
          <div>{billing_address.street[0]}</div>
          <div>{billing_address.city} {billing_address.region_code} {billing_address.postcode}</div>
        </div>
      )
    } else {
      return null
    }
  }

  renderDefaultShippingAddress = () => {
    if(this.props.shipping_addresses.default_shipping_address) {
      const default_shipping_address = this.props.shipping_addresses.default_shipping_address
      return (
        <div>
          <div>{default_shipping_address.firstname} {default_shipping_address.lastname}</div>
          <div>tel: {default_shipping_address.telephone}</div>
          <div>{default_shipping_address.street[0]}</div>
          <div>{default_shipping_address.city} {default_shipping_address.region.region_code} {default_shipping_address.postcode}</div>
        </div>
      )
    } else {
      return null
    }
  }

  renderShippingAddresses = () => {
    if(this.props.shipping_addresses.addresses.length > 0) {
      return this.props.shipping_addresses.addresses.map(v => {
        return (
          <div key={v.id} className="account-shipping-address">
            <div>{v.firstname} {v.lastname}</div>
            <div>tel: {v.telephone}</div>
            <div>{v.street[0]}</div>
            <div>{v.city} {v.region.region_code} {v.postcode}</div> 
          </div>
        )
      })
    } else {
      return null
    }
  }

  renderOrders = () => {
    return this.props.orders.map(v => {
      let grand_total = parseFloat(v.grand_total)
      grand_total = grand_total.toFixed(2)

      let shipping_address = null
      if(v.shipping_address) {
        shipping_address = (
          <React.Fragment>
            <div>{v.shipping_address.street[0]}</div>
            <div>{v.shipping_address.city} {v.shipping_address.region_code} {v.shipping_address.postcode}</div>
          </React.Fragment>
        )
      }

      return (
        <div className="account-order" key={v.id}>
          <div className="account-order-increment-id">{v.increment_id}</div>
          <div>{v.customer_name}</div>
          {shipping_address}
          <div><span className="bold-description">Total:</span> <span>${grand_total}</span></div>
          <button className="account-order-view-order" onClick={() => {
            history.push(`/account/order/${v.id}`)
          }}>View Order</button>
        </div> 
      )
    })
  }

  render() {
    return (
      <div id="page-account">
        <AccountNav />
        <div id="account-content">
          <h1 className="h1">My Account</h1>   
          <h2 className="h2">Address Information</h2> 
          <h3 className="h3">Billing Address</h3>
          <div>
            {this.renderBillingAddress()}
          </div>
          <h3 className="h3">Default Shipping Address</h3> 
          <div>
            {this.renderDefaultShippingAddress()}  
          </div>
          <h3 className="h3">All Shipping Addresses</h3>
          <div id="account-all-shipping-addresses">
            {this.renderShippingAddresses()}  
          </div>
          <h2 className="h2">Orders</h2>
          <div id="account-orders">
            {this.renderOrders()}
          </div> 
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart,
    shipping_addresses: state.shipping_addresses,
    orders: state.orders.orders
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { setLoader, createEmptyCart, fetchCart, fetchOrders }
  )(AccountPage)
);
