import _ from "lodash";
import {
  FETCH_CART_PRODUCTS,
  LOGOUT,
  ORDER_PLACED,
  SET_EMPTY_CART,
  UPDATE_PRODUCT_QTY
} from "../actions/types";

const INITIAL_STATE = {
  cart_products: {
    id: null,
    items: []
  } 
};

export default (state = INITIAL_STATE, action) => {
  let cart_products, new_state, product_id, new_qty;
  switch (action.type) {
    case FETCH_CART_PRODUCTS: 
      cart_products = {
        ...action.payload.data
      };
      new_state = {
        cart_products: cart_products
      }
      return new_state;
    case UPDATE_PRODUCT_QTY:
      product_id = action.payload.product_id
      new_qty = action.payload.qty

      cart_products = JSON.parse(JSON.stringify(state.cart_products))
      cart_products.items.forEach((v, i, a) => {
        if(v.id == product_id) {
          a[i].qty = new_qty
        }
      })
      localStorage.setItem('cartItems', JSON.stringify(cart_products.items));

      new_state = {
        cart_products: cart_products
      }
      return new_state;
    case SET_EMPTY_CART:
      return INITIAL_STATE;
    case LOGOUT:
    case ORDER_PLACED:
      return INITIAL_STATE  
    default:
      return state;
  }
};
