import _ from "lodash";
import {
  SET_SELECTED_SHIPPING_ADDRESS,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = {
  selected_shipping_address: null
};

export default (state = INITIAL_STATE, action) => {
  let new_state;
  switch (action.type) {
    case SET_SELECTED_SHIPPING_ADDRESS:
      new_state = {
        selected_shipping_address: action.payload
      }
      return new_state;
    case LOGOUT:
      return INITIAL_STATE  
    default:
      return state;
  }
};
