import _ from "lodash";
import {
  FETCH_PASSES
} from "../actions/types";

const INITIAL_STATE = {
  passes: []
};

export default (state = INITIAL_STATE, action) => {
  let passes, new_state;
  switch (action.type) {
    case FETCH_PASSES:
      passes = [
        ...action.payload 
      ]
      new_state = {
        passes: passes
      } 
      return new_state
    default:
      return state;
  }
};
