import React from "react";
import { connect } from "react-redux";
import { setLoader, createEmptyCart, fetchCart } from "../../actions";
import requireAuth from "../requireAuth";
import AccountNav from "../AccountNav";
import history from "../../history";
import DefaultAddressPopup from "../DefaultAddressPopup";

class AccountAddressInformationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_default_address_popup: false,
      default_billing: false,
      default_shipping: false,
    };
  }
  componentDidMount = () => {
    this.props.setLoader(true, "Fetching Cart...");
    this.props.createEmptyCart(() => {
      this.props.setLoader(true, "Fetching Cart...");
      this.props.fetchCart(() => {
        this.props.setLoader(false, null);
      });
    });
  };

  renderBillingAddress = () => {
    // if (this.props.cart) {
    //   const billing_address = this.props.cart.billing_address;
    console.log('this.props.shipping_addresses.default_billing_address', this.props.shipping_addresses.default_billing_address)
    if (this.props.shipping_addresses.default_billing_address) {
      const default_billing_address =
        this.props.shipping_addresses.default_billing_address;
      return (
        <div style={{ position: "relative" }}>
          <div>
            {default_billing_address.firstname}{" "}
            {default_billing_address.lastname}
          </div>
          <div>tel: {default_billing_address.telephone}</div>
          <div>{default_billing_address.street[0]}</div>
          <div>
            {default_billing_address.city} {default_billing_address.region_code}{" "}
            {default_billing_address.postcode}
          </div>
          <button
            type="button"
            style={{
              position: "absolute",
              top: 18,
              right: 0,
              background: "#bfdd00",
              color: "#022a1e",
              borderRadius: 20,
              width: 100,
            }}
            onClick={() => {
              this.setState({
                show_default_address_popup: true,
                default_billing: true,
                default_shipping: false,
              });
            }}
          >
            Edit
          </button>
        </div>
      );
    }
    // } else {
    //   return null;
    // }
  };

  renderDefaultShippingAddress = () => {
    if (this.props.shipping_addresses.default_shipping_address) {
      const default_shipping_address =
        this.props.shipping_addresses.default_shipping_address;
      return (
        <div style={{ position: "relative" }}>
          <div>
            {default_shipping_address.firstname}{" "}
            {default_shipping_address.lastname}
          </div>
          <div>tel: {default_shipping_address.telephone}</div>
          <div>{default_shipping_address.street[0]}</div>
          <div>
            {default_shipping_address.city}{" "}
            {default_shipping_address.region.region_code}{" "}
            {default_shipping_address.postcode}
          </div>
          <button
            type="button"
            style={{
              position: "absolute",
              top: 18,
              right: 0,
              background: "#bfdd00",
              color: "#022a1e",
              borderRadius: 20,
              width: 100,
            }}
            onClick={() => {
              this.setState({
                show_default_address_popup: true,
                default_billing: false,
                default_shipping: true,
              });
            }}
          >
            Edit
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  renderShippingAddresses = () => {
    if (this.props.shipping_addresses.addresses.length > 0) {
      return this.props.shipping_addresses.addresses.map((v) => {
        return (
          <div key={v.id} className="account-shipping-address">
            <div>
              {v.firstname} {v.lastname}
            </div>
            <div>tel: {v.telephone}</div>
            <div>{v.street[0]}</div>
            <div>
              {v.city} {v.region.region_code} {v.postcode}
            </div>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    console.log("props", this.props);
    return (
      <div id="page-account-address-information">
        <AccountNav />
        <div id="account-content">
          <h1 className="h1">Address Information</h1>
          <div id="add-new-address-parent">
            <button
              type="button"
              id="add-new-address-button"
              onClick={() => {
                history.push(`/account/addaddress`);
              }}
            >
              Add New Address
            </button>
          </div>
          <h2 className="h2">Billing Address</h2>
          <div>{this.renderBillingAddress()}</div>
          <h2 className="h2">Default Shipping Address</h2>
          <div>{this.renderDefaultShippingAddress()}</div>
          <h2 className="h2">All Shipping Addresses</h2>
          <div id="account-all-shipping-addresses">
            {this.renderShippingAddresses()}
          </div>
        </div>
        {this.state.show_default_address_popup && (
          <DefaultAddressPopup
            addresses={this.props.shipping_addresses.addresses}
            billing={this.state.default_billing}
            shipping={this.state.default_shipping}
            onClickMask={() => {
              this.setState({ show_default_address_popup: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    shipping_addresses: state.shipping_addresses,
  };
};

export default requireAuth(
  connect(mapStateToProps, { setLoader, createEmptyCart, fetchCart })(
    AccountAddressInformationPage
  )
);
