import React from "react";
import { sendMobileCode, showMobilePopup, setLoader, fetchSeller, resetExpiredLinkDetails, setExpiredLinkDetails } from "../../actions";
import { connect } from "react-redux";
import history from "../../history";
import requireNoAuth from "../requireNoAuth";
import { getReCaptchaToken } from "../../helper/recaptcha";  

class ExpiredLinkPage extends React.Component {
  componentDidMount = () => {
    this.props.setLoader(true, "Fetching Seller...")
    this.props.fetchSeller(localStorage.product_id, () => {
      this.props.setLoader(false, null)
    })

    this.props.setExpiredLinkDetails(localStorage.expired_link_mobile)
  }

  componentWillUnmount = () => {
    this.props.resetExpiredLinkDetails()
  }

  handleSendMobileCode = () => {
    this.props.setLoader(true, "Sending Code...")
    getReCaptchaToken('login', (token) => { 
      const data = {
        mobile: this.props.expired_link.mobile,
        r_token: token
      }
      this.props.sendMobileCode(data, () => {
        this.props.setLoader(false, null)
        this.props.showMobilePopup()  
      })
    })
  }

  render = () => {
    return (
      <div id="page-expiredlink">
        <div id="expired-link-content">
          <img src="https://cdn.tap2buy.com/web/images/red-sad.png"/>
          <h1 id="expired-link-heading">Oops! Expired Link</h1>
          <p id="expired-link-text-1">Your link has expired.</p>
          <p id="expired-link-text-2">Please click the button below to reconfirm your identity or browse our products</p>
          <div className="expired-link-button-parent">
              <button 
                className="expired-link-button"
                id="reconfirm-identity"
                onClick={() => {
                  this.handleSendMobileCode()
                }}
              >Reconfirm Identity</button>
          </div>
          <div className="expired-link-button-parent">
              <button 
                className="expired-link-button"
                id="expired-product-browse"
                onClick={() => {
                  history.push(`/sellerprofile/${this.props.seller.shop_url}`) 
                }}
              >Browse Products</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    seller: state.seller.seller,
    expired_link: state.expired_link
  };
};

export default requireNoAuth(
  connect(
    mapStateToProps,
    { sendMobileCode, showMobilePopup, setLoader, fetchSeller, resetExpiredLinkDetails, setExpiredLinkDetails }
  )(ExpiredLinkPage)
);
