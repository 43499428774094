import React from "react";
import { connect } from "react-redux";
import { hideOfflinePopup } from "../actions";

class OfflineModal extends React.Component {
  render() {
    return ( 
      <div id="offline-mask" onClick={() => {
        this.props.hideOfflinePopup()
      }}>
        <div id="offline-modal">
          <div id="offline-heading">Offline</div>
          <div id="offline-text">You're offline. Click the button below to refresh.</div>
          <button id="offline-button" onClick={() => {
            window.location.reload()
          }}>Refresh</button>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { hideOfflinePopup }
)(OfflineModal);
