import React from "react";
import { connect } from "react-redux";
import { fetchOrder, setLoader } from "../../actions";
import requireAuth from "../requireAuth"; 
import AccountNav from "../AccountNav";

class AccountOrderPage extends React.Component {
  componentDidMount = () => {
    const order_id = this.props.match.params.id
    this.props.setLoader(true, "Fetching Order...")
    this.props.fetchOrder(order_id, () => {  
      this.props.setLoader(false, null)
    })
  }

  renderBillingAddress = (billing_address) => {
    return (
      <React.Fragment>
        <div>{billing_address.firstname} {billing_address.lastname}</div>
        <div>tel: {billing_address.telephone}</div>
        <div>{billing_address.street[0]}</div>
        <div>{billing_address.city} {billing_address.region_code} {billing_address.postcode}</div>
      </React.Fragment> 
    )
  }

  renderShippingAddress = (shipping_address) => {
    return (
      <React.Fragment>
        <div>{shipping_address.firstname} {shipping_address.lastname}</div>
        <div>tel: {shipping_address.telephone}</div>
        <div>{shipping_address.street[0]}</div>
        <div>{shipping_address.city} {shipping_address.region_code} {shipping_address.postcode}</div>
      </React.Fragment>
    )
  }

  renderItems = (items) => {
    return items.map(v => {
      let price = parseFloat(v.price)
      price = price.toFixed(2)

      const qty = parseInt(v.qty)

      let total = parseFloat(v.total)
      total = total.toFixed(2)

      return (
        <React.Fragment> 
          <div className="account-order-item">
            <div><span className="bold-description">{v.name}</span></div> 
            <div><span className="bold-description">Price:</span> ${price}</div>
            <div><span className="bold-description">Qty:</span> {qty}</div>
            <div><span className="bold-description">Total:</span> ${total}</div> 
          </div>
        </React.Fragment>
      )
    })
  }

  renderOrder = () => {
    if(this.props.order) {
      const order = this.props.order

      let grand_total = parseFloat(order.grand_total)
      grand_total = grand_total.toFixed(2)

      let shipping_amount = parseFloat(order.shipping_amount)
      shipping_amount = shipping_amount.toFixed(2)

      return (
        <React.Fragment>
          <h1 className="h1">Order {order.increment_id}</h1>
          <div>{order.customer_name}</div>
          <div><span className="bold-description">Shipping:</span> <span>${shipping_amount}</span></div>
          <div><span className="bold-description">Total:</span> <span>${grand_total}</span></div>
          {order.shipping_address && <div> 
            <h2 className="h2">Shipping Address</h2>    
            <div>
              {this.renderBillingAddress(order.shipping_address)}
            </div>
          </div>}
          {order.billing_address && <div>
            <h2 className="h2">Billing Address</h2>   
            <div>
              {this.renderShippingAddress(order.billing_address)} 
            </div>
          </div>}
          <h2 className="h2">Items</h2> 
          <div id="account-order-items">
            {this.renderItems(order.items)}
          </div>  
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div id="page-account-order">
        <AccountNav />
        <div id="account-content">
          {this.renderOrder()}
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.order.order
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchOrder, setLoader }
  )(AccountOrderPage)
);
