import _ from "lodash";
import {
  SHOW_MESSAGE_POPUP,
  HIDE_MESSAGE_POPUP
} from "../actions/types";

const INITIAL_STATE = {
  show: false,
  header: null,
  message: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MESSAGE_POPUP:
      return {
        show: true,
        header: action.payload.header,
        message: action.payload.message
      };
    case HIDE_MESSAGE_POPUP:
      return INITIAL_STATE;
    default:
      return state;
  }
};

