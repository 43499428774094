export const getReCaptchaToken = (action, callback) => {
    window.grecaptcha.ready(() => {
        window.grecaptcha
            .execute("6LcuVukUAAAAABxviUwFidymawlxzFYmurNIxgPS", { action: action })
            .then(token => {        
            if(callback) {
                callback(token)
            }
        })  
    })
}