import React from "react";
import { connect } from "react-redux";
import { hideMessagePopup } from "../actions";

class MessagePopup extends React.Component {
  render() {
    return ( 
      <div id="unexpected-error-mask" onClick={() => {
        this.props.hideMessagePopup()
      }}>
        <div id="unexpected-error-modal">
          <div id="unexpected-error-heading">{this.props.message_popup.header ? this.props.message_popup.header : 'Something Went Wrong'}</div>
          <div id="unexpected-error-text">{this.props.message_popup.message}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    message_popup: state.message_popup
  };
};


export default connect(
  mapStateToProps,
  { hideMessagePopup }
)(MessagePopup);
