import {
  FETCH_SELLER, FETCH_SELLER_PRODUCTS
} from "../actions/types";

const INITIAL_STATE = {
  seller: null
};

export default (state = INITIAL_STATE, action) => {
  let new_state, seller;
  switch (action.type) {
    case FETCH_SELLER_PRODUCTS:
    case FETCH_SELLER: 
      seller = {
        ...action.payload.seller
      }
      new_state = {
        seller: seller
      }
      return new_state;
    default:
      return state;
  }
};
