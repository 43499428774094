import React from "react";
import { connect } from "react-redux";
import {
  fetchGiftCardDetailsByCart,
  removeGiftCardDetailsByCartItemId,
  removeProductFromCart,
  setLoader,
  setLoaderCheckout,
  fetchCartProducts,
} from "../actions";
import history from "../history";
import { store } from "../store";

class CheckoutGiftCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showGiftCards: false,
    };
  }

  componentDidMount = () => {
    this.props.fetchGiftCardDetailsByCart(
      this.props.cart.id,
      async (gift_card_details) => {
        const promises = [];

        // remove gift card details where a product doesn't exist
        let cart_product_counts = {};
        let gc_product_counts = {};

        this.props.cart_products.items.forEach((v) => {
          if (
            v.options &&
            v.options.some((o) => o.attribute_code === "gift_card_price")
          ) {
            if (typeof cart_product_counts[v.cart_item] === "undefined") {
              cart_product_counts[v.cart_item] = parseInt(v.qty);
            }
          }
        });

        gift_card_details.forEach((v) => {
          if (typeof gc_product_counts[v.cart_item_id] === "undefined") {
            gc_product_counts[v.cart_item_id] = 1;
          } else {
            gc_product_counts[v.cart_item_id]++;
          }
        });

        const gc_keys = Object.keys(gc_product_counts);

        gc_keys.forEach((k) => {
          if (typeof cart_product_counts[k] === "undefined") {
            gift_card_details.forEach((v) => {
              if (v.cart_item_id == k) {
                promises.push(
                  store.dispatch(
                    removeGiftCardDetailsByCartItemId(
                      v.cart_id,
                      v.cart_item_id,
                      null,
                      true
                    )
                  )
                );
              }
            });
          } else if (cart_product_counts[k] < gc_product_counts[k]) {
            let done_deleting = false;

            gift_card_details.forEach((v) => {
              if (!done_deleting && v.cart_item_id == k) {
                promises.push(
                  store.dispatch(
                    removeGiftCardDetailsByCartItemId(
                      v.cart_id,
                      v.cart_item_id,
                      null,
                      true
                    )
                  )
                );

                gc_product_counts[k]--;
                if (cart_product_counts[k] == gc_product_counts[k]) {
                  done_deleting = true;
                }
              }
            });
          }
        });

        // detect if new product and cd doesnt exist
        const cart_keys = Object.keys(cart_product_counts);

        cart_keys.forEach((k) => {
          if (typeof gc_product_counts[k] === "undefined") {
            const remove_products = this.props.cart_products.items.filter(
              (i) => i.cart_item == k
            );
            if (remove_products.length > 0) {
              remove_products.forEach((v) => {
                promises.push(
                  store.dispatch(removeProductFromCart(v.cart_item, null, true))
                );
              });
            }
          } else if (cart_product_counts[k] > gc_product_counts[k]) {
            const remove_products = this.props.cart_products.items.filter(
              (i) => i.cart_item == k
            );
            if (remove_products.length > 0) {
              remove_products.forEach((v) => {
                promises.push(
                  store.dispatch(removeProductFromCart(v.cart_item, null, true))
                );
              });

              // add config product with new qty

              // update cart item in gc order details
            }

            // remove below after change made
            const remove_gc_details = gift_card_details.filter(
              (v) => v.cart_item_id == k
            );
            remove_gc_details.forEach((v) => {
              promises.push(
                store.dispatch(
                  removeGiftCardDetailsByCartItemId(
                    v.cart_id,
                    v.cart_item_id,
                    null,
                    true
                  )
                )
              );
            });
          }
        });

        // detect if halfway through and send to edit
        for (let i = 0; i < gift_card_details.length; i++) {
          const v = gift_card_details[i];

          if (!v.scheduled_delivery || !v.valid_until || !v.mms_template_id) {
            history.push(`/giftcard/edit/${v.id}`);
          }
        }

        if (promises.length > 0) {
          console.log(promises);
          await Promise.all(promises);
          this.props.setLoaderCheckout("gift_cards");
          this.props.setLoader(true, null);
          this.props.fetchCartProducts((num_products) => {
            this.props.setLoader(false, null);
            if (num_products === 0) {
              history.push("/browse");
            }
          });
        } else {
          this.props.setLoaderCheckout("gift_cards");
        }
      }
    );
  };

  renderGiftCards = () => {
    if (this.props.gift_card_details.length > 0) {
      return this.props.gift_card_details.map((v) => {
        const product_exists = this.props.cart_products.items.some(
          (p) => p.id == v.product_id
        );
        const product = this.props.cart_products.items.find(
          (p) => p.id == v.product_id
        );
        const option = product.options.find(
          (o) => o.attribute_code === "gift_card_price"
        );

        if (product_exists) {
          let delivery = "NOT SET";
          let valid_until = "NOT SET";
          if (v.scheduled_delivery && v.valid_until) {
            delivery = new Date(
              v.scheduled_delivery.replace(" ", "T") + "Z"
            ).toLocaleString();
            valid_until = new Date(
              v.valid_until.replace(" ", "T") + "Z"
            ).toLocaleString();
          } else {
            history.push(`/giftcard/sendnoworschedule/${v.id}`);
          }

          return (
            <div className="product-gift-card-details-container">
              <div className="gift-card-details-product-info">
                <div className="gift-card-details-row">
                  <div className="gift-card-details-label">To:</div>
                  <div className="gift-card-details-info">
                    {v.recipient_firstname} {v.recipient_lastname}
                  </div>
                </div>
                <div className="gift-card-details-row">
                  <div className="gift-card-details-label">Mobile:</div>
                  <div className="gift-card-details-info">
                    {v.recipient_mobile}
                  </div>
                </div>
                <div className="gift-card-details-row">
                  <div className="gift-card-details-label">Image:</div>
                  <div className="gift-card-details-info">
                    <img
                      src={v.mms_template_url}
                      style={{
                        width: "150px",
                        border: "1px solid #c2dedb",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                </div>
                <div className="gift-card-details-row">
                  <div className="gift-card-details-label">Message:</div>
                  <div className="gift-card-details-info">
                    {v.recipient_message}
                  </div>
                </div>
                <div className="gift-card-details-row">
                  <div className="gift-card-details-label">Send Time:</div>
                  <div className="gift-card-details-info">{delivery}</div>
                </div>
                <div style={{
                  float: 'left',
                  position: 'absolute',
                  top: '5px',
                  right: '9px'
                }}>
                  <button
                    style={{
                      background: "#bfdd00",
                      color: "#022a1e",
                      fontSize: "16px",
                      textAlign: "center",
                      padding: "10px 30px",
                      borderRadius: "25px",
                      marginTop: "5px",
                      fontWeight: "400",
                    }}
                    onClick={() => history.push(`/giftcard/edit/${v.id}`)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      });
    }
  };

  render() {
    const has_gift_card = this.props.cart_products.items.some((p) => {
      if (p.options) {
        return p.options.some((o) => o.attribute_code === "gift_card_price");
      } else {
        return false;
      }
    });

    return has_gift_card ? (
      <div id="checkout-gift-cards">
        <div id="checkout-gift-cards-heading">
          {this.state.showGiftCards ? "Hide" : "Show"} Gift Cards
        </div>
        <div
          className="checkout-arrow"
          onClick={() => {
            this.setState({ showGiftCards: !this.state.showGiftCards });
          }}
        ></div>
        {this.state.showGiftCards && (
          <div style={{ marginTop: "10px" }}>{this.renderGiftCards()}</div>
        )}
      </div>
    ) : null;
  }
}

const mapStateToProps = (state) => {
  return {
    cart_products: state.cart_products.cart_products,
    gift_card_details: state.gift_card_details.gift_card_details,
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps, {
  fetchGiftCardDetailsByCart,
  removeGiftCardDetailsByCartItemId,
  removeProductFromCart,
  setLoader,
  setLoaderCheckout,
  fetchCartProducts,
})(CheckoutGiftCards);
