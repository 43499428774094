import React from "react";
import { connect } from "react-redux";
import { setLoader, fetchOrders } from "../../actions";
import requireAuth from "../requireAuth"; 
import AccountNav from "../AccountNav";
import history from "../../history";

class AccountOrdersPage extends React.Component {
  componentDidMount = () => {
    this.props.setLoader(true, "Fetching Cart...")
    this.props.fetchOrders(() => {
      this.props.setLoader(false, null)
    }) 
  }

  renderOrders = () => {
    return this.props.orders.map(v => {
      let grand_total = parseFloat(v.grand_total)
      grand_total = grand_total.toFixed(2)

      let shipping_address = null
      if(v.shipping_address) {
        shipping_address = (
          <React.Fragment>
            <div>{v.shipping_address.street[0]}</div>
            <div>{v.shipping_address.city} {v.shipping_address.region_code} {v.shipping_address.postcode}</div>
          </React.Fragment>
        )
      }

      return (
        <div className="account-order" key={v.id}>
          <div className="account-order-increment-id">{v.increment_id}</div>
          <div>{v.customer_name}</div>
          {shipping_address} 
          <div><span className="bold-description">Total:</span> <span>${grand_total}</span></div>
          <button className="account-order-view-order" onClick={() => {
            history.push(`/account/order/${v.id}`)
          }}>View Order</button>
        </div> 
      )
    })
  }

  render() {
    return (
      <div id="page-account-orders">
        <AccountNav />
        <div id="account-content">
          <h1 className="h1">Orders</h1>
          <div id="account-orders">
            {this.renderOrders()}
          </div>    
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.orders
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { setLoader, fetchOrders }
  )(AccountOrdersPage)
);
