import _ from "lodash";
import {
  FETCH_CATEGORY_PRODUCTS_MAGENTO,
  FETCH_BROWSE_PRODUCTS_MAGENTO,
  FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION,
  FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION
} from "../actions/types";

const INITIAL_STATE = {
  products_magento: []
};

export default (state = INITIAL_STATE, action) => {
  let products_magento, new_state;
  switch (action.type) {
    case FETCH_CATEGORY_PRODUCTS_MAGENTO:
    case FETCH_BROWSE_PRODUCTS_MAGENTO:
      products_magento = [
        ...action.payload.items
      ]
      new_state = {
        products_magento: products_magento 
      }
      return new_state
    case FETCH_CATEGORY_PRODUCTS_MAGENTO_PAGINATION:
    case FETCH_BROWSE_PRODUCTS_MAGENTO_PAGINATION:
      products_magento = [
        ...state.products_magento,
        ...action.payload.items
      ]
      new_state = {
        products_magento: products_magento 
      }
      return new_state
    default:
      return state;
  }
};