import React from "react";
import { connect } from "react-redux";
import history from "../history";
import { fetchCategories, hideNavMenu, showMobilePopup, setLoader, logout, triggeredMobilePopup } from "../actions";
class NavMenu extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      showMenu: true,
      showAccount: false,
      showCategoryLevel1: true, 
      showCategoryLevel2: false,
      showCategoryLevel3: false,
      level2_index: null,
      level2_heading: null,
      level2_entity_id: null,
      level3_index: null,
      level3_heading: null,
      level3_entity_id: null
    }
  }

  componentDidMount = () => {
    this.props.fetchCategories()
  }

  renderCategoriesLevel1 = () => {
    return this.props.categories.map((v, i, a) => {
      if(v.children.length > 0) {
        return (
          <a key={v.entity_id}>
            <div className="new-sm-nav-item">
              <div className="new-sm-nav-item-text" onClick={() => {
                console.log('text', `/category/${v.entity_id}`)
                history.push(`/category/${v.entity_id}`)
                this.props.hideNavMenu()
              }}>{v.value}</div>
              <div className="new-sm-nav-item-caret" onClick={() => {
                console.log('caret', i)
                this.setState({ 
                  level2_index: i,
                  level2_heading: v.value,
                  level2_entity_id: v.entity_id,
                  showCategoryLevel1: false,
                  showCategoryLevel2: true
                })
              }}><i className="fa fa-angle-right"></i></div>
            </div>
          </a>
        )
      } else {
        return (
          <a key={v.entity_id} onClick={() => {
            history.push(`/category/${v.entity_id}`)
            this.props.hideNavMenu()
          }}>
            <div className="new-sm-nav-item">{v.value}</div>
          </a>
        )
      }
      
    })
  }

  renderCategoriesLevel2 = () => {
    const level2_categories = this.props.categories[ 
      this.state.level2_index
    ].children.map((v, i, a) => {
      if(v.children.length > 0) {
        return (
          <a key={v.entity_id}>
            <div className="new-sm-nav-item">
              <div className="new-sm-nav-item-text" onClick={() => {
                history.push(`/category/${v.entity_id}`)
                this.props.hideNavMenu()
              }}>{v.value}</div>
              <div className="new-sm-nav-item-caret" onClick={() => {
                this.setState({ 
                  level3_index: i,
                  level3_heading: v.value,
                  level3_entity_id: v.entity_id,
                  showCategoryLevel2: false,
                  showCategoryLevel3: true
                })
              }}><i class="fa fa-angle-right"></i></div>
            </div>
          </a>
        )
      } else {
        return (
          <a key={v.entity_id} onClick={() => {
            history.push(`/category/${v.entity_id}`)
            this.props.hideNavMenu()
          }}>
            <div className="new-sm-nav-item">{v.value}</div>
          </a>
        )
      }
    })

    return (
      <React.Fragment>
        <a 
          className="new-sm-nav-item-caret-back"
          onClick={() => {
            this.setState({ 
              showCategoryLevel1: true,
              showCategoryLevel2: false
            })
          }}
        ></a>
        <div 
          className="categories-header"
          onClick={() => {
            history.push(`/category/${this.state.level2_entity_id}`)
            this.props.hideNavMenu()
          }}
        >{this.state.level2_heading}</div>
        {level2_categories}
      </React.Fragment>
    )
  }

  renderCategoriesLevel3 = () => {
    const level3_categories = this.props.categories[
      this.state.level2_index
    ].children[
      this.state.level3_index
    ].children.map(v => {
      return (
        <a key={v.entity_id} onClick={() => {
          history.push(`/category/${v.entity_id}`)
          this.props.hideNavMenu()
        }}>
          <div className="new-sm-nav-item">{v.value}</div>
        </a>
      )
    })

    return (
      <React.Fragment>
        <a 
          className="new-sm-nav-item-caret-back"
          onClick={() => {
          this.setState({ 
            showCategoryLevel2: true,
            showCategoryLevel3: false
          })
        }}></a>
        <div 
          className="categories-header"
          onClick={() => {
            history.push(`/category/${this.state.level3_entity_id}`) 
            this.props.hideNavMenu()
          }}
        >{this.state.level3_heading}</div>
        {level3_categories}
      </React.Fragment>
    )
  }

  render() {
    let show_nav_menu_css = {}
    if(this.props.show_nav) {
      show_nav_menu_css = { left: '50%' }
    }

    let menu_active_css = ""
    if(this.state.showMenu) {
      menu_active_css = "sm-nav-active"
    }

    let account_active_css = ""
    if(this.state.showAccount) {
      account_active_css = "sm-nav-active" 
    }
    return (
      <div id="new-sm-menu" style={show_nav_menu_css} >
        <a 
          id="new-sm-menu-close"
          onClick={() => {
            this.props.hideNavMenu()
          }}
        >x</a>
        {this.state.showCategoryLevel1 && <div id="new-sm-menu-nav-parent">
          <a 
            id="new-sm-menu-nav-menu" 
            className={`menu-nav ${menu_active_css}`}
            onClick={() => {
              this.setState({ showMenu: true, showAccount: false })
            }}
          >Menu</a>
          <a 
            id="new-sm-menu-nav-account" 
            className={`menu-nav ${account_active_css}`}
            onClick={() => {
              this.setState({ showMenu: false, showAccount: true })
            }}
          >Account</a>
        </div>}
        {this.state.showMenu && <div id="menu-menu-nav">
          {this.state.showCategoryLevel1 && <div id="categories-level-1">
            <div id="new-sm-menu-buffer"></div>
            <a onClick={() => {
              history.push('/browse')
              this.props.hideNavMenu()
            }}>
              <div className="new-sm-nav-item">Browse</div>
            </a>
            {this.renderCategoriesLevel1()}
          </div>}
          {this.state.showCategoryLevel2 && <div id="categories-level-2">
            {this.renderCategoriesLevel2()}
          </div>}
          {this.state.showCategoryLevel3 && <div id="categories-level-3">
            {this.renderCategoriesLevel3()}
          </div>}
        </div>}
        {this.state.showAccount && <div id="account-nav">
          <div id="new-sm-menu-buffer"></div>
          {!this.props.auth.is_signed_in && <a onClick={() => {
            this.props.triggeredMobilePopup({
              pathname: null
            })
            this.props.showMobilePopup()
          }}>
            <div 
              id="account-nav-signin" 
              className="new-sm-nav-item"
            >Sign In</div>
          </a>}
          {this.props.auth.is_signed_in && <React.Fragment>
            <a onClick={() => {
              history.push('/account')
              this.props.hideNavMenu()
            }}>
              <div 
                id="account-nav-my-account" 
                className="new-sm-nav-item"
              >My Account</div>
            </a>
            <a onClick={() => {
              console.log('logout')
              this.props.logout(() => {
                history.push('/browse')
              })
              this.props.hideNavMenu()
            }}>
              <div 
                id="account-nav-signout" 
                className="new-sm-nav-item"
              >Sign Out</div>
            </a>
          </React.Fragment>}
        </div>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
    categories: state.categories.categories,
    show_nav: state.show_nav,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { fetchCategories, hideNavMenu, showMobilePopup, setLoader, logout, triggeredMobilePopup }
)(NavMenu);
