import _ from "lodash";
import {
  SET_EXPIRED_LINK_DETAILS,
  RESET_EXPIRED_LINK_DETAILS
} from "../actions/types";

const INITIAL_STATE = {
  status: false,
  mobile: null
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_EXPIRED_LINK_DETAILS:
      return {
        status: true,
        mobile: action.payload
      };
    case RESET_EXPIRED_LINK_DETAILS:
      return INITIAL_STATE
    default:
      return state;
  }
};
