import React from "react";
import { connect } from "react-redux";
import { setLoader, createEmptyCart, fetchCart } from "../../actions";
import requireAuth from "../requireAuth"; 
import AccountNav from "../AccountNav";

class AccountPersonalDetailsPage extends React.Component {
  componentDidMount = () => {
    this.props.setLoader(true, "Fetching Cart...")
    this.props.createEmptyCart(() => {
      this.props.setLoader(true, "Fetching Cart...") 
      this.props.fetchCart(() => {  
        this.props.setLoader(false, null)
      })
    })
  }

  renderPersonalDetails = () => {
    if(this.props.cart) {
      const customer = this.props.cart.customer
      return (
        <React.Fragment>
          <h2 className="h2">Name</h2>
          <div>{customer.firstname} {customer.lastname}</div>
          <h2 className="h2">Email</h2>
          <div>{customer.email}</div>
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  render() {
    return (
      <div id="page-account-personal-details">
        <AccountNav />
        <div id="account-content">
          <h1 className="h1">Personal Details</h1>  
          <div id="account-personal-details">
            {this.renderPersonalDetails()} 
          </div>
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.cart
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { setLoader, createEmptyCart, fetchCart }
  )(AccountPersonalDetailsPage)
);
