import _ from "lodash";
import {
  FETCH_SHIPPING_INFORMATION,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = {
  shipping_information: []
};

export default (state = INITIAL_STATE, action) => {
  let shipping_information, new_state;
  switch (action.type) {
    case FETCH_SHIPPING_INFORMATION:
      shipping_information = [
        ...action.payload.shipping_information
      ];
      new_state = {
        shipping_information: shipping_information
      }
      return new_state;
    case LOGOUT: 
      return INITIAL_STATE 
    default:
      return state;
  }
};   