import _ from "lodash";
import {
  FETCH_SHIPPING_METHOD,
  FETCH_SHIPPING_METHOD_CUSTOM,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = {
  shipping_method: null
};

export default (state = INITIAL_STATE, action) => {
  let shipping_method, new_state;
  switch (action.type) {
    case FETCH_SHIPPING_METHOD:
      let index = -1;
      let min = null

      action.payload.forEach((v, i, a) => {
        if(min === null) {
          min = v.amount
          index = i
        }
        if(v.amount < min) {
          min = v.amount
          index = i
        }
      })

      if(index === -1) {
        shipping_method = null;
      } else {
        shipping_method = {
          ...action.payload[index] 
        };
      }
      
      new_state = {
        shipping_method: shipping_method   
      }
      return new_state;
    case FETCH_SHIPPING_METHOD_CUSTOM:
      let method_code = null, amount = 0

      action.payload.data.forEach((v, i, a) => {
        method_code = v.shipping_method_id
        amount = parseFloat(v.price) 
      })

      shipping_method = {
        carrier_code: "webkulshipping",
        method_code: method_code,
        amount: amount 
      }

      new_state = {
        shipping_method: shipping_method   
      }
      return new_state;
    case LOGOUT: 
      return INITIAL_STATE  
    default:
      return state;
  }
};
