import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import history from "../history";
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router-dom';

import Header from "./Header";
import Footer from "./Footer";
import NavMenu from "./NavMenu";
import Cart from "./Cart";
import OfflineModal from "./OfflineModal";
import SMPopup from "./SMPopup"
import ScrollToTop from "./ScrollToTop"
import MobileCodePopup from "./MobileCodePopup"
import SuppliedByFooter from "./SuppliedByFooter"
import SuppliedByPopup from "./SuppliedByPopup"
import MessagePopup from "./MessagePopup"

import AutologinPage from "./pages/AutologinPage";
import AccountPage from "./pages/AccountPage";
import BrowsePage from "./pages/BrowsePage";
import CategoryPage from "./pages/CategoryPage";
import CheckoutPage from "./pages/CheckoutPage";
import ExpiredLinkPage from "./pages/ExpiredLinkPage";
import HomePage from "./pages/HomePage";
import PrivacyPage from "./pages/PrivacyPage";
import ProductPage from "./pages/ProductPage";
import RegistrationPage from "./pages/RegistrationPage";
import SellerProfilePage from "./pages/SellerProfilePage";
import SuccessPage from "./pages/SuccessPage";
import TermsPage from "./pages/TermsPage";
import UpdateProductPage from "./pages/UpdateProductPage";
import NotFoundPage from "./pages/NotFoundPage";
import UnexpectedErrorModal from "./UnexpectedErrorModal";
import AccountAddressInformationPage from "./pages/AccountAddressInformationPage";
import AccountPersonalDetailsPage from "./pages/AccountPersonalDetailsPage";
import AccountSavedCardsPage from "./pages/AccountSavedCardsPage";
import AccountOrdersPage from "./pages/AccountOrdersPage";
import AccountOrderPage from "./pages/AccountOrderPage";
import ProductOutOfStockPopup from "./ProductOutOfStockPopup";
import FooterTermsPrivacy from "./FooterTermsPrivacy";
import GiftCardAddPage from "./pages/GiftCardAddPage";
import GiftCardSendNowOrSchedulePage from "./pages/GiftCardSendNowOrSchedulePage";
import GiftCardSchedulePage from "./pages/GiftCardSchedulePage";
import GiftCardEditPage from "./pages/GiftCardEditPage";
import GiftCardMMSTemplatesPage from "./pages/GiftCardMMSTemplatesPage";
import AccountAddAddressPage from "./pages/AccountAddAddressPage"

const App = props => {
  let nav_menu_mask = <div id="new-sm-menu-mask"></div>
  if(props.show_nav || props.show_sm_popup || props.show_supplied_by_popup) {
    nav_menu_mask = <div id="new-sm-menu-mask" className="show-mask"></div>
  }

  let cart = null
  if(props.show_cart) {
    cart = <Cart />
  }

  let mobile_code_popup = null
  if(props.show_mobile_popup) {
    mobile_code_popup = <MobileCodePopup /> 
  }

  let offline_modal = null
  if(props.offline) {
    offline_modal = <OfflineModal />
  }

  let unexpected_error_modal = null
  if(props.unexpected_error) {
    unexpected_error_modal = <UnexpectedErrorModal />
  }

  let supplied_by_footer = null
  if(props.show_supplied_by_footer) {
    supplied_by_footer = <SuppliedByFooter />
  }

  let message_popup = null
  if(props.message_popup.show) {
    message_popup = <MessagePopup />
  }

  let loader = null
  if(props.loader.show) {
    loader = (
      <div id="loader-parent">
        <div id="loader">
          <img src="/tap2buy-loader.gif"/> 
          {/* <div>{props.loader.message}</div> */}
        </div>
      </div> 
    )
  }

  let product_out_of_stock_popup = null
  if(props.product_out_of_stock_popup) {
    product_out_of_stock_popup = <ProductOutOfStockPopup /> 
  }

  let container_styles = {}
  if(props.is_home_page) {
    container_styles = {
      width: 'unset',
      maxWidth: 'unset',
      paddingLeft: '0',
      paddingRight: '0'
    }
  }

  return (
    <div>
      <MetaTags> 
        {/* <meta property="og:title" content="SecureMobile"/> */}
        {/* <meta property="og:description" content="Customers offered a genuine one click mobile checkout, removing friction in mobile transaction"/> */}
        {/* <meta property="og:image" content="https://cdn-staging.securemobile.net/web/images/SecureMobile-footer-registration.png"/>  */}
        {/* <meta property="og:url" content="https://securemobile.net"/> */}
      </MetaTags>
      <Router history={history}>
        <div>
          <ScrollToTop />  
          <Header />
          <div className="container" style={container_styles}>
            <Switch>
              <Route path="/" exact component={HomePage} /> 
              <Route path="/autologin/:jwt" exact component={AutologinPage} />
              <Route path="/account" exact component={AccountPage} />
              <Route path="/account/addressinformation" exact component={AccountAddressInformationPage} />
              <Route path="/account/addaddress" exact component={AccountAddAddressPage} />
              <Route path="/account/personaldetails" exact component={AccountPersonalDetailsPage} />
              <Route path="/account/savedcards" exact component={AccountSavedCardsPage} />
              <Route path="/account/orders" exact component={AccountOrdersPage} />
              <Route path="/account/order/:id" exact component={AccountOrderPage} />
              <Route path="/account" exact component={AccountPage} />
              <Route path="/browse" exact component={BrowsePage} />
              <Route path="/category/:id" exact component={CategoryPage} />
              <Route path="/checkout" exact component={CheckoutPage} />
              <Route path="/expiredlink" exact component={ExpiredLinkPage} />
              <Route path="/privacy" exact component={PrivacyPage} />
              <Route path="/product/:id" exact component={ProductPage} />
              <Route path="/register" exact component={RegistrationPage} />
              <Route path="/sellerprofile/:sellerurl" exact component={SellerProfilePage} />
              <Route path="/success" exact component={SuccessPage} />
              <Route path="/terms" exact component={TermsPage} />
              <Route path="/updateproduct/:id" exact component={UpdateProductPage} />
              <Route path="/giftcard/add/:productid/:configurableproductsku/:cartid/:cartitemid" exact component={GiftCardAddPage} /> 
              <Route path="/giftcard/edit/:giftcarddetailsid" exact component={GiftCardEditPage} /> 
              <Route path="/giftcard/sendnoworschedule/:giftcarddetailsid" exact component={GiftCardSendNowOrSchedulePage} /> 
              <Route path="/giftcard/schedule/:giftcarddetailsid" exact component={GiftCardSchedulePage} /> 
              <Route path="/giftcard/mmstemplates/:giftcarddetailsid" exact component={GiftCardMMSTemplatesPage} /> 
              <Route path="/404" component={NotFoundPage} />
              <Redirect from="*" to="/404" />
            </Switch>
            <FooterTermsPrivacy />
          </div>
          {/* <Footer /> */}
          {supplied_by_footer}
          <NavMenu />
          <SMPopup />
          <SuppliedByPopup />
          {nav_menu_mask}
          {cart}
          {loader}
          {offline_modal}
          {mobile_code_popup} 
          {unexpected_error_modal}
          {product_out_of_stock_popup}
          {message_popup}
        </div>
      </Router>
    </div>
  );
};

const mapStateToProps = state => {
  return { 
    show_nav: state.show_nav,
    show_cart: state.show_cart,
    loader: state.loader,
    show_sm_popup: state.show_sm_popup,
    offline: state.offline,
    show_mobile_popup: state.show_mobile_popup,
    unexpected_error: state.unexpected_error,
    product_out_of_stock_popup: state.product_out_of_stock_popup,
    show_supplied_by_footer: state.show_supplied_by_footer,
    show_supplied_by_popup: state.show_supplied_by_popup,
    is_home_page: state.is_home_page,
    message_popup: state.message_popup
  };
};

export default connect(
  mapStateToProps,
  null
)(App); 
