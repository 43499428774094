import _ from "lodash";
import {
  SAVE_GIFT_CARD_DETAILS,
  SAVE_GIFT_CARD_SEND_TIME,
  CLEAR_GIFT_CARD_DETAILS,
  FETCH_GIFT_CARD_DETAILS,
  FETCH_GIFT_CARD_DETAILS_BY_ID,
  REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID,
  SAVE_GIFT_CARD_MMS_TEMPLATE,
  SAVE_GIFT_CARD_PASS
} from "../actions/types";

const INITIAL_STATE = {
  gift_card_details: [],
};

export default (state = INITIAL_STATE, action) => {
  let new_state, gift_card_details, temp;
  switch (action.type) {
    case FETCH_GIFT_CARD_DETAILS:
      gift_card_details = [...action.payload];

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case FETCH_GIFT_CARD_DETAILS_BY_ID:
      temp = [...state.gift_card_details];

      let index = null;
      temp.forEach((v, i) => {
        if (v.id == action.payload.id) {
          index = i;
        }
      });

      if (index !== null) {
        temp.splice(index, 1);
      }

      gift_card_details = [...temp, action.payload];

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case SAVE_GIFT_CARD_DETAILS:
      gift_card_details = [...state.gift_card_details, action.payload];

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case SAVE_GIFT_CARD_SEND_TIME:
      gift_card_details = [...state.gift_card_details];

      gift_card_details.forEach((v, i, a) => {
        if (v.id === action.payload.id) {
          a[i].scheduled_delivery = action.payload.date_str;
        }
      });

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case SAVE_GIFT_CARD_MMS_TEMPLATE:
      gift_card_details = [...state.gift_card_details];

      gift_card_details.forEach((v, i, a) => {
        if (v.id === action.payload.id) {
          a[i].mms_template_id = action.payload.mms_template_id;
        }
      });

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case SAVE_GIFT_CARD_PASS:
      gift_card_details = [...state.gift_card_details];

      gift_card_details.forEach((v, i, a) => {
        if (v.id === action.payload.id) {
          a[i].pass_id = action.payload.pass_id;
        }
      });

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case REMOVE_GIFT_CARD_DETAILS_BY_CART_ITEM_ID:
      gift_card_details = [...state.gift_card_details];

      gift_card_details = gift_card_details.filter((v) => {
        return (
          v.cart_id != action.payload.cart_id &&
          v.cart_item_id != action.payload.cart_item_id
        );
      });

      new_state = {
        gift_card_details: gift_card_details,
      };
      return new_state;
    case CLEAR_GIFT_CARD_DETAILS:
      return {
        gift_card_details: [],
      };
    default:
      return state;
  }
};
