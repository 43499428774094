import _ from "lodash";
import {
  FETCH_SAVED_CARDS,
  LOGOUT,
  ORDER_PLACED
} from "../actions/types";

const INITIAL_STATE = {
  saved_cards: []
};

export default (state = INITIAL_STATE, action) => {
  let saved_cards, new_state;
  switch (action.type) {
    case FETCH_SAVED_CARDS: 
      saved_cards = [
        ...action.payload.data 
      ];
      new_state = {
        saved_cards: saved_cards
      }
      return new_state;
    case LOGOUT: 
    case ORDER_PLACED:
      return INITIAL_STATE 
    default:
      return state;
  }
};
