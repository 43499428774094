import _ from "lodash";
import {
  SET_CHECKOUT_SUCCESS,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = {
  checkout_success: null
}; 

export default (state = INITIAL_STATE, action) => {
  let checkout_success, new_state;
  switch (action.type) {
    case SET_CHECKOUT_SUCCESS:
      checkout_success = {
        ...action.payload
      };
      new_state = {
        checkout_success: checkout_success
      }
      return new_state;
    case LOGOUT: 
      return INITIAL_STATE  
    default:
      return state;
  }
};
