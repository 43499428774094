import React from "react";
import { fetchBrowseProducts, fetchCart, setLoader, createEmptyCart, fetchBrowseProductsMagento, clearProducts } from "../../actions";
import { connect } from "react-redux";
import ProductItem from "../ProductItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import Columns from 'react-columns';
import { isTap2Buy, isGiftGuru } from "../../helper/common"

class BrowsePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      hasMore: true
    }
  }

  componentDidMount = () => {
    // this.props.setLoader(true, "Fetching Products...")
    // this.props.fetchBrowseProducts(1, () => {
    //   this.props.setLoader(false, null) 
    // }) 
    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart()
    }
    
    this.onFetchProducts()
  }

  componentWillUnmount = () => {
    this.props.clearProducts()
  }

  onFetchProducts = () => {
    this.props.setLoader(true, "Fetching Products...")
    this.props.fetchBrowseProducts(this.state.page, this.props.products, left => {
      console.log('left', left)
      if(left < 10) {
        this.setState({ hasMore: false })
      } 
      this.props.setLoader(false, null)  
    });
  }

  fetchData = () => {
    this.setState({ page: this.state.page+1 })
    this.onFetchProducts()
  }

  renderProducts = () => {
    return this.props.products.map(v => {
      return <ProductItem key={v.id} product={v} />
    })
  }

  render() {
    return ( 
      <div id="page-browse">
        <h1 className="h1">{isTap2Buy() ? 'Browse Products' : isGiftGuru() ? 'Browse Gift Cards' : 'Browse Products' }</h1>
        <div className="explanatory-text">Feel free to browse {isTap2Buy() ? 'products' : isGiftGuru() ? 'gift cards' : 'products' }</div>
        <div className="product-tile-parent">
          <InfiniteScroll
            dataLength={this.props.products.length}
            next={this.fetchData}
            hasMore={this.state.hasMore}
            loader={null}
            endMessage={
              <p style={{
                fontWeight: '400',
                textAlign: 'center',
                fontSize: '18px',
                clear: 'both'
              }} className="up-to-date">No More Products</p> 
            }
          >
            <Columns columns="1">
              {this.renderProducts()}
            </Columns>
          </InfiniteScroll>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.products,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { fetchBrowseProducts, fetchCart, setLoader, createEmptyCart, fetchBrowseProductsMagento, clearProducts }
)(BrowsePage);
