import React from "react";
import { connect } from "react-redux";
import { hideProductOutOfStockPopup } from "../actions";

class ProductOutOfStockPopup extends React.Component {
  render() {
    return ( 
      <div id="product-out-of-stock-mask">
        <div id="product-out-of-stock-popup">
          <div 
            id="product-out-of-stock-x"
            onClick={() => {
              this.props.hideProductOutOfStockPopup()
            }}>x</div>
          <div id="product-out-of-stock-heading">Out of Stock</div>
          <div id="product-out-of-stock-text">Product is out of stock.</div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { hideProductOutOfStockPopup }
)(ProductOutOfStockPopup);
