import React from "react";
import { connect } from "react-redux";
import { hideSMPopup } from "../actions";

class SMPopup extends React.Component {
  render() {
    let show_sm_popup_class = ""
    if(this.props.show_sm_popup) {
      show_sm_popup_class = "show-popup"
    }

    return (
      <div id="md-popup" className={`${show_sm_popup_class}`}>
          <div 
            id="md-popup-x" 
            onClick={() => {
              this.props.hideSMPopup()
            }}
          >x</div>
          <div id="md-popup-content">
              <div id="md-popup-image-parent"><img src="https://cdn-staging.securemobile.net/web/images/SecureMobile-Logo.png"/></div>
              <p>Simple secure and worry-free, 2FA inherent only access to your account.</p>
              <p>Innovative accelerated checkout locked to your mobile phone, never type anything twice.</p>
              <p>With SecureMobile your information is safe and you can delete your account at any time, once your account is registered never have to log in again.</p>
              <p>Get access to excellent offers and products securely and direct to your phone. SecureMobile servers meets strict PCI compliance standards for vaulting credit card and personal info.</p>
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
    show_sm_popup: state.show_sm_popup
  };
};

export default connect(
  mapStateToProps,
  { hideSMPopup }
)(SMPopup);
