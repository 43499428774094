import _ from "lodash";
import {
  FETCH_MMS_TEMPLATES
} from "../actions/types";

const INITIAL_STATE = {
  mms_templates: []
};

export default (state = INITIAL_STATE, action) => {
  let mms_templates, new_state;
  switch (action.type) {
    case FETCH_MMS_TEMPLATES:
      mms_templates = [
        ...action.payload 
      ]
      new_state = {
        mms_templates: mms_templates
      } 
      return new_state
    default:
      return state;
  }
};
