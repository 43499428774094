import _ from "lodash";
import {
  FETCH_CATEGORIES
} from "../actions/types";

const INITIAL_STATE = {
  categories: []
};

export default (state = INITIAL_STATE, action) => {
  let categories, new_state;
  switch (action.type) {
    case FETCH_CATEGORIES:
      categories = [
        ...action.payload.data
      ];
      new_state = {
        categories: categories
      }
      return new_state;
    default:
      return state;
  }
};
