import {
  SET_AUTHORIZATION_TOKEN,
  UNSET_AUTHORIZATION_TOKEN
} from "../actions/types";
import { getWithExpiry } from "../helper/common";

const token = getWithExpiry("token_ttl");

const INITIAL_STATE = {
  is_signed_in: token ? true : false,
  auth_expired: false,
};

const setWithExpiry = (key, value, ttl) => {
  const now = new Date();

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTHORIZATION_TOKEN:
      localStorage.is_signed_in = true;
      localStorage.token = action.payload.token;
      const ttl = 55 * 60 * 1000; // 55 minutes in milliseconds
      setWithExpiry("token_ttl", action.payload.token, ttl);
      return { ...state, is_signed_in: true }; 
    case UNSET_AUTHORIZATION_TOKEN:
      delete localStorage.is_signed_in;
      delete localStorage.token;
      delete localStorage.token_ttl;
      delete localStorage.cartItems;
      delete localStorage.customer;
      return { ...state, is_signed_in: false };
    default:
      return state; 
  } 
  
};
