import React from "react";
import { fetchCategoryProducts, fetchCart, setLoader, createEmptyCart, fetchCategoryProductsMagento, clearProducts } from "../../actions";
import { connect } from "react-redux";
import ProductItem from "../ProductItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import Columns from 'react-columns';
import { isTap2Buy, isGiftGuru } from "../../helper/common"

class CategoryPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      category_id: null,
      page: 1,
      hasMore: true
    }
  }

  componentDidMount = () => {
    const category_id = this.props.match.params.id
    // this.props.setLoader(true, "Fetching Products...")
    // this.props.fetchCategoryProducts(category_id, 1, () => {
    //   this.props.setLoader(false, null)
    // })
    if(this.props.auth.is_signed_in){
      this.props.createEmptyCart()
    }
    this.setState({ category_id: category_id })
    this.props.clearProducts()
    this.onFetchProducts()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.match.params.id !== prevState.category_id){
      return { category_id: nextProps.match.params.id }; 
    } else {
      return null;
    }
 }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.match.params.id !== this.state.category_id){  
      const category_id = this.state.category_id
      if(this.props.auth.is_signed_in){
        this.props.createEmptyCart()
      }
      this.setState({ page: 1, hasMore: true })
      this.props.clearProducts()
      this.onFetchProducts() 
    }
  }

  onFetchProducts = () => {
    console.log('onFetchProducts')
    this.props.setLoader(true, "Fetching Products...")
    this.props.fetchCategoryProducts(this.state.category_id, this.state.page, this.props.products, left => { 
      console.log('left', left)
      if(left < 10) {
        console.log('set hasmore false', left)
        this.setState({ hasMore: false })
      } 
      this.props.setLoader(false, null)   
    }); 
  }

  fetchData = () => {
    this.setState({ page: this.state.page+1 })
    this.onFetchProducts()
  }

  renderProducts = () => {
    return this.props.products.map(v => {
      return <ProductItem key={v.id} product={v} />
    })
  }

  render() {  
    console.log('this.state.hasMore', this.state.hasMore) 

    let category_name = null;
    if(this.props.category) {
      // seller_image = <img src={this.props.seller.image}/>
      category_name = this.props.category.name
      
    }

    return ( 
      <div>
        <h1 className="h1">{category_name}</h1>
        <div className="product-tile-parent"> 
          <InfiniteScroll
            dataLength={this.props.products.length} 
            next={this.fetchData}
            hasMore={this.state.hasMore}
            loader={null}
            endMessage={
              <p style={{
                fontWeight: '400',
                textAlign: 'center',
                fontSize: '18px',
                clear: 'both'
              }} className="up-to-date">No More Products</p> 
            }
          >
            <Columns columns="1">
              {this.renderProducts()}
            </Columns>
          </InfiniteScroll>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.products,
    category: state.category.category,
    auth: state.auth
  };
};

export default connect(
  mapStateToProps,
  { fetchCategoryProducts, fetchCart, setLoader, createEmptyCart, fetchCategoryProductsMagento, clearProducts }
)(CategoryPage);
