import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class AccountNav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNav: false
    }
  }

  render() {
    return ( 
      <div id="my-account-nav">
        <div id="my-account-nav-bar">
          <div id="my-account-nav-heading">Account</div>
          <div className="my-account-arrow" onClick={() => { 
            this.setState({ showNav: !this.state.showNav })
          }}></div>
        </div>
        {this.state.showNav && <div id="my-account-nav-items">  
          <div className="my-account-nav-item">
            <Link to='/account'>My Account</Link>
          </div>
          <div className="my-account-nav-item">
            <Link to='/account/addressinformation'>Address Information</Link>
          </div>
          <div className="my-account-nav-item">
            <Link to='/account/personaldetails'>Personal Details</Link>
          </div>
          <div className="my-account-nav-item">
            <Link to='/account/savedcards'>Saved Cards</Link>
          </div>
          <div className="my-account-nav-item">
            <Link to='/account/orders'>Your Orders</Link>
          </div>
        </div>}
      </div>
    );
  }
}

export default connect(
  null,
  null
)(AccountNav);
