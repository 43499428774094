import _ from "lodash";
import {
  TRIGGERED_MOBILE_POPUP
} from "../actions/types";

const INITIAL_STATE = {
  triggered: false,
  pathname: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRIGGERED_MOBILE_POPUP:
      return {
        triggered: true,
        pathname: action.payload.pathname  
      };
    default:
      return state;
  }
};