import React from "react";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';
import { Link } from "react-router-dom";

class FooterTermsPrivacy extends React.Component { 
  render() {

    const pathname = this.props.location.pathname

    if(
      pathname !== "/" &&
      pathname !== "/checkout" &&
      pathname !== "/register"
    ) {
      return (
        <div id="footer-terms-privacy">
          <div className="footer-terms-privacy-item">
            <Link to="/privacy">Privacy Policy</Link>
          </div>
          <div className="footer-terms-privacy-item">
            <Link to="/terms">Terms &amp; Conditions</Link>
          </div>
        </div>
      );
   } else{
     return null
   }
  }
}

export default withRouter(
  connect(
    null,
    null
  )(FooterTermsPrivacy)
);