import React from "react";
import { connect } from "react-redux";
import { hideUnexpectedErrorPopup } from "../actions";

class UnexpectedErrorModal extends React.Component {
  render() {
    return ( 
      <div id="unexpected-error-mask" onClick={() => {
        this.props.hideUnexpectedErrorPopup()
      }}>
        <div id="unexpected-error-modal">
          <div id="unexpected-error-heading">Unexpected Error</div>
          <div id="unexpected-error-text">An Unexpected Error has occured. Please try again later.</div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  { hideUnexpectedErrorPopup }
)(UnexpectedErrorModal);
