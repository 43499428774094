import React from "react";
import {
  fetchCart,
  fetchShippingMethod,
  fetchCartProducts,
  estimateShippingMethod,
  fetchCustomShippingMethods,
  setSelectedShippingAddress,
  setLoaderCheckout,
  setLoader,
  fetchSeller,
  setUpdateProductDetails,
  disableCheckoutButton,
  updateProductQty,
  setCart,
  removeProductFromCart,
  enableCheckoutButton,
  removeGiftCardDetailsByCartItemId,
  createEmptyCartbyAPI,
  setEmptyCartbyAPI,
  setCartbyAPI
} from "../actions";
import { connect } from "react-redux";
import history from "../history";
import { Link } from "react-router-dom";
import CONSTANTS from "../constants";

window.update_qty_timeouts = {};

class CheckoutCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showQtyButtonsIndex: false,
    };
  }
  componentDidMount = () => {
    if (this.props.auth.is_signed_in) {
      this.props.createEmptyCartbyAPI(() => {
        this.props.setLoader(true, "Creating Cart...");
        this.props.setEmptyCartbyAPI(() => {
          this.props.setLoader(true, "Fetching Cart...");
          this.props.fetchCart(() => {
            this.setCartAndShippingAddress();
          });
        });
      });
      this.props.fetchCartProducts((num_products) => {
        this.props.setLoaderCheckout("cart_products");
        if (num_products === 0) {
          this.props.setLoader(false, null);
          history.push("/browse");
        } else if (!this.props.autologin_product_id) {
          if (this.props.cart_products.items.length > 0) {
            this.props.fetchSeller(this.props.cart_products.items[0].id, () => {
              this.props.setLoaderCheckout("seller");
            });
          } else {
            this.props.setLoaderCheckout("seller");
          }
        }
      });
    }
  };

  setCartAndShippingAddress = () => {
    this.props.setLoader(true, "Adding Product to Cart...");
    console.log("this.props.cart_productsthis.props.cart_products", this.props.cart_products);
    console.log("this.props.cart_productsthis.props.cart_products", this.props.cart_products);
    const numItems = this.props.cart_products.items.length;
    if (numItems > 0) {
      let itemsProcessed = 0;
      this.props.cart_products.items.forEach((v, index) => {
        const hasExtensionAttributes =
            v.product_option &&
            v.product_option.extension_attributes &&
            v.product_option.extension_attributes.configurable_item_options;

        const configurable_item_options = hasExtensionAttributes
            ? v.product_option.extension_attributes.configurable_item_options.map(option => {
              return {
                option_id: option.option_id,
                option_value: option.option_value
              };
            })
            : [];

        const product_data = {
          product_type: v.product_type, // Use the product type from the cart item
          sku: v.sku,
          qty: v.qty, // Use the quantity from the cart item
          product_option: hasExtensionAttributes
              ? {
                extension_attributes: {
                  configurable_item_options: configurable_item_options
                }
              }
              : null
        };
        this.props.setCartbyAPI(product_data, this.props.cart.id, () => {
          itemsProcessed++;
          if (itemsProcessed === numItems) {
            this.props.setLoader(false);
            this.props.setSelectedShippingAddress(
                this.props.shipping_addresses.default_shipping,
                this.props.shipping_addresses.addresses
            );
            this.props.fetchCustomShippingMethods(
                this.props.cart.customer.default_shipping
            );
          }
        });
      });
    } else {
      this.props.setLoader(false); // Set loading to false if there are no products to add to the cart
    }
  };

  componentDidUpdate = () => {
    if (this.props.cart_products.items.length === 0) {
      history.push("/browse");
    }
  };

  updateProductQty = (product, qty) => {
    if (window.update_qty_timeouts[product.cart_item]) {
      clearTimeout(window.update_qty_timeouts[product.cart_item]);
    }
    window.update_qty_timeouts[product.cart_item] = setTimeout(() => {
      const quote_id = this.props.cart.id;

      this.props.setLoader(true, "Updating Product Qty...");

      if (product.type_id === "configurable") {
        // color
        let color_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "color") {
            color_option = {
              option_id: CONSTANTS.COLOR_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let size_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "size") {
            size_option = {
              option_id: CONSTANTS.SIZE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let gift_card_price_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "gift_card_price") {
            gift_card_price_option = {
              option_id: CONSTANTS.GIFT_CARD_PRICE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        const configurable_options = {
          color: color_option,
          size: size_option,
          gift_card_price: gift_card_price_option,
        };

        this.props.removeProductFromCart(product.cart_item, () => {
          let configurable_item_options = [];
          for (const [key, value] of Object.entries(configurable_options)) {
            if (value !== null) {
              configurable_item_options.push({
                option_id: value.option_id.toString(),
                option_value: parseInt(value.option_value),
              });
            }
          }

          const product_data = {
            sku: product.sku,
            qty: qty,
            product_type: "configurable",
            product_option: {
              extension_attributes: {
                configurable_item_options: configurable_item_options,
              },
            },
          };

          this.props.setCart(product_data, quote_id, () => {
            this.props.fetchCart(() => {
              this.props.fetchCartProducts((num_products) => {
                this.props.setLoader(false, null);
                this.props.enableCheckoutButton();
                this.setState({ showQtyButtonsIndex: null });
              });
            });
          });
        });
      } else {
        const product_data = {
          item_id: product.cart_item,
          sku: product.sku,
          qty: qty,
        };

        this.props.setCart(product_data, quote_id, () => {
          this.props.fetchCart(() => {
            this.props.fetchCartProducts((num_products) => {
              this.props.setLoader(false, null);
              this.props.enableCheckoutButton();
              this.setState({ showQtyButtonsIndex: null });
            });
          });
        });
      }
    }, 1000);
  };

  renderCartProducts = () => {
    let total = 0;
    let totals = null;
    let totals_added = false;

    const items = this.props.cart_products.items.map((v, i, a) => {
      const price_dec = parseFloat(v.price);
      total += v.qty * price_dec;

      const price = price_dec.toFixed(2);

      let short_desc = v.short_desc ? v.short_desc : null;
      if (short_desc) {
        short_desc = short_desc.substring(3, short_desc.lastIndexOf("<"));
        if (short_desc.length > 20) {
          short_desc = short_desc.substr(0, 20) + "...";
        }
      }

      let image =
        v.images.length > 0
          ? v.images[0]
          : "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg";

      if (i === a.length - 1) {
        let shipping_amount = "...";
        if (this.props.shipping_method) {
          shipping_amount = this.props.shipping_method.amount;
          total += shipping_amount;
          shipping_amount = shipping_amount.toFixed(2);
        }

        let total_amount = total.toFixed(2);

        totals = (
          <React.Fragment>
            <div id="checkout-delivery-row" className="checkout-row-clear">
              <div className="checkout-delivery-total">Delivery</div>{" "}
              <div
                id="checkout-delivery-price"
                className="checkout-delivery-total-right"
              >
                ${shipping_amount}
              </div>
            </div>
            <div id="checkout-total-row" className="checkout-row-clear">
              <div
                id="checkout-delivery-total-text"
                className="checkout-delivery-total"
              >
                Total
              </div>{" "}
              <div
                id="checkout-order-total"
                className="checkout-delivery-total-right"
              >
                ${total_amount}
              </div>
            </div>
          </React.Fragment>
        );

        if (!v.is_giftcard) {
          totals_added = true;
        }
      }

      let configurable_options = null;
      if (v.product_option) {
        configurable_options = v.product_option.extension_attributes.configurable_item_options.map((v) => {
          return (
              <div key={v.value_index} class="checkout-normal">
                {v.option_label}: {v.option_title}
              </div>
          );
        });
      }

      return (
        <div key={v.item_id} className="checkout-product">
          <div className="clear">
            <div className="checkout-left-col">
              <div className="checkout-bold-left">{v.name}</div>
              <div className="checkout-normal">{short_desc}</div>
              {configurable_options}
              <div className="checkout-bold" style={{ marginBottom: 9 }}>
                ${price}
              </div>
              <div className="checkout-qty">
                Qty <span className="checkout-qty-number">{v.qty}</span>
              </div>
              {!v.is_giftcard && totals}
            </div>
            <div className="checkout-right-col">
              <img
                className={
                  v.is_giftcard ? "checkout-giftcard-image" : "checkout-image"
                }
                src={image}
              />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div>{items}</div>
        {!totals_added && totals}
      </div>
    );
  };

  render() {
    return <div id="checkout-cart">{this.renderCartProducts()}</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    shipping_method: state.shipping_method.shipping_method,
    cart_products: state.cart_products.cart_products,
    shipping_addresses: state.shipping_addresses,
    autologin_product_id: state.autologin_product,
    auth: state.auth,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

export default connect(mapStateToProps, {
  fetchCart,
  fetchShippingMethod,
  fetchCartProducts,
  estimateShippingMethod,
  fetchCustomShippingMethods,
  setSelectedShippingAddress,
  setLoaderCheckout,
  setLoader,
  fetchSeller,
  createEmptyCartbyAPI,
  setEmptyCartbyAPI,
  setCartbyAPI,
  setUpdateProductDetails,
  disableCheckoutButton,
  updateProductQty,
  setCart,
  removeProductFromCart,
  enableCheckoutButton,
  removeGiftCardDetailsByCartItemId,
})(CheckoutCart);
