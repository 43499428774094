import _ from "lodash";
import {
  FETCH_CATEGORY_PRODUCTS,
  FETCH_BROWSE_PRODUCTS,
  FETCH_SELLER_PRODUCTS,
  FETCH_BROWSE_PRODUCTS_PAGINATION,
  FETCH_CATEGORY_PRODUCTS_PAGINATION,
  CLEAR_PRODUCTS
} from "../actions/types";

const INITIAL_STATE = {
  products: []
};

export default (state = INITIAL_STATE, action) => {
  let products, new_state;
  switch (action.type) {
    case FETCH_CATEGORY_PRODUCTS:
    case FETCH_BROWSE_PRODUCTS:
    case FETCH_SELLER_PRODUCTS:
      products = [
        ...action.payload.data
      ]
      new_state = {
        products: products 
      }
      return new_state
    case FETCH_BROWSE_PRODUCTS_PAGINATION:
    case FETCH_CATEGORY_PRODUCTS_PAGINATION:
      products = [
        ...state.products,
        ...action.payload.data
      ]
      new_state = {
        products: products 
      }
      return new_state
    case CLEAR_PRODUCTS:
      return INITIAL_STATE
    default:
      return state;
  }
};
