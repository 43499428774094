import _ from "lodash";
import {
  FETCH_SHIPPING_ADDRESSES,
  ADD_NEW_SHIPPING_ADDRESS,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = {
  addresses: [],
  default_shipping_address: null,
  default_billing_address: null,
  default_billing: null,
  default_shipping: null
};

export default (state = INITIAL_STATE, action) => {
  let default_shipping_address, new_state, default_billing_address;
  switch (action.type) {
    case FETCH_SHIPPING_ADDRESSES:
      default_shipping_address = action.payload.customer.addresses.filter(v => {
        return v.id == action.payload.customer.default_shipping
      })
      default_billing_address = action.payload.customer.addresses.filter(v => {
        return v.id == action.payload.customer.default_billing
      })
      if(default_shipping_address.length > 0) {
        default_shipping_address = default_shipping_address[0]
      }
      if(default_billing_address.length > 0) {
        default_billing_address = default_billing_address[0]
      }
      new_state = {
        addresses: action.payload.customer.addresses,
        default_billing: action.payload.customer.default_billing,
        default_shipping: action.payload.customer.default_shipping,
        default_shipping_address: default_shipping_address,
        default_billing_address: default_billing_address
      }
      return new_state;
    case ADD_NEW_SHIPPING_ADDRESS: 
      new_state = {
        addresses: [
          ...state.addresses,
          action.payload
        ],
        default_billing: state.default_billing,
        default_shipping: state.default_shipping,
        default_shipping_address: state.default_shipping_address,
        default_billing_address: state.default_billing_address
      }
      return new_state;  
    case LOGOUT:
      return INITIAL_STATE 
    default:
      return state;
  }
};
