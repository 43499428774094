import React from "react";
import { connect } from "react-redux";
import { fetchSavedCards, setLoader } from "../../actions"; 
import requireAuth from "../requireAuth"; 
import AccountNav from "../AccountNav";

class AccountSavedCardsPage extends React.Component {
  componentDidMount = () => {
    this.props.setLoader(true, "Fetching Cards...")
    this.props.fetchSavedCards(() => { 
      this.props.setLoader(false, null)
    })
  }

  renderSavedCards = () => {
    return this.props.saved_cards.map((v, i, a) => { 

      let month = v.exp_month < 10 ? "0" + v.exp_month : v.exp_month
      let year = v.exp_year.toString().substring(2, 4); 
      let expiry = month + "/" + year

      let brand = v.brand.toUpperCase()
      switch (v.brand) {
        case 'visa':
          brand = <img className="account-saved-card-brand-img" src="https://cdn.tap2buy.com/card_logos/visa_card.png" />
          break;

        case 'mastercard':
          brand = <img className="account-saved-card-brand-img" src="https://cdn.tap2buy.com/card_logos/mastercard_card.png" />
          break;
        
        case 'amex':
          brand = <img className="account-saved-card-brand-img" src="https://cdn.tap2buy.com/card_logos/amex_card.png" /> 
          break;

        case 'jcb':
          brand = <img className="account-saved-card-brand-img" src="https://cdn.tap2buy.com/card_logos/jcb_card.png" />
          break;

        case 'unionpay':
          brand = <img className="account-saved-card-brand-img" src="https://cdn.tap2buy.com/card_logos/unionpay_card.png" />
          break;
      
        default:
          break;
      }

      return (
        <div key={v.id} className="account-saved-card">
          <div className="account-saved-card-brand">{brand}</div>
          <div className="account-saved-card-number">**{v.last4}</div>
          <div className="account-saved-card-expiry">expiry {expiry}</div>   
        </div>
      )
    })
  }

  render() {
    return (
      <div id="page-account-saved-cards">
        <AccountNav /> 
        <div id="account-content">
          <h1 className="h1">Saved Cards</h1>  
          <div id="account-saved-cards">
            {this.renderSavedCards()}  
          </div> 
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = state => {
  return { 
    saved_cards: state.saved_cards.saved_cards
  };
};

export default requireAuth(
  connect(
    mapStateToProps,
    { fetchSavedCards, setLoader }
  )(AccountSavedCardsPage)
);
