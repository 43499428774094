import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, getFormValues } from "redux-form";
import CONSTANTS from "../constants";
import { fetchProduct, inputValidation, clearValidation } from "../actions";
import { getSMSLength } from "../helper/common";

class GiftCardForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input_validation: {},
      product_name: null,
      gift_card_price: null,
      product_image: null,
      sms_length: 0,
      sms_message: ""
    };
  }

  getSMSLength(str) {
    const data = getSMSLength(str)
    console.log('data', data)
    this.setState({ sms_length: data.count, sms_message: str })
  }

  componentDidMount = () => {
    if (this.props.gift_card_details) {
      const {
        recipient_firstname,
        recipient_lastname,
        recipient_mobile,
        recipient_message,
        purchaser_mobile
      } = this.props.gift_card_details;
      this.props.change("recipient_firstname", recipient_firstname);
      this.props.change("recipient_lastname", recipient_lastname);
      this.props.change("recipient_mobile", recipient_mobile);
      this.props.change("recipient_message", recipient_message);
      this.props.change("purchaser_mobile", purchaser_mobile);
      this.getSMSLength(recipient_message)
    }

    this.props.fetchProduct(this.props.product_id, () => {
      const product_name = this.props.product.name;
      const gift_card_price = this.props.product.configurable_options_products[
        CONSTANTS.GIFT_CARD_PRICE_OPTION_ID
      ].find((v) => v.sku === this.props.configurable_product_sku).option_title;
      const product_image = this.props.product.images[0];

      this.setState({
        product_name: product_name,
        gift_card_price: gift_card_price,
        product_image: product_image,
      });
    });
  };

  componentDidUpdate = () => {
    console.log('this.props', this.props)
    console.log('this.props.form_values', this.props.form_values)
    if(this.props.form_values && this.props.form_values.recipient_message) {
      const new_message = this.props.form_values.recipient_message
      if(new_message !== this.state.sms_message) {
        this.getSMSLength(new_message)
      }
    }
  }

  renderInput = ({ input, label, type, id, defaultValue }) => {
    return (
      <div>
        <input
          {...input}
          id={id}
          type={type}
          placeholder={label}
          defaultValue={defaultValue}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      </div>
    );
  };

  renderTextarea = ({ input, label, id, rows, defaultValue }) => {
    return (
      <div>
        <textarea
          {...input}
          id={id}
          placeholder={label}
          defaultValue={defaultValue}
          rows={rows}
        ></textarea>
      </div>
    );
  };

  handleSubmit = (formValues) => {
    console.log(formValues);

    // input_validation
    let input_validation = {};

    if (!formValues.recipient_firstname) {
      input_validation.firstname = ["Please enter a firstname"];
    }

    if (!formValues.recipient_lastname) {
      input_validation.lastname = ["Please enter a lastname"];
    }

    if (!formValues.recipient_mobile) {
      input_validation.mobile = ["Please enter a mobile"];
    }

    if (!formValues.recipient_message) {
      input_validation.message = ["Please enter a message"];
    }

    if (!formValues.purchaser_mobile) {
      input_validation.purchaser_mobile = ["Please enter a mobile for receipt"];
    }

    console.log('input_validation', input_validation)
    if (Object.keys(input_validation).length > 0) {
      this.props.inputValidation(input_validation)

    } else {
      this.props.clearValidation()

      let gift_card_details = {
        ...formValues,
        gift_card_price: this.state.gift_card_price,
      };

      this.props.handleAction(gift_card_details);
    }
  };

  render() {
    let firstnameMessage = this.props.input_validation.firstname.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let lastnameMessage = this.props.input_validation.lastname.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let mobileMessage = this.props.input_validation.mobile.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let messageMessage = this.props.input_validation.message.map(message =>
      <div key={message} className="input-validation">{message}</div>);
    let purchaserMobileMessage = this.props.input_validation.purchaser_mobile.map(message =>
      <div key={message} className="input-validation">{message}</div>);

    return (
      <form
        className="form"
        id="gift-card-recipient-inputs-form"
        onSubmit={this.props.handleSubmit(this.handleSubmit)}
      >
        <div className="product-gift-card-details-container">
          <div className="gift-card-details-product-info">
            <div className="gift-card-details-left-col">
              <div className="gift-card-details-product-name">
                {this.state.product_name}
              </div>
              <div className="gift-card-details-product-price-container">
                <div className="gift-card-details-product-price-desc">
                  Price:
                </div>
                <div className="gift-card-details-product-price">
                  ${this.state.gift_card_price}
                </div>
              </div>
            </div>
            <div className="gift-card-details-right-col">
              <img
                className="gift-card-details-product-image"
                src={this.state.product_image}
              />
            </div>
          </div>
          <div className="gift-card-details-qty-info-container">
            <div className="gift-card-details-qty-info">
              <div style={{ marginBottom: 10, fontWeight: 'bold' }}>Details:</div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-firstname`}
                  name={`recipient_firstname`}
                  component={this.renderInput}
                  label="Firstname"
                  type="text"
                />
                {firstnameMessage}
              </div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-lastname`}
                  name={`recipient_lastname`}
                  component={this.renderInput}
                  label="Lastname"
                  type="text"
                />
                {lastnameMessage}
              </div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-mobile`}
                  name={`recipient_mobile`}
                  component={this.renderInput}
                  label="Mobile"
                  type="text"
                />
                {mobileMessage}
              </div>
              <div className="input-box" style={{ marginBottom: 11 }}>
                <Field
                  id={`gift-card-recipient-details-message`}
                  name={`recipient_message`}
                  component={this.renderTextarea}
                  label="Message"
                  rows="3"
                />
                <div style={{
                  fontSize:"14px",
                  position: 'relative',
                  top: '-3px'
                }}>{this.state.sms_length}/160</div>
                {messageMessage}
              </div>
              <div style={{ marginBottom: 10, fontWeight: 'bold' }}>Enter Mobile for Receipt:</div>
              <div className="input-box">
                <Field
                  id={`gift-card-recipient-details-purchaser-mobile`}
                  name={`purchaser_mobile`}
                  component={this.renderInput}
                  label="Your Mobile"
                  type="text"
                />
                {purchaserMobileMessage}
              </div>
            </div>
            <button id="gift-card-details-submit-button">Submit</button>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product.product,
    input_validation: state.input_validation,
    form_values: getFormValues('gift-card')(state),
  };
};

const giftCardForm = reduxForm({
  form: "gift-card",
})(GiftCardForm);

export default connect(mapStateToProps, { fetchProduct, inputValidation, clearValidation })(giftCardForm);
