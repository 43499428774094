import React from "react";
import { connect } from "react-redux";

class NotFoundPage extends React.Component {
  render() { 
    return (
      <div>
        <div>Page not found</div>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(NotFoundPage);
