import _ from "lodash";
import {
  SHOW_MOBILE_POPUP,
  HIDE_MOBILE_POPUP
} from "../actions/types";

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_MOBILE_POPUP:
      return true;
    case HIDE_MOBILE_POPUP:
      return false;
    default:
      return state;
  }
};