import React from "react";
import { connect } from "react-redux";
import history from "../history";
import {
  setLoader,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  fetchCart
} from "../actions";

class DefaultAddressPopup extends React.Component {
  renderAddresses = () => {
    if (this.props.addresses.length > 0) {
      return this.props.addresses.map((v) => {
        return (
          <div
            key={v.id}
            className="account-shipping-address"
            style={{ position: "relative" }}
          >
            <div>
              {v.firstname} {v.lastname}
            </div>
            <div>tel: {v.telephone}</div>
            <div>{v.street[0]}</div>
            <div>
              {v.city} {v.region.region_code} {v.postcode}
            </div>
            <button
              style={{
                position: "absolute",
                top: 18,
                right: 0,
                background: "#bfdd00",
                color: "#022a1e",
                borderRadius: 20,
                width: 100,
              }}
              onClick={() => {
                if (this.props.billing) {
                  const new_default_billing = v.id;
                  this.props.setLoader(true, "Updating Billing...");
                  this.props.setDefaultBillingAddress(
                    new_default_billing,
                    () => {
                      this.props.fetchCart(() => {
                        this.props.setLoader(false, null);
                        history.push("/account/addressinformation");
                      });
                    }
                  );
                } else if (this.props.shipping) {
                  const new_default_shipping = v.id;
                  this.props.setLoader(true, "Updating Billing...");
                  this.props.setDefaultShippingAddress(
                    new_default_shipping,
                    () => {
                      this.props.fetchCart(() => {
                        this.props.setLoader(false, null);
                        history.push("/account/addressinformation");
                      });
                    }
                  );
                }
              }}
            >
              Set
            </button>
          </div>
        );
      });
    } else {
      return null;
    }
  };

  render() {
    console.log("address", this.props.addresses); // array
    console.log("billing", this.props.billing); // true or false
    console.log("shipping", this.props.shipping); // true or false

    const heading_css = {
      textAlign: "center",
      fontSize: 20,
    };

    return (
      <div id="default-address-mask" onClick={this.props.onClickMask}>
        <div id="default-address-modal">
          {this.props.billing && (
            <div style={heading_css}>Set Default Billing</div>
          )}
          {this.props.shipping && (
            <div style={heading_css}>Set Default Shipping</div>
          )}
          {this.renderAddresses()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
  };
};

export default connect(mapStateToProps, {
  setLoader,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  fetchCart
})(DefaultAddressPopup);
