import _ from "lodash";
import {
  SET_AFFILIATE_SELLER_ID
} from "../actions/types";

const INITIAL_STATE = null

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AFFILIATE_SELLER_ID: 
      return action.payload;
    default:
      return state;
  }
};
