import React from "react";
import { connect } from "react-redux";
import history from "../../history";
import { verifyToken, fetchCart, createEmptyCart, fetchCartProducts, setSelectedShippingAddress, setCart, setLoader, logout, fetchProduct, setEmptyCart, fetchSeller } from "../../actions";

class AutologinPage extends React.Component {
  componentDidMount = () => {
    this.props.logout() 
    const jwt = this.props.match.params.jwt
    this.props.setLoader(true, "Verifying Token...")
    const autologin = typeof localStorage.autologin !== "undefined" ? localStorage.autologin : true
    delete localStorage.autologin 
    this.props.verifyToken(jwt, autologin, (data) => { 
      localStorage.campaign_id = data.campaign_id
      console.log('AUTOLOGIN PRODUCT DATA.');
      console.log(data);
      if(data.existing_user) {
        if(data.product) {
          const product_data = {
            id: data.product_id,
            name: data.product.name,
            sku: data.product.sku,
            qty: data.product.qty,
            images: [data.product.image],
            price: parseFloat(data.product.price).toFixed(2),
            product_type: data.product.product_option ? "configurable" : "simple",
            ...(data.product.product_option ? { product_option: data.product.product_option } : {}),
          };
          this.props.setCart(product_data);
          this.props.fetchCartProducts();
          history.push('/checkout')
        } else {
          history.push('/browse')
        }
      } else if(data.new_user) {
        if(data.product) {
          const product_data = {
            id: data.product_id,
            name: data.product.name,
            sku: data.product.sku,
            qty: data.product.qty,
            images: [data.product.image],
            price: parseFloat(data.product.price).toFixed(2),
            product_type: data.product.product_option ? "configurable" : "simple",
            ...(data.product.product_option ? { product_option: data.product.product_option } : {}),
          };
          this.props.setCart(product_data);
          this.props.fetchCartProducts();
          this.props.setLoader(false, null)
        }
        history.push('/register')
      } else if(data.expired_link) {
        localStorage.product_id = data.product_id 
        this.props.setLoader(false, null)
        history.push('/expiredlink')
      }
    })
  }

  render() {
    return (
      <div></div>
    );
  }
}

const mapStateToProps = state => {
  return {
    shipping_addresses: state.shipping_addresses,
    cart: state.cart.cart
  };
};

export default connect(
    mapStateToProps,
    { verifyToken, fetchCart, createEmptyCart, setSelectedShippingAddress, fetchCartProducts, setCart, setLoader, logout, fetchProduct, setEmptyCart, fetchSeller }
  )(AutologinPage);
