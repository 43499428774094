import _ from "lodash";
import {
  SET_PAYMENT_CARD,
  LOGOUT,
  ORDER_PLACED
} from "../actions/types";

const INITIAL_STATE = {
  pm: null
};

export default (state = INITIAL_STATE, action) => {
  let pm, new_state;
  switch (action.type) {
    case SET_PAYMENT_CARD: 
      pm = action.payload
      new_state = { 
        pm: pm
      }
      return new_state;
    case LOGOUT: 
    case ORDER_PLACED:
      return INITIAL_STATE  
    default:
      return state;
  }
};
