export const getDatetime = (date_obj) => {
    const year = date_obj.getFullYear().toString()
    const month = (date_obj.getMonth() + 1).toString().padStart(2, '0')
    const date = date_obj.getDate().toString().padStart(2, '0')

    const hours = date_obj.getHours().toString().padStart(2, '0')
    const minutes = date_obj.getMinutes().toString().padStart(2, '0')
    const seconds = date_obj.getSeconds().toString().padStart(2, '0')

    const datetime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

    return datetime 
}