import _ from "lodash";
import {
  SET_UPDATE_PRODUCT_DETAILS,
  FETCH_UPDATE_PRODUCT_DETAILS
} from "../actions/types";

const INITIAL_STATE = {
  update_product_details: null
};

export default (state = INITIAL_STATE, action) => {
  let new_state, update_product_details;
  switch (action.type) {
    case SET_UPDATE_PRODUCT_DETAILS:

      update_product_details = {
        qty: action.payload.qty,
        options: null,
        item_id: action.payload.item_id
      }

      if(action.payload.options.length > 0) {
        update_product_details.options = {}
        action.payload.options.forEach(v => {
          update_product_details.options[v.attribute_code] = {
            option_title: v.super_attribute_label,
            option_value: v.value_index,
            default_title: v.default_title  
          }
        }) 
      }

      localStorage.update_product_details = JSON.stringify(update_product_details)

      new_state = {
        update_product_details: update_product_details   
      }
      return new_state;
    case FETCH_UPDATE_PRODUCT_DETAILS:
      update_product_details = localStorage.update_product_details ? JSON.parse(localStorage.update_product_details) : null
      new_state = {
        update_product_details: update_product_details    
      }
      return new_state;
    default:
      return state;
  }
};
