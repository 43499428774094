import _ from "lodash";
import {
  FETCH_ORDER
} from "../actions/types";

const INITIAL_STATE = {
  order: null
};

export default (state = INITIAL_STATE, action) => {
  let order, new_state;
  switch (action.type) {
    case FETCH_ORDER:
      order = {
        ...action.payload.data 
      }
      new_state = {
        order: order
      } 
      return new_state
    default:
      return state;
  }
};
