import { combineReducers } from "redux";
import authReducer from "./authReducer";  
import customerReducer from "./customerReducer";
import cartReducer from "./cartReducer";
import productReducer from "./productReducer";
import productsReducer from "./productsReducer";
import savedCardsReducer from "./savedCardsReducer";
import sellerReducer from "./sellerReducer";
import shippingInformationReducer from "./shippingInformationReducer";
import shippingMethodReducer from "./shippingMethodReducer";
import showCartReducer from "./showCartReducer";
import showNavMenuReducer from "./showNavMenuReducer";
import inputValidationReducer from "./inputValidationReducer";
import categoriesReducer from "./categoriesReducer";
import { reducer as formReducer } from "redux-form";
import categoryReducer from "./categoryReducer";
import jwtReducer from "./jwtReducer";
import checkoutSuccessReducer from "./checkoutSuccessReducer";
import cartProductsReducer from "./cartProductsReducer";
import shippingAddressesReducer from "./shippingAddressesReducer";
import paymentCardReducer from "./paymentCardReducer";
import billingAddressReducer from "./billingAddressReducer";
import selectedShippingAddressReducer from "./selectedShippingAddressReducer";
import loaderReducer from "./loaderReducer";
import autologinProductReducer from "./autologinProductReducer";
import showMobilePopupReducer from "./showMobilePopupReducer";
import updateProductDetailsReducer from "./updateProductDetailsReducer";
import offlineReducer from "./offlineReducer";
import showSMPopupReducer from "./showSMPopupReducer";
import mobilePopupReducer from "./mobilePopupReducer";
import expiredLinkReducer from "./expiredLinkReducer";
import unexpectedErrorReducer from "./unexpectedErrorReducer";
import ordersReducer from "./ordersReducer";
import orderReducer from "./orderReducer";
import productOutOfStockPopupReducer from "./productOutOfStockPopupReducer";
import productsMagentoReducer from "./productsMagentoReducer";
import disableCheckoutButtonReducer from "./disableCheckoutButtonReducer";
import showSuppliedByFooterReducer from "./showSuppliedByFooterReducer";
import showSuppliedByPopupReducer from "./showSuppliedByPopupReducer";
import isHomePageReducer from "./isHomePageReducer";
import affiliateSellerIdReducer from "./affiliateSellerIdReducer";
import giftCardDetailsReducer from "./giftCardDetailsReducer";
import mmsTemplatesReducer from "./mmsTemplatesReducer";
import passesReducer from "./passesReducer";
import showMessagePopupReducer from "./showMessagePopupReducer";

export default combineReducers({
  auth: authReducer,
  customer: customerReducer,
  cart: cartReducer,
  cart_products: cartProductsReducer,
  product: productReducer,
  products: productsReducer,
  saved_cards: savedCardsReducer,
  seller: sellerReducer,
  shipping_information: shippingInformationReducer,
  shipping_addresses: shippingAddressesReducer,
  selected_shipping_address: selectedShippingAddressReducer,
  billing_address: billingAddressReducer,
  shipping_method: shippingMethodReducer,
  show_cart: showCartReducer,
  show_nav: showNavMenuReducer,
  show_mobile_popup: showMobilePopupReducer,
  show_supplied_by_popup: showSuppliedByPopupReducer, 
  input_validation: inputValidationReducer,
  categories: categoriesReducer,
  category: categoryReducer,
  jwt: jwtReducer, 
  checkout_success: checkoutSuccessReducer, 
  payment_card: paymentCardReducer, 
  loader: loaderReducer, 
  autologin_product: autologinProductReducer, 
  update_product_details: updateProductDetailsReducer, 
  offline: offlineReducer, 
  show_sm_popup: showSMPopupReducer,  
  mobile_popup: mobilePopupReducer,
  expired_link: expiredLinkReducer,
  unexpected_error: unexpectedErrorReducer, 
  orders: ordersReducer,
  order: orderReducer,
  product_out_of_stock_popup: productOutOfStockPopupReducer,
  products_magento: productsMagentoReducer,
  disable_checkout_button: disableCheckoutButtonReducer,
  show_supplied_by_footer: showSuppliedByFooterReducer,
  is_home_page: isHomePageReducer,
  affiliate_seller_id: affiliateSellerIdReducer,
  gift_card_details: giftCardDetailsReducer,
  mms_templates: mmsTemplatesReducer,
  passes: passesReducer,
  message_popup: showMessagePopupReducer,
  form: formReducer
});
