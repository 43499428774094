import React from "react";
import { fetchSellerInformation, setLoader, fetchCart, createEmptyCart } from "../../actions";
import { connect } from "react-redux";
import ProductItem from "../ProductItem";
import Columns from 'react-columns';
import { isTap2Buy, isGiftGuru } from "../../helper/common"

class SellerProfilePage extends React.Component {
  componentDidMount = () => {
    const seller_url = this.props.match.params.sellerurl
    this.props.setLoader(true, "Fetching Seller information...")
    this.props.fetchSellerInformation(seller_url, () => {
      this.props.setLoader(false, null)
    })
    if(this.props.auth.is_signed_in) {
      this.props.createEmptyCart(() => {
        this.props.fetchCart() 
      })
    }
  }

  renderProducts = () => {
    return this.props.products.map(v => {
      return <ProductItem key={v.id} product={v} />
    })
  }

  renderSellerProfile = () => {
    return ( 
      <div id="page-sellerprofile">
        <div id="seller-profile-header">
          <div id="seller-profile-image">
            <img className="wk-mp-collection-logo" src={this.props.seller.image} />
          </div>
          <div id="seller-profile-desc">{this.props.seller.description}</div>
        </div>
        <div className="product-tile-parent">
          <Columns columns="1">
            {this.renderProducts()}
          </Columns>
        </div>
        <div className="clear"></div>
      </div>
    );
  }

  render() {
    if(this.props.seller) {
      return this.renderSellerProfile()
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return {
    products: state.products.products,
    seller: state.seller.seller,
    auth: state.auth
  };
};

export default connect( 
  mapStateToProps,
  { fetchSellerInformation, setLoader, fetchCart, createEmptyCart }
)(SellerProfilePage);
