import _ from "lodash";
import {
  SET_AUTOLOGIN_PRODUCT,
  LOGOUT
} from "../actions/types";

const INITIAL_STATE = null

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_AUTOLOGIN_PRODUCT: 
      return action.payload.product_id;
    case LOGOUT: 
      return INITIAL_STATE  
    default:
      return state;
  }
};
