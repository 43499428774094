import React from "react";
import { showNavMenu, showCart, fetchCartProducts, setLoader, showMobilePopup, triggeredMobilePopup, showSMPopup } from "../actions";
import { connect } from "react-redux";
// import { useLocation } from 'react-router-dom'
import {withRouter} from 'react-router-dom';
import { isGiftGuru, isTap2Buy } from "../helper/common";

class Header extends React.Component {
  componentDidMount = () => {
    if(this.props.auth.is_signed_in){
      this.props.setLoader(true, "Fetching Cart...")
      this.props.fetchCartProducts(() => {
        this.props.setLoader(false, null)
      })
    }
  }

  getHeaderText = () => {
    return isTap2Buy() ? "Tap2Buy" : isGiftGuru() ? "GiftGuru" : "Tap2Buy"
  }

  getProductText() {
    let short_desc = this.getHeaderText()
    if(this.props.product && this.props.product.short_desc){
      const product = this.props.product
      short_desc = product.short_desc ? product.short_desc : `<p>${short_desc}</p>`
      short_desc = short_desc.substring(3, short_desc.lastIndexOf('<'));  
      if(short_desc.length > 20) {
          short_desc = short_desc.substr(0, 20) + "..." 
      } 
    }
    
    return short_desc
  }

  render() {
    let total_qty = 0
    this.props.cart_products.items.forEach(v => {
      total_qty += v.qty
    })

    const nav_toggle = (
      <span id="toggle-nav-parent" onClick={() => {
        this.props.showNavMenu()
      }}>
          <img id="toggle-nav" src="https://cdn-staging.securemobile.net/web/images/toggle-nav-3-bars.png"/>
      </span>
    )

    const md_icon = (
      <span id="md-icon-parent" onClick={() => {
        window.open('https://intouch.digital/', '_blank') 
      }}>
          <img id="md-icon" src="https://cdn-staging.securemobile.net/web/images/Mark-lime.svg"/>
      </span>
    )

    const cart = (
      <div id="cart" onClick={() => {
        if(this.props.auth.is_signed_in){
          this.props.showCart()
        } else {
          this.props.triggeredMobilePopup({
            pathname: null
          })
          this.props.showMobilePopup() 
        }
      }}><img src="https://cdn-staging.securemobile.net/web/images/cart-white.png"/><span id="cart-quantity">{total_qty}</span></div> 
    )

    let header = null
    
    const pathname = this.props.location.pathname

    if(pathname === "/register") {
      header = (
        <React.Fragment>
          <div id="header-registration-text">Registration</div>
          <div className="header-sm-image-parent">
            <img 
              className="header-registration-sm-image" 
              src="https://cdn-staging.securemobile.net/web/images/SecureMobile-03.png"
              onClick={() => {
                this.props.showSMPopup()
              }}
            />
          </div>
        </React.Fragment>
      ) 
    } else if(pathname === "/checkout") { 
      header = (
        <React.Fragment>
          {md_icon}
          <div id="header-checkout-text">{this.getHeaderText()}</div>
          {cart}
        </React.Fragment>
      )
    } else if(pathname.includes("/product/")) {
      const short_desc = this.getProductText()

      header = (
        <React.Fragment>
          {nav_toggle}
          <div id="header-product-text">{this.props.seller ? this.props.seller.name : this.getHeaderText()}</div>
          {cart}
        </React.Fragment>
      )
    } else {
      header = (
        <React.Fragment>
          {nav_toggle} 
          <div id="header-pages-text">{this.getHeaderText()}</div>
          {cart}
        </React.Fragment>
      )
    }

    if(pathname !== "/") {
      return (
        <div id="header">
          <div id="header-container">
            {header}
          </div>
        </div>
      );
    } else {
      return null
    }
  }
}

const mapStateToProps = state => {
  return { 
    cart_products: state.cart_products.cart_products,
    auth: state.auth,
    product: state.product.product,
    seller: state.seller.seller
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { showNavMenu, showCart, fetchCartProducts, setLoader, showMobilePopup, triggeredMobilePopup, showSMPopup }
  )(Header)
);
