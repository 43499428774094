import _ from "lodash";
import {
  HIDE_OFFLINE_POPUP,
  SET_OFFLINE
} from "../actions/types";

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OFFLINE:
      return true;
    case HIDE_OFFLINE_POPUP:
      return false;
    default:
      return state;
  }
};

