import React from "react";
import { showSMPopup } from "../actions";
import { connect } from "react-redux";
import {withRouter} from 'react-router-dom';

class Footer extends React.Component { 
  render() {

    const pathname = this.props.location.pathname

    if(
      pathname !== "/" &&
      pathname !== "/register" &&
      pathname !== "/checkout" 
    ) {
      return (
        <div id="footer">
          <div id="footer-powered-by-parent">
            <img 
              src="https://cdn-staging.securemobile.net/web/images/SecureMobile-footer-registration.png"
              onClick={() => {
                this.props.showSMPopup()
              }}
            />
          </div>
        </div>
      );
   } else{
     return null
   }
  }
}

export default withRouter(
  connect(
    null,
    { showSMPopup }
  )(Footer)
);