import React from "react";
import {
  fetchCart,
  removeProductFromCart,
  fetchCustomShippingMethods,
  fetchCartProducts,
  hideCart,
  setSelectedShippingAddress,
  setUpdateProductDetails,
  setLoaderCart,
  createEmptyCart,
  removeGiftCardDetailsByCartItemId,
  setEmptyCart,
  setLoader,
  clearGiftCardDetails, updateProductQty,
} from "../actions";
import { connect } from "react-redux";
import history from "../history";
import CONSTANTS from "../constants";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showQtyButtonsIndex: false,
    };
  }
  componentDidMount = () => {
    if (this.props.auth.is_signed_in) {
      this.props.setLoaderCart(true, "Getting Shipping Information...");
      this.props.setSelectedShippingAddress(
        this.props.shipping_addresses.default_shipping,
        this.props.shipping_addresses.addresses
      );
      this.props.setLoaderCart(false, null);
    }
  };

  updateProductQty = (product, qty) => {
    if (window.update_qty_timeouts[product.cart_item]) {
      clearTimeout(window.update_qty_timeouts[product.cart_item]);
    }
    window.update_qty_timeouts[product.cart_item] = setTimeout(() => {
      const quote_id = this.props.cart.id;

      this.props.setLoader(true, "Updating Product Qty...");

      if (product.type_id === "configurable") {
        // color
        let color_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "color") {
            color_option = {
              option_id: CONSTANTS.COLOR_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let size_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "size") {
            size_option = {
              option_id: CONSTANTS.SIZE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        // size
        let gift_card_price_option = null;
        product.options.forEach((v) => {
          if (v.attribute_code === "gift_card_price") {
            gift_card_price_option = {
              option_id: CONSTANTS.GIFT_CARD_PRICE_OPTION_ID,
              option_value: v.value_index,
            };
          }
        });

        const configurable_options = {
          color: color_option,
          size: size_option,
          gift_card_price: gift_card_price_option,
        };

        this.props.removeProductFromCart(product.cart_item, () => {
          let configurable_item_options = [];
          for (const [key, value] of Object.entries(configurable_options)) {
            if (value !== null) {
              configurable_item_options.push({
                option_id: value.option_id.toString(),
                option_value: parseInt(value.option_value),
              });
            }
          }

          const product_data = {
            sku: product.sku,
            qty: qty,
            product_type: "configurable",
            product_option: {
              extension_attributes: {
                configurable_item_options: configurable_item_options,
              },
            },
          };

          this.props.setCart(product_data, quote_id, () => {
            this.props.fetchCart(() => {
              this.props.fetchCartProducts((num_products) => {
                this.props.setLoader(false, null);
                this.props.enableCheckoutButton();
                this.setState({ showQtyButtonsIndex: null });
              });
            });
          });
        });
      } else {
        const product_data = {
          item_id: product.cart_item,
          sku: product.sku,
          qty: qty,
        };

        this.props.setCart(product_data, quote_id, () => {
          this.props.fetchCart(() => {
            this.props.fetchCartProducts((num_products) => {
              this.props.setLoader(false, null);
              this.props.enableCheckoutButton();
              this.setState({ showQtyButtonsIndex: null });
            });
          });
        });
      }
    }, 1000);
  };

  renderCartProducts = () => {
    return this.props.cart_products.items.map((v, i, a) => {
      const price_dec = parseFloat(v.price);

      const price = price_dec.toFixed(2);

      let short_desc = v.short_desc ? v.short_desc : null;
      if (short_desc) {
        short_desc = short_desc.substring(3, short_desc.lastIndexOf("<"));
        if (short_desc.length > 20) {
          short_desc = short_desc.substr(0, 20) + "...";
        }
      }

      let image =
        v.images.length > 0
          ? v.images[0]
          : "https://staging.securemobile.net/pub/static/frontend/Codazon/fastest_bigmarket/en_AU/Magento_Catalog/images/product/placeholder/thumbnail.jpg";

      let totals = null;

      let configurable_options = null;


      if (v.product_option) {
        configurable_options = v.product_option.extension_attributes.configurable_item_options.map((v) => {
          return (
            <div key={v.value_index} class="checkout-normal">
              {v.option_label}: {v.option_title}
            </div>
          );
        });
      }

      return (
        <div key={v.id} className="cart-product">
          <div className="clear">
            <div className="checkout-left-col">
              <div className="checkout-bold-left">{v.name}</div>
              <div className="checkout-normal">{short_desc}</div>
              <div className="checkout-bold">${price}</div>
              {configurable_options}
              <div className="checkout-qty">
                Qty <span className="checkout-qty-number">{v.qty}</span>
                <span
                    className="checkout-qty-arrow"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (i === this.state.showQtyButtonsIndex) {
                        this.setState({ showQtyButtonsIndex: null });
                      } else {
                        this.setState({ showQtyButtonsIndex: i });
                      }
                    }}
                ></span>
              </div>
              {this.state.showQtyButtonsIndex === i && (
                  <div className="checkout-qty-control">
                    <div className="qty-minus">
                      <a
                          className="btn-qty minus"
                          onClick={(e) => {
                            e.stopPropagation();
                            const qty = v.qty > 1 ? v.qty - 1 : 1;
                            // decrease qty and update cart
                            this.props.updateProductQty(v.id, qty);
                            if (
                                qty === 0 &&
                                v.type_id === "configurable" &&
                                v.options.find(
                                    (v2) => v2.attribute_code === "gift_card_price"
                                )
                            ) {
                              this.props.removeGiftCardDetailsByCartItemId(
                                  this.props.cart_products.id,
                                  v.cart_item
                              );
                            }
                          }}
                      >
                        -
                      </a>
                    </div>
                    <div className="qty-input">
                      <input
                          type="number"
                          name="qty"
                          id="qty"
                          maxlength="12"
                          value={v.qty}
                          title="Qty"
                          className="input-text qty"
                      />
                    </div>
                    <div className="qty-plus">
                      <a
                          className="btn-qty plus"
                          onClick={(e) => {
                            e.stopPropagation();
                            const qty = v.qty + 1;
                            // increase qty and update cart
                            this.props.updateProductQty(v.id, qty);
                          }}
                      >
                        +
                      </a>
                    </div>
                  </div>
              )}
              {!v.is_giftcard && totals}
              <div className="remove-product-parent">
                <button
                  className="remove-product"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.removeProductFromCart(v.id, () => {
                      this.props.fetchCartProducts();
                    });
                  }}
                >
                  Remove
                </button>
              </div>
              {totals}
            </div>
            <div className="checkout-right-col">
              <img
                className={
                  v.is_giftcard ? "checkout-giftcard-image" : "checkout-image"
                }
                src={image}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  renderCartWithProducts = () => {
    let total = 0;

    let shipping_amount = 0;
    if (this.props.shipping_method) {
      shipping_amount = this.props.shipping_method.amount;
      total += shipping_amount;
      shipping_amount = shipping_amount.toFixed(2);
    }

    this.props.cart_products.items.forEach((v, i, a) => {
      const price_dec = parseFloat(v.price);
      total += v.qty * price_dec;
    });
    let total_amount = total.toFixed(2);

    return (
      <React.Fragment>
        <div id="cart-popup-heading">Your Cart</div>
        <div id="cart-products">{this.renderCartProducts()}</div>
        <div id="cart-delivery-total">
          <div id="checkout-delivery-row" className="clear">
            <div className="checkout-delivery-total">Delivery</div>{" "}
            <div
              id="checkout-delivery-price"
              className="checkout-delivery-total-right"
            >
              ${shipping_amount}
            </div>
          </div>
          <div id="checkout-total-row" className="clear">
            <div
              id="checkout-delivery-total-text"
              className="checkout-delivery-total"
            >
              Total
            </div>{" "}
            <div
              id="checkout-order-total"
              className="checkout-delivery-total-right"
            >
              ${total_amount}
            </div>
          </div>
        </div>
        <div id="cart-buttons">
          <button
            id="cart-popup-checkout"
            onClick={() => {
              history.push("/checkout");
              this.props.hideCart();
            }}
          >
            Checkout
          </button>
          <button
            id="cart-popup-keep-shopping"
            onClick={() => {
              history.push("/browse");
              this.props.hideCart();
            }}
          >
            Keep Shopping
          </button>
        </div>
        <div id="cart-popup-close-window-container">
          <div id="cart-popup-close-window-parent">
            <div
              id="cart-popup-close-window"
              onClick={() => {
                this.props.hideCart();
              }}
            >
              Close Window
            </div>
            <div
              id="cart-popup-close-window"
              onClick={() => {
                this.props.setLoader(true, "Emptying Cart...");
                this.props.createEmptyCart(() => {
                  this.props.setEmptyCart(() => {
                    this.props.clearGiftCardDetails(() => {
                      this.props.fetchCartProducts(() => {
                        this.props.setLoader(false, null);
                        this.props.hideCart();
                      });
                    });
                  });
                });
              }}
            >
              Clear Cart
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  render() {
    let cart_products = null;
    let cart_empty = null;
    if (this.props.cart_products.items.length > 0) {
      cart_products = this.renderCartWithProducts();
    } else {
      cart_empty = <div id="cart-popup-empty">Cart is empty</div>;
    }

    let cart_content = null;
    if (this.props.loader.cart.show) {
      cart_content = (
        <React.Fragment>
          <div id="cart-popup-heading">Your Cart</div>
          <div id="loader-cart-parent">
            <div id="loader-cart">
              <img src="/tap2buy-loader.gif" />
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      cart_content = (
        <React.Fragment>
          {cart_products}
          {cart_empty}
        </React.Fragment>
      );
    }

    return (
      <div id="cart-mask">
        <div id="cart-popup">
          <div id="cart-popup-x-parent">
            <span
              id="cart-popup-x"
              onClick={() => {
                this.props.hideCart();
              }}
            >
              x
            </span>
          </div>
          {cart_content}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart.cart,
    shipping_method: state.shipping_method.shipping_method,
    cart_products: state.cart_products.cart_products,
    shipping_addresses: state.shipping_addresses,
    auth: state.auth,
    loader: state.loader,
    gift_card_details: state.gift_card_details.gift_card_details,
  };
};

export default connect(mapStateToProps, {
  fetchCart,
  removeProductFromCart,
  fetchCustomShippingMethods,
  fetchCartProducts,
  hideCart,
  setSelectedShippingAddress,
  setUpdateProductDetails,
  setLoaderCart,
  createEmptyCart,
  removeGiftCardDetailsByCartItemId,
  setEmptyCart,
  updateProductQty,
  setLoader,
  clearGiftCardDetails,
})(Cart);
