import _ from "lodash";
import {
  SHOW_PRODUCT_OUT_OF_STOCK,
  HIDE_PRODUCT_OUT_OF_STOCK
} from "../actions/types";

const INITIAL_STATE = false;

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SHOW_PRODUCT_OUT_OF_STOCK:
      return true;
    case HIDE_PRODUCT_OUT_OF_STOCK:
      return false; 
    default:
      return state;
  }
};

